import React, { useCallback, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { Tooltip } from 'antd';
import {
  TableDetalleFlujoCaja,
  ModalFiltros,
  ModalAgregarNombre,
  ModalRestricciones,
  ModalVersion,
  ModalSoluciones,
  Solucion,
} from './components';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad, useFetch } from '../../../../common/hooks';
import {
  ButtonForm,
  NoData,
  TabBarCreacion,
  Input,
  LabelForm,
  LoadingSpinner,
  SelectForm,
  MultiSelectCheckboxes,
  PBRFunction,
} from '../../../../components';
import {
  crearVersionFlujoCaja,
  ejecutarOptimizadorScriptNube,
  listarConceptosXFiltro,
  obtenerEstructurasBase,
  obtenerInfoFiltrosFlujoCajaCabecera,
  obtenerListadoEtiquetas,
  obtenerListadoMoneda,
  obtenerListadoPeriodo,
  obtenerListadoTC,
  obtenerSoluciones,
  obtenerSplitRegistros,
  obtenerVersionesFiltrosFCxUsuario,
  obtenerVersionesFlujoCaja,
  obtenerVersionesSolucionFCxUsuario,
  saveHeaderAndDetailsFlowsCaja,
  updateVerionFlujoCaja,
  obtenerVersionesRestriccionesFCxUsuario,
  obtenerCamposHomologadosReglas,
  obtenerPaletaColoresXSolucion,
  obtenerAgentes,
  guardarCambiosFlujoCaja,
  listarSociedadUsuario,
} from '../../../../services';
import { createEstructuraBaseAdapter } from '../../../../adapters/estructuraBase.adapter';
import {
  onSetDetalleFlujoCajaCabeceras,
  onChangeEstructuraCrearFlujoCaja,
  onChangeFechaCrearFlujoCaja,
  onOpenModalFiltrosDetalleFlujoCaja,
  onOpenModalRestriccionesDetalleFlujoCaja,
  onOpenModalVersionFlujoCaja,
  onSetEstructurasBaseCrearFlujoCaja,
  onSetListaVersionesFlujoCaja,
  onSetPeriodoCrearFlujoCaja,
  onSetMonedasCrearFlujoCaja,
  onChangeTiempoPeridiocidad,
  onChangeProyeccion,
  onChangeVersion,
  onSetListaPosicionesFiltrosFlujoCaja,
  onLimpiarFiltroDetalleFlujoCaja,
  onSetTabProceso,
  onSetListaSoluciones,
  changeSelectListaSoluciones,
  onSetListaAgrupadaXSolucionesParcial,
  onSetListaAgrupadaXSolucionesTotal,
  changeListaAgruadaXSoluciones,
  onSetDetalleListaAgrupadaXSoluciones,
  onLimpiarRestriccionDetalleFlujoCaja,
  onSetListaEtiqueFlujoCaja,
  onSelectEtiquetaFlujoCaja,
  onOpenModalSolucionesDetalleFlujoCaja,
  limpiarSolucionesFlujoCaja,
  onSetListaModalSolFlujoCaja,
  onSetFilterListaModalSolFlujoCaja,
  onSetListaTipoEstadoSolFlujoCaja,
  collapseDesdobles,
  onSetOptimizador,
  onLimpiarCamposCrearFlujoCaja,
  onLimpiarCamposFlujoCaja,
  onSelectVersionFlujoCaja,
  updateTitle,
  onOpenModalAgregarNombreFlujoCaja,
  onLimpiarCombinacionSolucuionesFlujoCaja,
  onSelectPeridiocidadTableFC,
  onSetDetalleFlujoCajaCabecerasInicial,
  onSelectMonedaTableFC,
  onSetTipoCambioFlujoCaja,
  onSetFactorMonedaTableFC,
  onSetAplicarBusquedaFlujoCaja,
  onSelectPeridiocidadTableSolucionFC,
  onSetDetalleFlujoCajaCabecerasSolucion,
  onSetListaAgrupadaXSolucionesSumarizada,
  onSetListaVersionFiltroDetalleFlujoCaja,
  onSetListaVersionRestriccionesDetalleFlujoCaja,
  onSetListaVersionSolucionDetalleFlujoCaja,
  onSelectCaracteristicasReglas,
  onSetAgente,
  onLogout,
  onSetLog,
  onGenerateDataLineChartFC,
  onUniverso,
  onSetListFiltroCaract,
  onSetFiltroDetalleFlujoCaja,
  onSelectMonedaTableFCInicial,
  onSetSociedadesCrearFlujoCaja,
  onSetSociedad,
} from '../../../../redux/states';
import {
  createConceptoAdapter,
  createEstadoSolucionSelectAdapter,
  createFlujoCajaEtiquetaAdapter,
  createMonedaAdapter,
  createMonedaAbreviaturaAdapter,
  createPeriodoAdapter,
  createSolucionModalFlujoCajaAdapter,
  createSolucionesCabFC,
  createVersionFlujoCajaAdapter,
  createRestriccionesCabFCAdapter,
  createCaracteristicaReglaAdapter,
  createAgenteSelectAdapter,
  filtroReporteAdapter,
  cambiosModificadosAdapter,
  cambiosEliminadosAdapter,
} from '../../../../adapters';
import {
  addDays,
  dateTo_dd_mm_yyyy,
  onlyNumbers,
  dateTo_yyyy_mm_dd,
  formatString_YYYYMMDD_TO_DDMMYYYY,
  monthToString,
  JsoToExcelJsFC,
  theme,
  listaUniverso,
  permisos,
  paths,
} from '../../../../common';
import { createObtenerInfoFiltrosFlujoCajaCabeceraAdapter } from '../../../../adapters/obtenerInfoFiltrosFlujoCajaCabecera.adapter';
import collect from 'collect.js';
import { EstadoSolucionesData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { createFiltrosCabFCAdapter } from '../../../../adapters/createFiltrosCabFCAdapter.adapter';

import {
  ColForm,
  Content,
  ContentButton,
  ContentForm,
  ContentRow,
  DatePickerForm,
  InputSearchForm,
  ParamCard,
  ParamCardBody,
  ParamCardHeader,
  ParamCardTitle,
  RowDoubleInput,
  RowForm,
} from '../../../../styles';
import { initialFormAgregarRegM, initiallAllPosition, listaProyeccion } from './misc';
import cloneDeep from 'lodash/cloneDeep';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  Filler,
} from 'chart.js';
import { forEach } from 'lodash';
import {
  FaAngleDown,
  FaChartLine,
  FaClipboard,
  FaCodeBranch,
  FaCubes,
  FaDice,
  FaDownload,
  FaExpand,
  FaFileExcel,
} from 'react-icons/fa6';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TooltipChart,
  Legend,
  Filler
);
registerLocale('es', es);

export const CrearFlujoCajaF = (props) => {
  const [selectedDesdoblesInicialTableSolucionFC, setSelectedDesdoblesInicialTableSolucionFC] =
    useState([]);
  const [selectionCollapseModel, setSelectionCollapseModel] = React.useState([]);
  const [selectionRestriccionesModel, setSelectionRestriccionesModel] = React.useState([]);
  const [accodionHeightParametroEntrada, setAccodionHeightParametroEntrada] = useState(0);
  const [formAgregarRegM, setFormAgregarRegM] = useState(initialFormAgregarRegM);
  const [expandedParametroEntrada, setExpandedParametroEntrada] = useState(true);
  const [selectionFiltrosModel, setSelectionFiltrosModel] = React.useState([]);
  const [finalArrayTotalParciales, setFinalArrayTotalParciales] = useState({});
  const [objRightPosicionClick, setObjRightPosicionClick] = useState(null);
  const [objRightRegistroClick, setObjRightRegistroClick] = useState(null);
  const [finalArraySinFiltro, setFinalArraySinFiltro] = useState([]);
  const [finalArrayEliminar, setFinalArrayEliminar] = useState([]);
  const [selectedDesdobles, setSelectedDesdobles] = useState([]);
  const personalizacionState = useSelector((store) => store.personalizacion);
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const [finalArrayTotal, setFinalArrayTotal] = useState({});
  const [objRightClick, setObjRightClick] = useState({});
  const [showGrafico, setShowGrafico] = useState(false);
  const authState = useSelector((store) => store.auth);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { callEndpointSP } = useFetch();
  const [editTable, setEditTable] = useState(false);
  const [finalArray, setFinalArray] = useState([]);
  const [compartirAvailable, setCompartirAvailable] = useState(
    PBRFunction([permisos.COMPARTIR_REGISTRO])
  );
  const [idMatrizExplotada, setIdMatrizExplotada] = useState(null);
  const [idMatrizExplotadaPrimer, setIdMatrizExplotadaPrimer] = useState(null);
  const [idOptimizador, setIdOptimizador] = useState(null);
  //  Filtros Personalizados
  const [filtroPersonalizado1, setFiltroPersonalizado1] = useState(false);
  const [filtroPersonalizadoNombre1, setFiltroPersonalizadoNombre1] = useState('');
  const [listaValorPersonalizada1, setListaValorPersonalizada1] = useState([]);
  const [selectValorPersonaliza1, setSelectValorPersonaliza1] = useState([]);
  const [filtroPersonalizado2, setFiltroPersonalizado2] = useState(false);
  const [filtroPersonalizadoNombre2, setFiltroPersonalizadoNombre2] = useState('');
  const [listaValorPersonalizada2, setListaValorPersonalizada2] = useState([]);
  const [selectValorPersonaliza2, setSelectValorPersonaliza2] = useState([]);

  const [caracRegistros, setCaracRegistros] = useState(false);
  const [listaCarac, setListaCarac] = useState([]);
  const [listaPosic, setListaPosic] = useState([]);
  const [listaReg, setListaReg] = useState([]);
  const [datosRegistros, setDatosRegistros] = useState(false);
  const [options, setOptions] = useState(null);
  const {
    aListaEstructurasBaseCrearFlujoCaja,
    aTiemposPeriodoCrearFlujoCaja,
    aMonedasCrearFlujoCaja,
    dDateRange,
    aSociedadesCrearFlujoCaja,
  } = flujoCajaState;
  const [aListaRegistro2, setAListaRegistro2] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const ref2 = useRef(null);
  const ref = useRef(null);

  const showParametros = () => setExpandedParametroEntrada(!expandedParametroEntrada);

  const limpiarFormAgregarRegM = () => {
    setFormAgregarRegM(initialFormAgregarRegM);
  };

  useEffect(() => {
    if (personalizacionState.listaPersonalizada.length > 0 && authState.user.uid != '') {
      dispatch(updateTitle({ title: 'Crear Flujo de Caja' }));
      (async () => {
        loadEstructura();
        loadPeriodo();
        loadListadoTC();
        loadEtiquetasM();
        loadMoneda();
        loadSociedadUsu();
      })();
    }
  }, [personalizacionState.listaPersonalizada, authState.user]);

  // useEffect(() => {
  //   if (listaCarac.length > 0) {
  //     actualizarCaracteristicas();
  //   }
  // }, [listaCarac]);

  useEffect(() => {
    if (listaPosic.length > 0) {
      (async () => {
        await ejecutarPosic();
      })();
    }
  }, [listaPosic]);

  const ejecutarPosic = async () => {
    var sFechaInicio = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[0], '-');
    var sFechaFinal = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[1], '-');
    var ListaEtiquetas = '';
    if (flujoCajaState.selectEtiquetaFlujoCaja.length > 0) {
      flujoCajaState.selectEtiquetaFlujoCaja.forEach((element, index) => {
        if (index === 0) {
          ListaEtiquetas = element.value.toString();
        } else {
          ListaEtiquetas = ListaEtiquetas + ',' + element.value.toString();
        }
      });
    }
    var ListaSociedades = '';
    if (flujoCajaState.selectSociedad.length > 0) {
      flujoCajaState.selectSociedad.forEach((element, index) => {
        if (index === 0) {
          ListaSociedades = element.value.toString();
        } else {
          ListaSociedades = ListaSociedades + ',' + element.value.toString();
        }
      });
    }
    // Personalizacion de Moneda y Periodo

    let monedaInicial = aMonedasCrearFlujoCaja.filter(
      (v) =>
        v.value ==
        personalizacionState.listaPersonalizada.filter((v) => v.DescPersonalizacion === 'Moneda')[0]
          .DatoPersonalizacion
    );

    let periodoInicial = aTiemposPeriodoCrearFlujoCaja.filter(
      (v) =>
        v.value ==
        personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'Periodo'
        )[0].DatoPersonalizacion
    );

    // for (let index = 0; index < listaPosic.length; index++) {
    var respuestaObtenerCaracRe = await callEndpointSP(
      obtenerSplitRegistros({
        // obtenerSplitRegistrosNew({
        sFechaInicio: sFechaInicio,
        sFechaFin: sFechaFinal,
        sPeriodo: periodoInicial[0].sCodFechaPeriodo,
        sMoneda: monedaInicial[0].sAbreviatura,
        sIdEstructura: flujoCajaState.iIdEstructura,
        ListaEtiquetas: ListaEtiquetas,
        Sociedad: ListaSociedades,
        IDUsuario: authState.user.uid,
        IDUniverso: flujoCajaState.universo[0].value,
        //Cambios mejora de tiempo
        IDTIPOSERVICIO: 7,
        // IDPOSICION_M: listaPosic[index],
        IDPOSICION_M: 0,
        //Cambios mejora de tiempo
      })
    );

    // let posicIndexsf = finalArraySinFiltro.findIndex((r) => r.Id_Partida2 === listaPosic[index]);

    // respuestaObtenerCaracRe.data.respuesta.aListaSplitAgrupada.forEach((r) => {
    //   finalArraySinFiltro.splice(posicIndexsf + 1, 0, r);
    // });
    // }
    if (respuestaObtenerCaracRe.data) {
      // const transformData = (people, dates) => {
      //   return people.map((person) => {
      //     const values = dates.map((date, index) => ({
      //       Id: person.Id,
      //       Id2: person.Id2,
      //       IdHijo: person.IdPadre + '-' + index,
      //       IdHijo2: person.IdPadre2 + '-' + index,
      //       IdPadre: person.IdPadre,
      //       IdPadre2: person.IdPadre2,
      //       EsGrupo: person.EsGrupo,
      //       EsManual: person.EsManual,
      //       EsRaiz: person.EsRaiz,
      //       EsSplit: person.EsSplit,
      //       Posicion: person.Posicion,
      //       EsManual: person.EsManual,
      //       bEditable: 'NULL',
      //       IdRegistro: 0,
      //       sAccion: 'C',
      //       Valor: person.sFecha === date.sFechaMin ? person.Importe_Red : 0,
      //     }));

      //     return { ...person, Items: values };
      //   });
      // };
      //
      // const result = transformData(
      //   respuestaObtenerCaracRe.data.respuesta.aListaSplitAgrupada,
      //   respuestaObtenerCaracRe.data.respuesta.aListaCabecera
      // );
      // setFinalArraySinFiltro(result);
      setFinalArraySinFiltro(respuestaObtenerCaracRe.data.respuesta.aListaSplitAgrupada);
      setDatosRegistros(true);

      setIdMatrizExplotada(respuestaObtenerCaracRe.data.respuesta.ID_Matriz_Exp);
    }
  };

  // useEffect(() => {
  //   if (caracRegistros) {
  //     (async () => {
  //       await ejecutarCaracteristicas();
  //     })();
  //   }
  // }, [caracRegistros]);

  useEffect(() => {
    const getHeight = ref2.current.scrollHeight;
    setAccodionHeightParametroEntrada(getHeight);
  }, [expandedParametroEntrada]);

  useEffect(() => {
    if (aListaEstructurasBaseCrearFlujoCaja.length > 0) {
      if (
        personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'Estructura'
        ).length > 0
      ) {
        var oParam = {};

        let estructuraPersonalizada = aListaEstructurasBaseCrearFlujoCaja.filter(
          (v) =>
            v.value ==
            personalizacionState.listaPersonalizada.filter(
              (v) => v.DescPersonalizacion === 'Estructura'
            )[0].DatoPersonalizacion
        );

        oParam.iIdEstructura = estructuraPersonalizada[0].value;
        oParam.sEstructura = estructuraPersonalizada[0].label;
        oParam.validate = true;
        dispatch(onChangeEstructuraCrearFlujoCaja(oParam));
      }
    }
    if (
      personalizacionState.listaPersonalizada.filter((v) => v.DescPersonalizacion === 'Universo')
        .length > 0
    ) {
      var oParam = {};

      let UniversoPersonalizada = listaUniverso.filter(
        (v) =>
          v.value ==
          personalizacionState.listaPersonalizada.filter(
            (v) => v.DescPersonalizacion === 'Universo'
          )[0].DatoPersonalizacion
      );
      dispatch(
        onUniverso({
          universo: UniversoPersonalizada,
        })
      );
    }
    if (
      personalizacionState.listaPersonalizada.filter(
        (v) => v.DescPersonalizacion === 'Horizonte_Num'
      ).length > 0
    ) {
      var oParam = {};

      let HorizonteNumPersonalizada = personalizacionState.listaPersonalizada.filter(
        (v) => v.DescPersonalizacion === 'Horizonte_Num'
      )[0].DatoPersonalizacion;
      changeProyeccionNum(HorizonteNumPersonalizada);
    }

    if (
      personalizacionState.listaPersonalizada.filter(
        (v) => v.DescPersonalizacion === 'Horizonte_Det'
      ).length > 0
    ) {
      var oParam = {};

      let HorizonteDetPersonalizada = listaProyeccion.filter(
        (v) =>
          v.value ==
          personalizacionState.listaPersonalizada.filter(
            (v) => v.DescPersonalizacion === 'Horizonte_Det'
          )[0].DatoPersonalizacion
      );
      handleChangeTiempoProyeccion(HorizonteDetPersonalizada[0]);
    }
  }, [personalizacionState.listaPersonalizada, aListaEstructurasBaseCrearFlujoCaja]);

  useEffect(() => {
    let result = [];
    let resultCaceberas = cloneDeep(flujoCajaState.detalleFlujoCajaCabecerasInicial);

    if (finalArraySinFiltro.length > 0) {
      let lista = [];

      const finalArraySinFiltroCopy = cloneDeep(finalArraySinFiltro); //finalArraySinFiltro.map((object) => ({ ...object }));
      const selectedFilters = {
        posiciones: [],
        caracteristicas: [],
        valores: [],
      };

      flujoCajaState.aFiltrosDetalleFlujoCaja.forEach((element) => {
        element.selectPosicion.forEach((element2) => {
          if (element2.value != '*') {
            selectedFilters.posiciones.push(element2.IdPosicion);
          }
        });
        selectedFilters.caracteristicas.push(element.selectCaracteristica);
        element.selectValor.forEach((element2) => {
          if (element2.value != '*') {
            selectedFilters.valores.push(element2);
          }
        });
      });

      const { posiciones, valores, caracteristicas } = selectedFilters;
      if (posiciones.length === 0 && valores.length === 0 && caracteristicas.length === 0) {
        finalArraySinFiltroCopy.forEach((element) => {
          lista.push({ ...element });
        });
      } else {
        var colectData = collect;

        finalArraySinFiltroCopy.forEach((element) => {
          if (element.IdentificadorCPR === 'C') {
            //
            element.Items.forEach((element2) => {
              element2.Valor = 0;
            });
            lista.push(element);
            // return;
          } else if (element.IdentificadorCPR === 'P') {
            lista.push(element);
          } else if (element.IdentificadorCPR === 'D') {
            lista.push(element);
          } else if (element.IdentificadorCPR === 'R') {
            let datos_reg = [];
            if (posiciones.includes(element.Id_Partida2)) {
              var Id_Partida2 = element.Id_Partida2;
              let datos_reg = element.datos_reg;
              var no_error = false;

              for (let index = 0; index < datos_reg.length; index++) {
                if (
                  selectedFilters.caracteristicas[0][0].NombreCampo ===
                  datos_reg[index].sNombreCampo
                ) {
                  const sDatoPosicion = datos_reg[index].sDatoPosicion;

                  var aEncontrado = selectedFilters.valores.filter(function (item, index) {
                    return (
                      item.aIdPosicion.filter(function (item2, index2) {
                        return item2 === Id_Partida2;
                      }).length > 0 && item.value === sDatoPosicion
                    );
                  });

                  if (aEncontrado.length > 0) {
                    no_error = true;
                    break;
                  }
                }
              }

              if (no_error) {
                lista.push(element);
              }
            } else {
              lista.push(element);
            }
          }
        });
      }
      console.log(lista);
      result = lista;

      resultCaceberas = filterPeridiocidad();
      result = onRealizarDesdoblesAutomatico(result, resultCaceberas);

      // CAMBIO DE 0

      //if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo != "D"){
      var aListaFinal = [];
      if (!datosRegistros) {
        for (var index2 = 0; index2 < result.length; index2++) {
          const element2 = { ...result[index2] };
          const elementCopia = { ...result[index2] };
          elementCopia.Items = [];

          for (var index = 0; index < resultCaceberas.length; index++) {
            let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;

            var obj = {};
            obj['Id'] = Id + '-' + (index + 1);
            obj['Id2'] = Id + '-' + (index + 1);
            obj['IdPadre'] = Id;
            obj['IdPadre2'] = Id;
            obj['IdHijo'] = Id + '-' + (index + 1);
            obj['IdHijo2'] = Id + '-' + (index + 1);
            obj['IdRegistro'] = index + 1;
            obj['sAccion'] = 'C';
            obj['EsRaiz'] = EsRaiz;
            obj['EsGrupo'] = EsGrupo;
            obj['EsManual'] = EsManual;
            obj['EsSplit'] = EsSplit;
            obj['bEditable'] = bEditable;
            obj['Posicion'] = Posicion;
            obj['Valor'] = 0;
            try {
              const element = { ...resultCaceberas[index] };
              if (flujoCajaState.selectPeridiocidadTableFC[0].value === 1) {
                const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = element2.Items[index];
                obj = {
                  ...obj,
                  ...{
                    EsGrupo: EsGrupo,
                    EsManual: EsManual,
                    EsRaiz: EsRaiz,
                    EsSplit: EsSplit,
                    bEditable: bEditable,
                  },
                };
                obj.Valor =
                  element2.Items[index].Valor * flujoCajaState.factorMonedaTableTC[0].valor;
              } else {
                let acum = 0;
                obj = {
                  ...obj,
                  ...{
                    EsGrupo: EsGrupo,
                    EsManual: EsManual,
                    EsRaiz: EsRaiz,
                    EsSplit: EsSplit,
                    bEditable: bEditable,
                  },
                };
                element2.Items.find(function (item, i) {
                  if (element.desde <= i && i <= element.hasta) {
                    acum = acum + item.Valor;
                  }
                });
                obj.Valor = acum * flujoCajaState.factorMonedaTableTC[0].valor; //flujoCajaState.selectMonedaTableFC[0].factor
              }
            } catch (error) {}
            elementCopia.Items.push(obj);
          }
          aListaFinal.push(elementCopia);
        }
      } else {
        for (var index2 = 0; index2 < result.length; index2++) {
          const element2 = { ...result[index2] };
          const elementCopia = { ...result[index2] };
          // for (let index2 = 0; index2 < result.length; index2++) {
          elementCopia.Items = [];
          if (element2.IdentificadorCPR === 'R') {
            var indexEncontrado = -1;
            element2.Items.find(function (item, i) {
              if (item.EsSplit === 0) {
                indexEncontrado = i;
                return i;
              }
            });
          }
          for (var index = 0; index < resultCaceberas.length; index++) {
            const element = { ...resultCaceberas[index] };
            var acumulador = 0;
            //
            let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;
            var obj = {};
            obj['Id'] = Id + '-' + (index + 1);
            obj['Id2'] = Id + '-' + (index + 1);
            obj['IdPadre'] = Id;
            obj['IdPadre2'] = Id;
            obj['IdHijo'] = Id + '-' + (index + 1);
            obj['IdHijo2'] = Id + '-' + (index + 1);
            obj['IdRegistro'] = index + 1;
            obj['sAccion'] = 'C';
            obj['EsRaiz'] = EsRaiz;
            obj['EsGrupo'] = EsGrupo;
            obj['EsManual'] = EsManual;
            obj['EsSplit'] = EsSplit;
            obj['bEditable'] = bEditable;
            obj['Posicion'] = Posicion;
            obj['Valor'] = 0;
            try {
              if (element2.IdentificadorCPR === 'R') {
                // if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo === "D"){
                if (flujoCajaState.selectPeridiocidadTableFC[0].value === 1) {
                  const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = element2.Items[index];
                  obj = {
                    ...obj,
                    ...{
                      EsGrupo: EsGrupo,
                      EsManual: EsManual,
                      EsRaiz: EsRaiz,
                      EsSplit: EsSplit,
                      bEditable: bEditable,
                    },
                  };
                  obj.Valor =
                    element2.Items[index].Valor * flujoCajaState.factorMonedaTableTC[0].valor;
                } else {
                  if (element.desde <= indexEncontrado && indexEncontrado <= element.hasta) {
                    var aEncontrado = element2.Items.filter(function (item) {
                      return item.EsSplit === 0;
                    });
                    if (aEncontrado.length > 0) {
                      const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = aEncontrado[0];
                      obj = {
                        ...obj,
                        ...{
                          EsGrupo: EsGrupo,
                          EsManual: EsManual,
                          EsRaiz: EsRaiz,
                          EsSplit: EsSplit,
                          bEditable: bEditable,
                        },
                      };
                      obj.Valor =
                        aEncontrado[0].Valor * flujoCajaState.factorMonedaTableTC[0].valor; //flujoCajaState.selectMonedaTableFC[0].factor
                    }
                  }
                }
              }
            } catch (error) {}
            elementCopia.Items.push(obj);
          }
          aListaFinal.push(elementCopia);
        }
      }
      // for (let index = 0; index < resultCaceberas.length; index++) {
      // for (var index2 = 0; index2 < result.length; index2++) {
      //   const element2 = { ...result[index2] };
      //   const elementCopia = { ...result[index2] };
      //   // for (let index2 = 0; index2 < result.length; index2++) {
      //   elementCopia.Items = [];
      //   // if (element2.IdentificadorCPR === 'R') {
      //   var indexEncontrado = -1;
      //   element2.Items.find(function (item, i) {
      //     if (item.EsSplit === 0) {
      //       indexEncontrado = i;
      //       return i;
      //     }
      //   });
      //   // }
      //   for (var index = 0; index < resultCaceberas.length; index++) {
      //     const element = { ...resultCaceberas[index] };
      //     var acumulador = 0;
      //     //
      //     let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;
      //     var obj = {};
      //     obj['Id'] = Id + '-' + (index + 1);
      //     obj['Id2'] = Id + '-' + (index + 1);
      //     obj['IdPadre'] = Id;
      //     obj['IdPadre2'] = Id;
      //     obj['IdHijo'] = Id + '-' + (index + 1);
      //     obj['IdHijo2'] = Id + '-' + (index + 1);
      //     obj['IdRegistro'] = index + 1;
      //     obj['sAccion'] = 'C';
      //     obj['EsRaiz'] = EsRaiz;
      //     obj['EsGrupo'] = EsGrupo;
      //     obj['EsManual'] = EsManual;
      //     obj['EsSplit'] = EsSplit;
      //     obj['bEditable'] = bEditable;
      //     obj['Posicion'] = Posicion;
      //     obj['Valor'] = 0;
      //     try {
      //       // if (element2.IdentificadorCPR === 'R') {
      //       // if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo === "D"){
      //       if (flujoCajaState.selectPeridiocidadTableFC[0].value === 1) {
      //         const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = element2.Items[index];
      //         obj = {
      //           ...obj,
      //           ...{
      //             EsGrupo: EsGrupo,
      //             EsManual: EsManual,
      //             EsRaiz: EsRaiz,
      //             EsSplit: EsSplit,
      //             bEditable: bEditable,
      //           },
      //         };
      //         obj.Valor = element2.Items[index].Valor * flujoCajaState.factorMonedaTableTC[0].valor;
      //       } else {
      //         if (element.desde <= indexEncontrado && indexEncontrado <= element.hasta) {
      //           var aEncontrado = element2.Items.filter(function (item) {
      //             return item.EsSplit === 0;
      //           });
      //           if (aEncontrado.length > 0) {
      //             const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = aEncontrado[0];
      //             obj = {
      //               ...obj,
      //               ...{
      //                 EsGrupo: EsGrupo,
      //                 EsManual: EsManual,
      //                 EsRaiz: EsRaiz,
      //                 EsSplit: EsSplit,
      //                 bEditable: bEditable,
      //               },
      //             };
      //             obj.Valor = aEncontrado[0].Valor * flujoCajaState.factorMonedaTableTC[0].valor; //flujoCajaState.selectMonedaTableFC[0].factor
      //           }
      //         }
      //       }
      //       // }
      //     } catch (error) {}
      //     elementCopia.Items.push(obj);
      //   }
      //   aListaFinal.push(elementCopia);
      // }

      setFinalArray(aListaFinal);

      // CAMBIO DE 0
      //  }else{
      //   aListaFinal = result;
      //  }
      var rowsInput = [];
      cloneDeep(aListaFinal).forEach((element) => {
        // cloneDeep(result).forEach((element) => {
        var obj = { ...element };
        obj.Items = [];
        element.Items.forEach((element2) => {
          obj.Items.push({ ...element2 });
        });
        rowsInput.push(obj);
      });

      var rowsInputResponse = realizarRecalculoMejorado(
        ['S', 'I', 'E'],
        cloneDeep(rowsInput),
        cloneDeep(resultCaceberas)
      );
      var aListaSplitAgrupadaSaldo = rowsInputResponse.filter((element) => {
        return element.Id.includes('S');
      });
      collapseChild('S', aListaSplitAgrupadaSaldo);
      var aListaSplitAgrupadaIngreso = rowsInputResponse.filter((element) => {
        return element.Id.includes('I');
      });
      collapseChild('I', aListaSplitAgrupadaIngreso);
      var aListaSplitAgrupadaEgreso = rowsInputResponse.filter((element) => {
        return element.Id.includes('E');
      });
      collapseChild('E', aListaSplitAgrupadaEgreso);
      let aListaSplitAgrupada = [
        ...aListaSplitAgrupadaSaldo,
        ...aListaSplitAgrupadaIngreso,
        ...aListaSplitAgrupadaEgreso,
      ];
      calcularTotalYsubTotal(aListaSplitAgrupada, resultCaceberas);

      let listaCollapse = [];

      if (aListaSplitAgrupada.length > 0 && flujoCajaState.aplicarBusqueda === null) {
        setSelectionCollapseModel(
          aListaSplitAgrupada.filter(function (item, index) {
            return item.collapse === false && item.collapseIcon === true;
          })
        );
        dispatch(onSetAplicarBusquedaFlujoCaja({ aplicarBusqueda: true }));
      }
      if (flujoCajaState.aplicarBusqueda) {
        listaCollapse = [...selectionCollapseModel];
        listaCollapse = cloneDeep(listaCollapse);

        const aPosicionesAbiertas = listaCollapse.filter(function (item, index) {
          return (
            item.IdentificadorCPR === 'P' && item.collapse === false && item.collapseIcon === true
          );
        });

        let listaNivelAbrir = [];
        for (let index = 0; index < aPosicionesAbiertas.length; index++) {
          const oItem = aPosicionesAbiertas[index];

          const aListaSplitAgrupadaClone = cloneDeep(aListaSplitAgrupada);
          var aEncontrado = aListaSplitAgrupadaClone.filter(function (item, index) {
            return (
              item.Id.includes(oItem.Id) &&
              item.Id != oItem.Id &&
              item.Id.split('-').length - oItem.Id.split('-').length === 1
            );
          });

          if (aEncontrado.length > 0) {
            for (let index = 0; index < aEncontrado.length; index++) {
              const element = aEncontrado[index];
              element.collapse = false;
              element.collapseIcon = true;
              listaNivelAbrir.push(element);
            }
          }
          var aEncontradoHijos = aListaSplitAgrupadaClone.filter(function (item, index) {
            return item.Id.indexOf(oItem.Id + '-') !== -1;
          });

          if (aEncontradoHijos.length > 0) {
            for (let iHijos = 0; iHijos < aEncontradoHijos.length; iHijos++) {
              const elementHijo = aEncontradoHijos[iHijos];
              elementHijo.collapse = false;
              elementHijo.collapseIcon = true;
              listaNivelAbrir.push(elementHijo);
            }
          }
        }
        listaCollapse = [...listaCollapse, ...listaNivelAbrir];

        listaCollapse = [
          ...listaCollapse
            .filter(function (item, index) {
              return item.Id.includes('S');
            })
            .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
          ...listaCollapse
            .filter(function (item, index) {
              return item.Id.includes('I');
            })
            .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
          ...listaCollapse
            .filter(function (item, index) {
              return item.Id.includes('E');
            })
            .sort((a, b) => (a.Id > b.Id ? 1 : -1)),
        ];
        console.log(listaCollapse);

        for (let index = 0; index < listaCollapse.length; index++) {
          const element = listaCollapse[index];
          var aEncontrado = aListaSplitAgrupada.filter(function (item, index) {
            return item.Id === element.Id;
          });

          if (aEncontrado.length > 0) {
            aEncontrado[0].collapse = element.collapse;

            aEncontrado[0].collapseIcon = element.collapseIcon;
          }
        }
      }
      console.log(selectedDesdobles);

      // if (selectedDesdobles.length > 0) {
      const prueba = (indice, colapseItem, lista) => {
        let aEncontrados = lista.filter((element) => {
          return (
            element.Id.includes(colapseItem.Id.substr(0, indice + 1)) &&
            indice >= element.Id.lastIndexOf('-')
          );
        });

        for (let i = 0; i < aEncontrados.length; i++) {
          const aCaractF = selectedDesdobles.filter((r) => r.collapse === true);
          if (aCaractF.length > 0) {
            let caractFilter = aCaractF[0].oDesdoble.iIdCaracteristica;
            let pLista = listaCollapse.filter(
              (r) =>
                r.Id.includes(aEncontrados[i]['Id'] + '-') &&
                r.collapse === true &&
                r.datos_reg.filter((el) => {
                  return (
                    el.iIdCaracteristica === caractFilter &&
                    el.sDatoPosicion ===
                      aEncontrados[i]['datos_reg'].filter((el1) => {
                        return el1.iIdCaracteristica === caractFilter;
                      }).sDatoPosicion
                  );
                })
            );
            let pLista1 = lista.filter(
              (r) =>
                r.Id.includes(aEncontrados[i]['Id'] + '-') &&
                r.collapse === true &&
                r.datos_reg.filter((el) => {
                  return (
                    el.iIdCaracteristica === caractFilter &&
                    el.sDatoPosicion ===
                      aEncontrados[i]['datos_reg'].filter((el1) => {
                        return el1.iIdCaracteristica === caractFilter;
                      }).sDatoPosicion
                  );
                })
            );
            if (pLista.length >= 1 && pLista1.length >= 1) {
              aEncontrados[i]['collapse'] = true;
              aEncontrados[i]['collapseIcon'] = false;
            } else {
              if (aEncontrados[i]['EsGrupo'] === 0) {
                aEncontrados[i]['collapse'] = false;
                aEncontrados[i]['collapseIcon'] = true;
              }
            }
          }
        }
        if (colapseItem.Id.substr(0, indice).lastIndexOf('-') > 0) {
          lista = prueba(colapseItem.Id.substr(0, indice).lastIndexOf('-'), colapseItem, lista);
        }
        return lista;
      };
      // let listaPrueba = prueba();
      // console.log('prueba', listaPrueba);
      setFinalArray(aListaSplitAgrupada);
      // }
    }
    setShowGrafico(false);
  }, [
    selectedDesdobles,
    setSelectedDesdobles,
    flujoCajaState.selectPeridiocidadTableFC,
    flujoCajaState.onSelectPeridiocidadTableFC,
    flujoCajaState.aFiltrosDetalleFlujoCaja,
    flujoCajaState.selectMonedaTableFC,
    datosRegistros,
  ]);

  useEffect(() => {
    if (flujoCajaState.selectListaSoluciones.length > 0) {
      onBuscarDetalle();
    }
  }, [flujoCajaState.selectListaSoluciones, changeSelectListaSoluciones]);

  useEffect(() => {
    (async () => {
      if (
        flujoCajaState.selectListaAgrupadaXSoluciones.length > 0 &&
        flujoCajaState.selectListaSoluciones.length > 0
      ) {
        // onBuscarDetalle();
        var finalArrayNuevo = finalArray.map((object) => ({ ...object }));
        //AGREGGAMOS EL ARRAY
        let result = [];
        let resultCaceberas = flujoCajaState.detalleFlujoCajaCabecerasInicial.map((object) => ({
          ...object,
        })); //[...props.finalArraySinFiltro];
        let lista = [];
        const finalArraySinFiltroCopy = finalArraySinFiltro.map((object) => ({ ...object })); //[...props.finalArraySinFiltro];
        const selectedFilters = {
          posiciones: [],
          caracteristicas: [],
          valores: [],
        };
        // flujoCajaState.aFiltrosDetalleFlujoCaja.forEach((element) => {
        //   element.selectPosicion.forEach((element2) => {
        //     selectedFilters.posiciones.push(element2.IdPosicion);
        //   });
        //   selectedFilters.caracteristicas.push(element.selectCaracteristica);
        //   element.selectValor.forEach((element2) => {
        //     selectedFilters.valores.push(element2);
        //   });
        // });

        flujoCajaState.aFiltrosDetalleFlujoCaja.forEach((element) => {
          element.selectPosicion.forEach((element2) => {
            if (element2.value != '*') {
              selectedFilters.posiciones.push(element2.IdPosicion);
            }
          });
          selectedFilters.caracteristicas.push(element.selectCaracteristica);
          element.selectValor.forEach((element2) => {
            if (element2.value != '*') {
              selectedFilters.valores.push(element2);
            }
          });
        });
        const { posiciones, valores, caracteristicas } = selectedFilters;
        // if (posiciones.length === 0 && valores.length === 0 && caracteristicas.length === 0) {
        //   finalArraySinFiltroCopy.forEach((element) => {
        //     lista.push({ ...element });
        //   });
        // } else {
        //   var colectData = collect;
        //   finalArraySinFiltroCopy.forEach((element) => {
        //     if (element.IdentificadorCPR === 'C') {
        //       //
        //       element.Items.forEach((element2) => {
        //         element2.Valor = 0;
        //       });
        //       lista.push(element);
        //       // return;
        //     } else if (element.IdentificadorCPR === 'P') {
        //       lista.push(element);
        //     } else if (element.IdentificadorCPR === 'D') {
        //       lista.push(element);
        //     } else if (element.IdentificadorCPR === 'R') {
        //       let datos_reg = [];
        //       if (posiciones.includes(element.Id_Partida2)) {
        //         var Id_Partida2 = element.Id_Partida2;
        //         let datos_reg = element.datos_reg;
        //         var no_error = false;
        //         //
        //         for (let index = 0; index < datos_reg.length; index++) {
        //           const element = datos_reg[index];
        //           var aEncontrado = selectedFilters.valores.filter(
        //             (o) => o.NombreInstancia === element.sDatoPosicion && Id_Partida2 === o.IdPosicion
        //           );
        //           if (aEncontrado.length > 0) {
        //             no_error = true;
        //             break;
        //           }
        //         }
        //         if (no_error) {
        //           lista.push(element);
        //         }
        //       } else {
        //         lista.push(element);
        //       }
        //     }
        //   });
        // }

        if (posiciones.length === 0 && valores.length === 0 && caracteristicas.length === 0) {
          finalArraySinFiltroCopy.forEach((element) => {
            lista.push({ ...element });
          });
        } else {
          var colectData = collect;
          finalArraySinFiltroCopy.forEach((element) => {
            if (element.IdentificadorCPR === 'C') {
              //
              element.Items.forEach((element2) => {
                element2.Valor = 0;
              });
              lista.push(element);
              // return;
            } else if (element.IdentificadorCPR === 'P') {
              lista.push(element);
            } else if (element.IdentificadorCPR === 'D') {
              lista.push(element);
            } else if (element.IdentificadorCPR === 'R') {
              let datos_reg = [];

              if (posiciones.includes(element.Id_Partida2)) {
                var Id_Partida2 = element.Id_Partida2;
                let datos_reg = element.datos_reg;
                var no_error = false;
                //
                for (let index = 0; index < datos_reg.length; index++) {
                  if (
                    selectedFilters.caracteristicas[0][0].NombreCampo ==
                    datos_reg[index].sNombreCampo
                  ) {
                    const sDatoPosicion = datos_reg[index].sDatoPosicion;

                    var aEncontrado = selectedFilters.valores.filter(function (item, index) {
                      return (
                        item.aIdPosicion.filter(function (item2, index2) {
                          return item2 === Id_Partida2;
                        }).length > 0 && item.value === sDatoPosicion
                      );
                    });

                    if (aEncontrado.length > 0) {
                      no_error = true;
                      break;
                    }
                  }
                }
                if (no_error) {
                  lista.push(element);
                }
              } else {
                lista.push(element);
              }
            }
          });
        }

        result = lista;

        resultCaceberas = filterPeridiocidadXParametro(
          flujoCajaState.selectPeridiocidadTableSolucionFC
        );
        result = onRealizarDesdoblesAutomatico(result, resultCaceberas);

        //  if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo != "D"){
        var aListaFinal = [];
        // for (let index = 0; index < resultCaceberas.length; index++) {
        for (var index2 = 0; index2 < result.length; index2++) {
          const element2 = { ...result[index2] };
          const elementCopia = { ...result[index2] };
          // for (let index2 = 0; index2 < result.length; index2++) {
          elementCopia.Items = [];
          if (element2.IdentificadorCPR === 'R') {
            var indexEncontrado = -1;
            element2.Items.find(function (item, i) {
              if (item.EsSplit === 0) {
                indexEncontrado = i;
                return i;
              }
            });
          }
          for (var index = 0; index < resultCaceberas.length; index++) {
            const element = { ...resultCaceberas[index] };
            var acumulador = 0;
            let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;
            var obj = {};
            obj['Id'] = Id + '-' + (index + 1);
            obj['Id2'] = Id + '-' + (index + 1);
            obj['IdPadre'] = Id;
            obj['IdPadre2'] = Id;
            obj['IdHijo'] = Id + '-' + (index + 1);
            obj['IdHijo2'] = Id + '-' + (index + 1);
            obj['IdRegistro'] = index + 1;
            obj['sAccion'] = 'C';
            obj['EsRaiz'] = EsRaiz;
            obj['EsGrupo'] = EsGrupo;
            obj['EsManual'] = EsManual;
            obj['EsSplit'] = EsSplit;
            obj['bEditable'] = bEditable;
            obj['Posicion'] = Posicion;
            obj['Valor'] = 0;
            try {
              if (element2.IdentificadorCPR === 'R') {
                // if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo === "D"){
                if (flujoCajaState.selectPeridiocidadTableSolucionFC[0].value === 1) {
                  const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = element2.Items[index];
                  obj = {
                    ...obj,
                    ...{
                      EsGrupo: EsGrupo,
                      EsManual: EsManual,
                      EsRaiz: EsRaiz,
                      EsSplit: EsSplit,
                      bEditable: bEditable,
                    },
                  };
                  obj.Valor =
                    element2.Items[index].Valor * flujoCajaState.factorMonedaTableTC[0].valor;
                } else {
                  if (element.desde <= indexEncontrado && indexEncontrado <= element.hasta) {
                    var aEncontrado = element2.Items.filter(function (item) {
                      return item.EsSplit === 0;
                    });
                    if (aEncontrado.length > 0) {
                      const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = aEncontrado[0];
                      obj = {
                        ...obj,
                        ...{
                          EsGrupo: EsGrupo,
                          EsManual: EsManual,
                          EsRaiz: EsRaiz,
                          EsSplit: EsSplit,
                          bEditable: bEditable,
                        },
                      };
                      obj.Valor =
                        aEncontrado[0].Valor * flujoCajaState.factorMonedaTableTC[0].valor; //flujoCajaState.selectMonedaTableFC[0].factor
                    }
                  }
                }
              }
            } catch (error) {}
            elementCopia.Items.push(obj);
          }
          aListaFinal.push(elementCopia);
        }
        var rowsInput = [];
        [...aListaFinal].forEach((element) => {
          var obj = { ...element };
          obj.Items = [];
          element.Items.forEach((element2) => {
            obj.Items.push({ ...element2 });
          });
          rowsInput.push(obj);
        });
        var rowsInputSaldo = rowsInput.filter((element) => {
          return element.Id.includes('S');
        });
        var rowsInputIngreso = rowsInput.filter((element) => {
          return element.Id.includes('I');
        });
        var rowsInputEgreso = rowsInput.filter((element) => {
          return element.Id.includes('E');
        });
        realizarRecalculo(rowsInputSaldo, rowsInput, resultCaceberas);
        realizarRecalculo(rowsInputIngreso, rowsInput, resultCaceberas);
        realizarRecalculo(rowsInputEgreso, rowsInput, resultCaceberas);
        var aListaSplitAgrupadaSaldo = rowsInput.filter((element) => {
          return element.Id.includes('S');
        });
        collapseChild('S', aListaSplitAgrupadaSaldo);
        var aListaSplitAgrupadaIngreso = rowsInput.filter((element) => {
          return element.Id.includes('I');
        });
        collapseChild('I', aListaSplitAgrupadaIngreso);
        var aListaSplitAgrupadaEgreso = rowsInput.filter((element) => {
          return element.Id.includes('E');
        });
        collapseChild('E', aListaSplitAgrupadaEgreso);
        let aListaSplitAgrupada = [
          ...aListaSplitAgrupadaSaldo,
          ...aListaSplitAgrupadaIngreso,
          ...aListaSplitAgrupadaEgreso,
        ];
        let posi = aListaSplitAgrupada.length;
        let aListaSoluciones = flujoCajaState.selectListaAgrupadaXSoluciones[0].aListaSoluciones;
        var aListaSolucionesSumarizada = [];
        // for (let index = 0; index < resultCaceberas.length; index++) {

        var responseObtenerPaletaColoresXSolucion = await callEndpoint(
          obtenerPaletaColoresXSolucion({})
        );

        for (var index2 = 0; index2 < aListaSoluciones.length; index2++) {
          const element2 = { ...aListaSoluciones[index2] };
          const elementCopia = { ...aListaSoluciones[index2] };
          // for (let index2 = 0; index2 < result.length; index2++) {
          elementCopia.Items = [];

          const HexColor =
            responseObtenerPaletaColoresXSolucion.data.respuesta.aRegPaletaC_X_Solucion.filter(
              function (item, index) {
                return item.CodTipoSolucion === elementCopia.CodigoSolucion;
              }
            )[0].HexColor;
          elementCopia.HexColor = HexColor;

          for (var index = 0; index < resultCaceberas.length; index++) {
            const element = { ...resultCaceberas[index] };
            var acumulador = 0;
            let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;
            var obj = {};
            obj['Id'] = Id + '-' + (index + 1);
            obj['Id2'] = Id + '-' + (index + 1);
            obj['IdPadre'] = Id;
            obj['IdPadre2'] = Id;
            obj['IdHijo'] = Id + '-' + (index + 1);
            obj['IdHijo2'] = Id + '-' + (index + 1);
            obj['IdRegistro'] = index + 1;
            obj['sAccion'] = 'C';
            obj['EsRaiz'] = EsRaiz;
            obj['EsGrupo'] = EsGrupo;
            obj['EsManual'] = EsManual;
            obj['EsSplit'] = EsSplit;
            obj['bEditable'] = bEditable;
            obj['Posicion'] = Posicion;
            obj['Valor'] = 0;
            try {
              if (flujoCajaState.selectPeridiocidadTableSolucionFC[0].value === 1) {
                obj.Valor =
                  element2.Items[index].Valor * flujoCajaState.factorMonedaTableTC[0].valor;
              } else {
                for (
                  let indexRecorrido = element.desde;
                  indexRecorrido <= element.hasta;
                  indexRecorrido++
                ) {
                  obj['Valor'] = element2.Items[indexRecorrido].Valor + obj['Valor'];
                }
                obj['Valor'] = obj['Valor'] * flujoCajaState.factorMonedaTableTC[0].valor;
              }
            } catch (error) {}
            elementCopia.Items.push(obj);
          }
          aListaSolucionesSumarizada.push(elementCopia);
        }

        let listaSumarizadaCollapse = [];

        for (let index = 0; index < aListaSolucionesSumarizada.length; index++) {
          let element = { ...aListaSolucionesSumarizada[index] };
          if (
            aListaSolucionesSumarizada.filter((r) => r.Id_Partida1 === element.Id_Partida1).length >
            1
          ) {
            element['collapseIcon'] = false;
            element['EsRaiz'] = 0;
            element['collapse'] = true;
            let padreObj = listaSumarizadaCollapse.filter((r) => r.IdPadre === element.Id_Partida1);
            if (padreObj.length === 0) {
              let lastIndexPos =
                element['Posicion'].lastIndexOf('-') <= 0
                  ? element['Posicion'].lastIndexOf(':')
                  : element['Posicion'].lastIndexOf('-');

              let objPadre = {};
              objPadre = { ...element };
              objPadre['collapseIcon'] = false;
              objPadre['collapse'] = false;
              objPadre['EsRaiz'] = 1;
              objPadre['IdPadre'] = element['Id_Partida1'];
              objPadre['Posicion'] =
                lastIndexPos < 0
                  ? element['Posicion']
                  : element['Posicion'].substring(0, lastIndexPos);
              listaSumarizadaCollapse.push(objPadre);
            } else {
              let PadreItems = [];
              for (let iCollapse = 0; iCollapse < padreObj[0]['Items'].length; iCollapse++) {
                const Dato = padreObj[0]['Items'][iCollapse];
                let valor = Dato.Valor + element['Items'][iCollapse].Valor;
                PadreItems.push({
                  ...Dato,
                  ...{ Valor: valor },
                });
              }
              padreObj[0]['Items'] = PadreItems;
            }
          } else {
            element['IdPadre'] = element['Id_Partida1'];
            element['collapseIcon'] = false;
            element['collapse'] = false;
            element['EsRaiz'] = 0;
          }
          listaSumarizadaCollapse.push(element);
          //  aListaSplitAgrupada.splice(posi, 0, element);

          posi++;
        }

        // calcularTotalYsubTotal(aListaSplitAgrupada,resultCaceberas)
        // realizarRecalculoSubTotal_Total(aListaSplitAgrupada,resultCaceberas)
        // setFinalArray(aListaSplitAgrupada);
        dispatch(
          onSetDetalleListaAgrupadaXSoluciones({
            detalleListaAgrupadaXSoluciones: aListaSplitAgrupada,
          })
        );
        dispatch(
          onSetListaAgrupadaXSolucionesSumarizada({
            aListaSolucionesSumarizada: listaSumarizadaCollapse,
            // aListaSolucionesSumarizada: aListaSolucionesSumarizada,
          })
        );
        var detalleFlujoCajaCabeceras = resultCaceberas;
        var objecto = {};
        objecto.EsRaiz = 1;
        objecto.Id = 'Total Acumulado';
        objecto.sNombre = 'Total Acumulado';
        objecto.Items = [];
        var objectoParcial = {};
        objectoParcial.EsRaiz = 1;
        objectoParcial.Id = 'Total Periodo';
        objectoParcial.sNombre = 'Total Periodo';
        objectoParcial.Items = [];
        var aEncontradoGruposRaiz = aListaSplitAgrupada.filter((element) => {
          return element.EsGrupo === 1 && element.EsRaiz === 1;
        });
        for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
          var fAcumulado = 0;
          var item = {
            Id: objecto.Id + '-' + index,
            Id_Movimiento: null,
            EsRaiz: 1,
            EsGrupo: 1,
            Valor: 0,
          };
          for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
            const element = aEncontradoGruposRaiz[w];
            if (element.Posicion === 'Egreso') {
              fAcumulado = fAcumulado + element.Items[index].Valor;
            } else {
              fAcumulado = fAcumulado + element.Items[index].Valor;
            }
          }
          for (let ss = 0; ss < aListaSolucionesSumarizada.length; ss++) {
            const element = aListaSolucionesSumarizada[ss];
            fAcumulado = fAcumulado + element.Items[index].Valor;
          }
          item.Valor = fAcumulado;
          objecto.Items.push(item);
          objectoParcial.Items.push({ ...item });
        }
        // props.setFinalArrayTotalParciales(objectoParcial);
        for (let index = 0; index < objecto.Items.length; index++) {
          const element = objecto.Items[index - 1];
          if (!element) {
            objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
          } else {
            objecto.Items[index].Valor =
              objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
          }
        }
        dispatch(
          onSetListaAgrupadaXSolucionesParcial({
            detalleListaAgrupadaXSolucionesParcial: objectoParcial,
          })
        );
        dispatch(
          onSetListaAgrupadaXSolucionesTotal({ detalleListaAgrupadaXSolucionesTotal: objecto })
        );
      }
    })();
  }, [flujoCajaState.selectListaAgrupadaXSoluciones]);

  const actualizarCaracteristicas = () => {
    console.log(finalArray);
    console.log(listaCarac);
    finalArray.map((item, i) => {
      let itemEncontrado = item;
      let valorCarac = listaCarac.find(
        (v) =>
          item.Id_Partida1 === v.Id_Partida1 &&
          item.Id_Partida2 === v.Id_Partida2 &&
          item.Id_Partida3 === v.Id_Partida3
      );
      console.log(valorCarac);

      itemEncontrado.datos_carpeta = valorCarac.datos_carpeta;
      itemEncontrado.datos_posic = valorCarac.datos_posic;
      itemEncontrado.datos_reg = valorCarac.datos_reg;
    });
  };

  const ejecutarCaracteristicas = async () => {
    var sFechaInicio = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[0], '-');
    var sFechaFinal = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[1], '-');
    var ListaEtiquetas = '';
    if (flujoCajaState.selectEtiquetaFlujoCaja.length > 0) {
      flujoCajaState.selectEtiquetaFlujoCaja.forEach((element, index) => {
        if (index === 0) {
          ListaEtiquetas = element.value.toString();
        } else {
          ListaEtiquetas = ListaEtiquetas + ',' + element.value.toString();
        }
      });
    }

    // Personalizacion de Moneda y Periodo

    let monedaInicial = aMonedasCrearFlujoCaja.filter(
      (v) =>
        v.value ==
        personalizacionState.listaPersonalizada.filter((v) => v.DescPersonalizacion === 'Moneda')[0]
          .DatoPersonalizacion
    );
    let periodoInicial = aTiemposPeriodoCrearFlujoCaja.filter(
      (v) =>
        v.value ==
        personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'Periodo'
        )[0].DatoPersonalizacion
    );

    var respuestaObtenerCaracRe = await callEndpointSP(
      obtenerSplitRegistros({
        sFechaInicio: sFechaInicio,
        sFechaFin: sFechaFinal,
        sPeriodo: periodoInicial[0].sCodFechaPeriodo,
        sMoneda: monedaInicial[0].sAbreviatura,
        sIdEstructura: flujoCajaState.iIdEstructura,
        ListaEtiquetas: ListaEtiquetas,
        IDUsuario: authState.user.uid,
        IDUniverso: flujoCajaState.universo[0].value,
        //Cambios mejora de tiempo
        IDTIPOSERVICIO: 2,
        IDPOSICION_M: 0,
        //Cambios mejora de tiempo
      })
    );

    var data = respuestaObtenerCaracRe.data;
    if (data) {
      if (data.cabecera.statusCode === 200) {
        setListaCarac(data.respuesta.aListaSplitAgrupada);
      }
    }
  };

  const loadEstructura = async () => {
    let aListaEstructuras = [];
    var { data } = await callEndpoint(obtenerEstructurasBase());
    data.respuesta.aListaEstructuras.forEach(function (item, index) {
      var o = createEstructuraBaseAdapter(item);
      aListaEstructuras.push(o);
    });
    dispatch(onSetEstructurasBaseCrearFlujoCaja(aListaEstructuras));
  };

  const loadPeriodo = async () => {
    let aListaPeriodo = [];
    var { data } = await callEndpoint(obtenerListadoPeriodo());
    data.respuesta.aListaPeriodo.forEach(function (item, index) {
      var o = createPeriodoAdapter(item);
      aListaPeriodo.push(o);
    });
    dispatch(onSetPeriodoCrearFlujoCaja(aListaPeriodo));
  };

  const loadListadoTC = async () => {
    var { data } = await callEndpoint(obtenerListadoTC());
    let aListaTC = [];

    data.respuesta.aListaTC.forEach(function (item, index) {
      // var o = createMonedaAdapter(item);
      aListaTC.push(item);
    });
    dispatch(onSetTipoCambioFlujoCaja({ aListaTC: aListaTC }));
  };

  const loadMoneda = async () => {
    let aListaMoneda = [],
      currencyListManual = [];
    var { data } = await callEndpoint(obtenerListadoMoneda());
    let tipoMoneda = personalizacionState.listaPersonalizada.filter(
      (v) => v.DescPersonalizacion === 'texto_moneda'
    )[0].DatoPersonalizacion;
    data.respuesta.aListaMoneda.forEach(function (item, index) {
      var o = createMonedaAdapter(item, tipoMoneda);
      aListaMoneda.push(o);
      currencyListManual.push(createMonedaAbreviaturaAdapter(item, tipoMoneda));
    });
    dispatch(onSetMonedasCrearFlujoCaja(aListaMoneda));
    setFormAgregarRegM({ ...formAgregarRegM, ...{ currencyListManual: currencyListManual } });
  };

  const loadSociedadUsu = async () => {
    let aListaSociedad = [];

    var { data } = await callEndpoint(listarSociedadUsuario());
    data.respuesta.listSociedad.forEach(function (item, index) {
      aListaSociedad.push({ ...item, ...{ value: item.IDSociedad, label: item.Sociedad } });
    });
    dispatch(onSetSociedadesCrearFlujoCaja(aListaSociedad));
  };

  const handleChangeEstructuraBaseCrearFlujoCaja = (v) => {
    var oParam = {};
    oParam.iIdEstructura = null;
    oParam.sEstructura = '';
    oParam.validate = null;
    if (v) {
      oParam.iIdEstructura = v.value;
      oParam.sEstructura = v.label;
      oParam.validate = true;
      dispatch(onChangeEstructuraCrearFlujoCaja(oParam));
    } else {
      dispatch(onChangeEstructuraCrearFlujoCaja(oParam));
    }
  };

  const handlePersonaliza1ValorMultiCheckbox = async (value, oEvent) => {
    setShowGrafico(false);
    let selectValor = [];
    let aIDPosicion = [];
    let datoPersonalizado1 = flujoCajaState.aFiltrosDetalleFlujoCaja.filter(
      (v) => v.personalizada === 1
    );
    let options = datoPersonalizado1[0].listaValor;
    const detalleFiltro = [...flujoCajaState.aFiltrosDetalleFlujoCaja];
    if (value != null) {
      selectValor = value; //result;
    }
    var oParam = { selectValor: selectValor, personalizada: 1 };
    if (oEvent.action === 'select-option' && oEvent.option.value === '*') {
      // this.setState(this.options);
      oParam.selectValor = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option' && oEvent.option.value === '*') {
      // this.setState([]);
      oParam.selectValor = [];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option') {
      oParam.selectValor = selectValor.filter((o) => o.value !== '*');
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (value.length === options.length - 1) {
      oParam.selectValor = value; //options
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else {
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    }
    //
    let newDetalleFiltro = detalleFiltro.map((item, index) => {
      let newItem = { ...item };
      if (item.personalizada === oParam.personalizada) {
        newItem.selectValor = oParam.selectValor;
      }

      return { ...newItem };
    });
    dispatch(onSetFiltroDetalleFlujoCaja({ aFiltrosDetalleFlujoCaja: newDetalleFiltro }));
    setSelectValorPersonaliza1(oParam.selectValor);
  };

  const handlePersonaliza2ValorMultiCheckbox = async (value, oEvent) => {
    setShowGrafico(false);
    let selectValor = [];
    let aIDPosicion = [];
    let datoPersonalizado2 = flujoCajaState.aFiltrosDetalleFlujoCaja.filter(
      (v) => v.personalizada === 2
    );
    let options = datoPersonalizado2[0].listaValor;
    const detalleFiltro = [...flujoCajaState.aFiltrosDetalleFlujoCaja];
    if (value != null) {
      selectValor = value; //result;
    }
    var oParam = { selectValor: selectValor, personalizada: 2 };
    if (oEvent.action === 'select-option' && oEvent.option.value === '*') {
      // this.setState(this.options);
      oParam.selectValor = [initiallAllPosition, ...options];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option' && oEvent.option.value === '*') {
      // this.setState([]);
      oParam.selectValor = [];
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (oEvent.action === 'deselect-option') {
      oParam.selectValor = selectValor.filter((o) => o.value !== '*');
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else if (value.length === options.length - 1) {
      oParam.selectValor = value; //options
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    } else {
      // dispatch(onChangeRowFValoresFiltroDetalleFlujoCaja(oParam))
    }
    //
    let newDetalleFiltro = detalleFiltro.map((item, index) => {
      let newItem = { ...item };
      if (item.personalizada === oParam.personalizada) {
        newItem.selectValor = oParam.selectValor;
      }

      return { ...newItem };
    });
    dispatch(onSetFiltroDetalleFlujoCaja({ aFiltrosDetalleFlujoCaja: newDetalleFiltro }));
    setSelectValorPersonaliza2(oParam.selectValor);
  };

  const handleChangePeridiocidadTableFC = (oEvent) => {
    setShowGrafico(false);
    var oParam = {};
    oParam.iIDTipoPeriodo = oEvent.value;
    oParam.sCodFechaPeriodo = oEvent.sCodFechaPeriodo;
    oParam.sFechaPeriodo = oEvent.label;
    oParam.validate = true;
    oParam = { ...oParam, ...oEvent };
    dispatch(onSelectPeridiocidadTableFC({ selectPeridiocidadTableFC: [oParam] }));
  };

  const handleChangeMonedaTableFC = (oEvent) => {
    setShowGrafico(false);
    var oParam = {};
    oParam.validate = true;
    oParam = { ...oParam, ...oEvent };

    // let quiero = flujoCajaState.aListaTC.filter(function (item, index) {
    //   return item.iIDTipoMoneda === oEvent.sAbreviatura;
    // })[0].fImporte;

    // let tengo = flujoCajaState.aListaTC.filter(function (item, index) {
    //   return item.iIDTipoMoneda === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial; //flujoCajaState.selectMonedaTableFC[0].iIDTipoMonedaInicial
    // })[0].fImporte;

    // let factorMonedaTableTC = quiero / tengo;

    let findFactorTC = flujoCajaState.aListaTC.filter(function (item, index) {
      return (
        item.MonedaBase === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial &&
        item.NombreMoneda === oEvent.sAbreviatura
      );
    });

    let findFactorTCRe = flujoCajaState.aListaTC.filter(function (item, index) {
      return (
        item.NombreMoneda === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial &&
        item.MonedaBase === oEvent.sAbreviatura
      );
    });

    let factorMonedaTableTC = 0;

    if (findFactorTC.length > 0) {
      factorMonedaTableTC = findFactorTC[0].Factor * findFactorTC[0].Importe;
    } else if (findFactorTCRe.length > 0) {
      factorMonedaTableTC =
        findFactorTCRe[0].Factor / (findFactorTCRe[0].Factor * findFactorTCRe[0].Importe);
    }

    if (factorMonedaTableTC > 0) {
      var oParamFactor = {
        iIDTipoMonedaInicial: flujoCajaState.selectMonedaTableFCInicial[0].sAbreviatura,
        iIDTipoMonedaFinal: oEvent.sAbreviatura,
        valor: factorMonedaTableTC,
      };
      dispatch(
        onSelectMonedaTableFC({
          factorMonedaTableTC: [oParamFactor],
          selectMonedaTableFC: [oParam],
        })
      );
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se tiene tipo de cambio para esa moneda',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleChangeFechaCrearFlujoCaja = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent[0],
      dFechaFin: oEvent[1],
    };
    var sFechaInicio = '';
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';
    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      var iDia = oParam.dFechaInicio.getDate();
      var iMes = oParam.dFechaInicio.getMonth() + 1;
      var iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
    }
    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
    }
    oParam.sFechaInicio = sFechaInicio;
    oParam.sFechaFinal = sFechaFinal;
    dispatch(onChangeFechaCrearFlujoCaja(oParam));
  };

  const loadFiltrosVersionFCXUsuario = async () => {
    var respObtVersionFiltros = await callEndpoint(obtenerVersionesFiltrosFCxUsuario());
    let listaVersionFiltroDetalleFlujoCaja = [];
    if (respObtVersionFiltros != null) {
      if (respObtVersionFiltros.data) {
        respObtVersionFiltros.data.respuesta.aListaVersiones.forEach((element) => {
          let obj = createFiltrosCabFCAdapter({ ...element });
          listaVersionFiltroDetalleFlujoCaja.push(obj);
        });
      }
    }
    dispatch(
      onSetListaVersionFiltroDetalleFlujoCaja({
        listaVersionFiltroDetalleFlujoCaja: listaVersionFiltroDetalleFlujoCaja,
      })
    );
  };

  const loadSolucionesVersionFCXUsuario = async () => {
    var respObtVersionSoluciones = await callEndpoint(obtenerVersionesSolucionFCxUsuario());
    let listaVersionSolucionDetalleFlujoCaja = [];
    if (respObtVersionSoluciones != null) {
      if (respObtVersionSoluciones.data) {
        respObtVersionSoluciones.data.respuesta.aListaVersiones.forEach((element) => {
          let obj = createSolucionesCabFC({ ...element });
          listaVersionSolucionDetalleFlujoCaja.push(obj);
        });
      }
    }
    dispatch(
      onSetListaVersionSolucionDetalleFlujoCaja({
        listaVersionSolucionDetalleFlujoCaja: listaVersionSolucionDetalleFlujoCaja,
      })
    );
  };

  const loadRestriccionesVersionFCXUsuario = async () => {
    var respObtVersionRestricciones = await callEndpoint(obtenerVersionesRestriccionesFCxUsuario());
    let listaVersionRestriccionesDetalleFlujoCaja = [];
    if (respObtVersionRestricciones != null) {
      if (respObtVersionRestricciones.data) {
        respObtVersionRestricciones.data.respuesta.aListaVersiones.forEach((element) => {
          let obj = createRestriccionesCabFCAdapter({ ...element });
          listaVersionRestriccionesDetalleFlujoCaja.push(obj);
        });
      }
    }
    dispatch(
      onSetListaVersionRestriccionesDetalleFlujoCaja({
        listaVersionRestriccionesDetalleFlujoCaja: listaVersionRestriccionesDetalleFlujoCaja,
      })
    );
  };

  const openModalFiltros = async () => {
    await loadFiltrosVersionFCXUsuario();

    let selectionFiltrosModel = [];
    for (let index = 0; index < flujoCajaState.aFiltrosDetalleFlujoCaja.length; index++) {
      const element = { ...flujoCajaState.aFiltrosDetalleFlujoCaja[index] };
      selectionFiltrosModel.push(element);
    }
    setSelectionFiltrosModel(selectionFiltrosModel);
    dispatch(onOpenModalFiltrosDetalleFlujoCaja(true));
  };

  const openModalRestricciones = async () => {
    await loadRestriccionesVersionFCXUsuario();

    await loadAgente();
    const data = await callEndpoint(obtenerCamposHomologadosReglas());
    let caracteristica = [];
    for (let index = 0; index < data.data.respuesta.aListaCamposHomologados.length; index++) {
      const element = createCaracteristicaReglaAdapter({
        ...data.data.respuesta.aListaCamposHomologados[index],
      });
      caracteristica.push(element);
    }
    let selectionRestriccionesModel = [];
    for (let index = 0; index < flujoCajaState.aRestriccionesDetalleFlujoCaja.length; index++) {
      const element = { ...flujoCajaState.aRestriccionesDetalleFlujoCaja[index] };
      selectionRestriccionesModel.push(element);
    }
    setSelectionRestriccionesModel(selectionRestriccionesModel);
    dispatch(onSelectCaracteristicasReglas(caracteristica));
    dispatch(onOpenModalRestriccionesDetalleFlujoCaja(true));
  };

  const loadAgente = async () => {
    const responseObtAgentes = await callEndpoint(obtenerAgentes());

    let aAgentes = [];

    if (responseObtAgentes != null) {
      if (responseObtAgentes.data) {
        let { aListaAgentes } = responseObtAgentes.data.respuesta;

        aListaAgentes.forEach((element) => {
          aAgentes.push(createAgenteSelectAdapter(element));
        });
      }
    }

    dispatch(onSetAgente({ aListaAgente: aAgentes }));
  };

  const openModalSoluciones = async () => {
    await loadSolucionesVersionFCXUsuario();
    await loadSoluciones();
    dispatch(onOpenModalSolucionesDetalleFlujoCaja({ modalSolucionesFlujoCaja: true }));
  };

  const convertirMoneda = (listaSuceso) => {
    let lista = [];
    let findFactorTC = flujoCajaState.aListaTC.filter(function (item, index) {
      return (
        item.MonedaBase === flujoCajaState.selectMonedaTableFC[0].sAbreviatura &&
        item.NombreMoneda === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial
      );
    });
    let factorMonedaTableTC = 0;
    if (findFactorTC.length > 0) {
      factorMonedaTableTC = findFactorTC[0].Factor * findFactorTC[0].Importe;
    } else {
      let findFactorTCRe = flujoCajaState.aListaTC.filter(function (item, index) {
        return (
          item.NombreMoneda === flujoCajaState.selectMonedaTableFC[0].sAbreviatura &&
          item.MonedaBase === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial
        );
      });
      factorMonedaTableTC =
        findFactorTCRe[0].Factor / (findFactorTCRe[0].Factor * findFactorTCRe[0].Importe);
    }

    // let quiero = flujoCajaState.aListaTC.filter(function (item, index) {
    //   return item.iIDTipoMoneda === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial;
    // })[0].fImporte;
    // let tengo = flujoCajaState.aListaTC.filter(function (item, index) {
    //   return item.iIDTipoMoneda === flujoCajaState.selectMonedaTableFC[0].value; //flujoCajaState.selectMonedaTableFC[0].iIDTipoMonedaInicial
    // })[0].fImporte;
    // let factorMonedaTableTC = quiero / tengo;
    for (let index = 0; index < listaSuceso.length; index++) {
      const obj = { ...listaSuceso[index] };
      obj.valor = obj.valor * factorMonedaTableTC;
      // obj.MonedaImporte = flujoCajaState.selectMonedaTableFCInicial[0].value;
      lista.push(obj);
    }
    return lista;
  };

  const onGuardarCambios = async (oEvent) => {
    Swal.fire({
      title: '¿Desea guardar los cambios?',
      html: !compartirAvailable
        ? `Compartir el registro :  <input id="compartido" style="width:18px;height:18px" type='checkbox' />`
        : '',
      icon: 'warning',
      showCancelButton: true,
      preConfirm: () => {
        var compartido;
        if (Swal.getPopup().querySelector('#compartido:checked')) {
          compartido = 1;
        } else compartido = 0;

        return { compartido: compartido };
      },
      preDeny: () => {},
      confirmButtonColor: theme.colors.primary,
      cancelButtonColor: theme.colors.warning,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // await registrarRegistroManual(result.value.compartido);

        let listaSucesosCreados = [];
        let listaSucesosModificados = [];
        let listaSucesosEliminados = [];

        flujoCajaState.listaSucesosCreados.map((r) => listaSucesosCreados.push(r));

        flujoCajaState.listaSucesosModificados.map((r) =>
          listaSucesosModificados.push(cambiosModificadosAdapter(r, result.value.compartido))
        );

        flujoCajaState.listaSucesosEliminados.map((r) =>
          listaSucesosEliminados.push(cambiosEliminadosAdapter(r, result.value.compartido))
        );

        if (
          listaSucesosCreados.length === 0 &&
          listaSucesosModificados.length === 0 &&
          listaSucesosEliminados.length === 0
        ) {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'No se han realizado cambios.',
            showConfirmButton: false,
            timer: 2000,
          });
          return;
        }
        let oParam = {
          Cambios: convertirMoneda(listaSucesosModificados),
          Eliminaciones: convertirMoneda(listaSucesosEliminados),
        };
        var responseGuardarRegistrosFlujoCaja = await callEndpoint(guardarCambiosFlujoCaja(oParam));
        if (responseGuardarRegistrosFlujoCaja === null) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          if (responseGuardarRegistrosFlujoCaja.data.cabecera.statusCode === 200) {
            dispatch(
              onLimpiarCamposFlujoCaja({
                listaSucesosCreados: [],
                listaSucesosModificados: [],
                listaSucesosEliminados: [],
              })
            );
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: responseGuardarRegistrosFlujoCaja.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: responseGuardarRegistrosFlujoCaja.data.cabecera.descripcion,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
        const aRegistroModificados = finalArray.filter((element) => {
          return element.sAccion === 'M'; //&& element.IdentificadorCPR === "R";
        });
      }
    });
  };

  const onGuardarVersion = async (oEvent, finalArrayEliminar) => {
    var bValidForm = validarCampos();
    if (bValidForm) {
      var updateVersion = true;
      if (flujoCajaState.selectVersionFlujoCaja.length === 0) {
        updateVersion = false;
      }
      Swal.fire({
        title: !updateVersion ? '¿Desea guardar la versión?' : '¿Desea sobreescribir la versión?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          var ListaEtiquetas = '';
          flujoCajaState.selectEtiquetaFlujoCaja.forEach((element, index) => {
            if (index === 0) {
              ListaEtiquetas = element.IDEtiqueta.toString();
            } else {
              ListaEtiquetas = ListaEtiquetas + ',' + element.IDEtiqueta.toString();
            }
          });
          var listaSociedad = '';

          flujoCajaState.selectSociedad.forEach((element, index) => {
            if (index === 0) {
              listaSociedad = element.value.toString();
            } else {
              listaSociedad = listaSociedad + ',' + element.value.toString();
            }
          });
          if (!updateVersion) {
            var oParam = {
              RegistroVersionFlujoCaja: {
                sNombreVersion: flujoCajaState.sVersion, //"NombreVersion",
                iIdEstructuraBase: flujoCajaState.iIdEstructura, //1,
                iProyeccion: flujoCajaState.iProyeccion,
                iIdtiempoProyeccion: flujoCajaState.iIdtiempoProyeccion,
                sTiempoProyeccion: flujoCajaState.sTiempoProyeccion,
                sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'), //flujoCajaState.sFechaInicio,
                sFechaFin: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[1], '/'), //flujoCajaState.sFechaFinal,
                iIdPeridiocidad: flujoCajaState.selectPeridiocidadTableFC[0].iIDTipoPeriodo, // 1
                iIdMoneda: flujoCajaState.selectMonedaTableFCInicial[0].value, // 1
                aFiltros: [],
                aReglas: [],
                aSoluciones: [],
                ListaEtiquetas: ListaEtiquetas,
                Sociedad: listaSociedad,
              },
            };
            var responseCrearVersionFlujoCaja = await callEndpoint(crearVersionFlujoCaja(oParam));
            if (responseCrearVersionFlujoCaja === null) {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Ocurrió un error en el servicio.',
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              if (responseCrearVersionFlujoCaja.data.cabecera.statusCode === 200) {
                var oParamSelectVersionFlujoCaja = {};
                oParamSelectVersionFlujoCaja.selectVersionFlujoCaja = [
                  {
                    value:
                      responseCrearVersionFlujoCaja.data.respuesta.RegistroVersionFlujoCaja
                        .idVersion,
                    label: flujoCajaState.sVersion,
                  },
                ];
                oParamSelectVersionFlujoCaja.bValidVersionFlujoCaja = true;
                dispatch(onSelectVersionFlujoCaja(oParamSelectVersionFlujoCaja));
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: responseCrearVersionFlujoCaja.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: responseCrearVersionFlujoCaja.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }
          } else {
            var oParamUpdateVersionFlujoCaja = {
              UpdateVersionFlujoCaja: {
                iIdVersion: flujoCajaState.selectVersionFlujoCaja[0].value,
                sNombreVersion: flujoCajaState.sVersion, //"NombreVersion",
                iIdEstructuraBase: flujoCajaState.iIdEstructura, //1,
                iProyeccion: flujoCajaState.iProyeccion,
                iIdtiempoProyeccion: flujoCajaState.iIdtiempoProyeccion,
                sTiempoProyeccion: flujoCajaState.sTiempoProyeccion,
                sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'), //flujoCajaState.sFechaInicio,
                sFechaFin: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[1], '/'), //flujoCajaState.sFechaFinal,
                iIdPeridiocidad: flujoCajaState.selectPeridiocidadTableFC[0].iIDTipoPeriodo, // 1
                iIdMoneda: flujoCajaState.selectMonedaTableFCInicial[0].value, // 1
                aFiltros: [],
                aReglas: [],
                aSoluciones: [],
                ListaEtiquetas: ListaEtiquetas,
                Sociedad: listaSociedad,
              },
            };
            var responseUpdateVersionFlujoCaja = await callEndpoint(
              updateVerionFlujoCaja(oParamUpdateVersionFlujoCaja)
            );
            if (responseUpdateVersionFlujoCaja === null) {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Ocurrió un error en el servicio.',
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              if (responseUpdateVersionFlujoCaja.data.cabecera.statusCode === 200) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: responseUpdateVersionFlujoCaja.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
                // const aRegistroModificados = finalArray.filter((element) => {
                //   return element.sAccion === "M" && element.IdentificadorCPR === "R";
                // });
                // const aRegistroNuevos = finalArray.filter((element) => {
                //   return element.sAccion === "N" && element.IdentificadorCPR === "R"
                // });
                // const aRegistroEliminados = finalArrayEliminar;
                // let aMovimientosModif = [];
                // let aMovimientosNuevos = []
                // let aMovimientosEliminados = [];
                // aRegistroModificados.forEach(element => {
                //   let { Id_Partida2, Id_Partida3, sFecha, sAccion, Items, posiCabecera } = element;
                //   let obj = {}
                //   obj.Id_Partida2 = Id_Partida2
                //   obj.Id_Partida3 = Id_Partida3;
                //   obj.sFecha = sFecha;
                //   obj.sAccion = sAccion;
                //   obj.Valor = Items[posiCabecera].Valor;
                //   aMovimientosModif.push(obj)
                // });
                // aRegistroEliminados.forEach(element => {
                //   let { Id_Partida1, Id_Partida2, Id_Partida3, sAccion } = element;
                //   let obj = {}
                //   obj.Id_Partida1 = Id_Partida1
                //   obj.Id_Partida2 = Id_Partida2
                //   obj.Id_Partida3 = Id_Partida3;
                //   obj.sAccion = sAccion;
                //   aMovimientosEliminados.push(obj)
                // });
                // var oParam = {
                //   "aMovimientosModif": aMovimientosModif,
                //   "aMovimientosNuevos": aMovimientosNuevos,
                //   "aMovimientosEliminados": aMovimientosEliminados
                // }
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: responseCrearVersionFlujoCaja.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }
          }
        }
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'Por favor, completar la versión, estructura , fecha , periodicidad y moneda del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onPressBuscarVersion = async () => {
    let aListaVersiones = [];

    var { response, data } = await callEndpoint(obtenerVersionesFlujoCaja());

    if (data) {
      data.respuesta.aListaVersiones.forEach(function (item, index) {
        var o = createVersionFlujoCajaAdapter(item);
        aListaVersiones.push(o);
      });
      dispatch(onOpenModalVersionFlujoCaja(true));
    }
    dispatch(onSetListaVersionesFlujoCaja(aListaVersiones));

    if (response) {
      if (response.status === 404) {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: 'No se encontro alguna versión',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const realizarBusqueda = async () => {
    // setCaracRegistros(true);
    // ejecutarCaracteristicas();

    // Limpiar Tablas
    dispatch(onSetTabProceso({ tabProceso: 0 }));
    setFinalArray([]);
    setFinalArraySinFiltro([]);
    setSelectedDesdobles([]);
    dispatch(changeSelectListaSoluciones({ selectListaSoluciones: [] }));
    dispatch(collapseDesdobles({ collapseDesdobles: null }));
    dispatch(onLimpiarFiltroDetalleFlujoCaja([]));
    dispatch(onLimpiarRestriccionDetalleFlujoCaja([]));
    dispatch(
      onSetListaSoluciones({
        aListaSoluciones: [],
        selectListaSoluciones: [],
        aListaAgrupadaXSoluciones: [],
        selectListaAgrupadaXSoluciones: [],
        detalleListaAgrupadaXSoluciones: [],
        detalleListaAgrupadaXSolucionesTotal: [],
        detalleListaAgrupadaXSolucionesParcial: [],
      })
    );
    dispatch(
      limpiarSolucionesFlujoCaja({
        idOptimiacion: null,
        modalSolucionesFlujoCaja: false,
        listaModalSolFlujoCaja: [],
        filterListaModalSolFlujoCaja: [],
        searchModalSolSearchFlujoCaja: '',
        selectTipoEstadoSolFlujoCaja: [],
        selectTipoSoluFlujocaja: [],
        selectSolucionesModalFlujoCaja: [],
        dDateVigenciaSoluFlujoCaja: [null, null],
        bValidDateVigenciaSoluFlujoCaja: null,
      })
    );
    dispatch(
      onLimpiarCamposCrearFlujoCaja({
        listaSucesosCreados: [],
        listaSucesosEliminados: [],
        listaSucesosModificados: [],
      })
    );
    dispatch(onSetAplicarBusquedaFlujoCaja({ aplicarBusqueda: null }));
    setSelectionCollapseModel([]);
    // Limpiar Tablas

    var sFechaInicio = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[0], '-');
    var sFechaFinal = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[1], '-');
    var ListaEtiquetas = '';
    if (flujoCajaState.selectEtiquetaFlujoCaja.length > 0) {
      flujoCajaState.selectEtiquetaFlujoCaja.forEach((element, index) => {
        if (index === 0) {
          ListaEtiquetas = element.value.toString();
        } else {
          ListaEtiquetas = ListaEtiquetas + ',' + element.value.toString();
        }
      });
    }
    var ListaSociedades = '';
    if (flujoCajaState.selectSociedad.length > 0) {
      flujoCajaState.selectSociedad.forEach((element, index) => {
        if (index === 0) {
          ListaSociedades = element.value.toString();
        } else {
          ListaSociedades = ListaSociedades + ',' + element.value.toString();
        }
      });
    }
    // Personalizacion de Moneda y Periodo
    let monedaInicial = aMonedasCrearFlujoCaja.filter(
      (v) =>
        v.value ==
        personalizacionState.listaPersonalizada.filter((v) => v.DescPersonalizacion === 'Moneda')[0]
          .DatoPersonalizacion
    );
    let periodoInicial = aTiemposPeriodoCrearFlujoCaja.filter(
      (v) =>
        v.value ==
        personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'Periodo'
        )[0].DatoPersonalizacion
    );

    if (
      flujoCajaState.selectPeridiocidadTableFC.length === 0 &&
      flujoCajaState.selectMonedaTableFCInicial.length === 0
    ) {
      dispatch(
        onSelectMonedaTableFCInicial({
          selectMonedaTableFCInicial: monedaInicial,
        })
      );
      dispatch(
        onSelectPeridiocidadTableFC({
          selectPeridiocidadTableFC: periodoInicial,
        })
      );
      dispatch(
        onSelectMonedaTableFC({
          selectMonedaTableFC: monedaInicial,
        })
      );

      var oParam = {
        ...flujoCajaState.factorMonedaTableTC[0],
        ...{
          iIDTipoMonedaInicial: monedaInicial[0].sAbreviatura,
          iIDTipoMonedaFinal: monedaInicial[0].sAbreviatura,
        },
      };

      dispatch(
        onSetFactorMonedaTableFC({
          factorMonedaTableTC: [oParam],
        })
      );
    }
    // Personalizacion de Moneda y Periodo
    var respuestaObtenerSplitRegistros = await callEndpoint(
      // obtenerSplitRegistrosNew({
      obtenerSplitRegistros({
        // obtenerSplitRegistros({
        sFechaInicio: sFechaInicio,
        sFechaFin: sFechaFinal,
        sPeriodo: periodoInicial[0].sCodFechaPeriodo,
        // sPeriodo: flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo,
        sMoneda: monedaInicial[0].sAbreviatura,
        // sMoneda: flujoCajaState.selectMonedaTableFCInicial[0].sAbreviatura,
        sIdEstructura: flujoCajaState.iIdEstructura,
        ListaEtiquetas: ListaEtiquetas,
        Sociedad: ListaSociedades,
        IDUsuario: authState.user.uid,
        IDUniverso: flujoCajaState.universo[0].value,
        // //Cambios mejora de tiempo
        IDTIPOSERVICIO: 4,
        // IDTIPOSERVICIO: 7,
        IDPOSICION_M: 0,
        //Cambios mejora de tiempo
      })
    );
    setCaracRegistros(true);

    if (!respuestaObtenerSplitRegistros.data) {
      if (respuestaObtenerSplitRegistros.request.status === 401) {
        localStorage.clear();
        dispatch(onLogout());
        return;
      }
    }
    // var data = SplitRegistrosDataDemo;

    var data = respuestaObtenerSplitRegistros.data;

    if (data) {
      if (data.cabecera.statusCode === 200) {
        setAListaRegistro2(data.respuesta.aListaRegistros); //Nuevo Benji
        dispatch(
          onSetListFiltroCaract({ listFiltroCaract: data.respuesta.aCaracteristicasFiltros })
        );
        setIdMatrizExplotadaPrimer(data.respuesta.ID_Matriz_Exp);
        //  Personalizacion de Filtros
        let listaFiltrosPersonalizados = [];

        let filtroPer1 = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'Filtro_1'
        );

        let filtroPer2 = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'Filtro_2'
        );

        let listaFiltroPosi = [];
        data.respuesta.aCaracteristicasFiltros.map((r) => {
          listaFiltroPosi.push(filtroReporteAdapter(r));
        });

        if (filtroPer1.length > 0) {
          let selectPosicionesFP = listaFiltroPosi.filter(
            (f) =>
              f.Items.filter((f1) => f1.sNombreCampo === filtroPer1[0].DatoPersonalizacion).length >
              0
          );

          let selectCaracteristia = [
            {
              label: filtroPer1[0].DatoPersonalizacion,
              value: filtroPer1[0].DatoPersonalizacion,
              NombreCampo: filtroPer1[0].DatoPersonalizacion,
            },
          ];

          let listaPosicionCaracValor = [];
          selectPosicionesFP
            .filter((o) => o.value !== '*')
            .forEach((element) => {
              element.Items.map((r) => {
                if (r.sNombreCampo === filtroPer1[0].DatoPersonalizacion) {
                  listaPosicionCaracValor.push(r);
                }
              });
            });
          if (selectPosicionesFP.length === listaFiltroPosi.length) {
            selectPosicionesFP = [initiallAllPosition, ...selectPosicionesFP];
          }

          const listaValor = [];
          listaPosicionCaracValor.forEach((element) => {
            element.Items.forEach((el) => {
              if (listaValor.filter((r) => r.value === el.sValor).length < 1) {
                listaValor.push({
                  label: el.sValor,
                  value: el.sValor,
                  aIdPosicion: [element.IDPosicion],
                });
              } else {
                listaValor.map((op) => {
                  if (op.value === el.sValor) {
                    op.aIdPosicion = [...op.aIdPosicion, element.IDPosicion];
                  }
                });
              }
            });
          });

          let paramFiltro = {
            selectPosicion: selectPosicionesFP,
            listaPosicion: listaFiltroPosi,
            selectCaracteristica: selectCaracteristia,
            listaCaracteristica: selectCaracteristia,
            selectValor: [initiallAllPosition, ...listaValor],
            listaValor: listaValor,
            personalizada: 1,
          };
          listaFiltrosPersonalizados.push(paramFiltro);
          setFiltroPersonalizado1(true);
          setFiltroPersonalizadoNombre1(filtroPer1[0].DatoPersonalizacion);
          setListaValorPersonalizada1(listaValor);
          setSelectValorPersonaliza1([initiallAllPosition, ...listaValor]);
        }

        if (filtroPer2.length > 0) {
          let selectPosicionesFP = listaFiltroPosi.filter(
            (f) =>
              f.Items.filter((f1) => f1.sNombreCampo === filtroPer2[0].DatoPersonalizacion).length >
              0
          );

          let selectCaracteristia = [
            {
              label: filtroPer2[0].DatoPersonalizacion,
              value: filtroPer2[0].DatoPersonalizacion,
              NombreCampo: filtroPer2[0].DatoPersonalizacion,
            },
          ];

          let listaPosicionCaracValor = [];
          selectPosicionesFP
            .filter((o) => o.value !== '*')
            .forEach((element) => {
              element.Items.map((r) => {
                if (r.sNombreCampo === filtroPer2[0].DatoPersonalizacion) {
                  listaPosicionCaracValor.push(r);
                }
              });
            });
          if (selectPosicionesFP.length === listaFiltroPosi.length) {
            selectPosicionesFP = [initiallAllPosition, ...selectPosicionesFP];
          }

          const listaValor = [];
          listaPosicionCaracValor.forEach((element) => {
            element.Items.forEach((el) => {
              if (listaValor.filter((r) => r.value === el.sValor).length < 1) {
                listaValor.push({
                  label: el.sValor,
                  value: el.sValor,
                  aIdPosicion: [element.IDPosicion],
                });
              } else {
                listaValor.map((op) => {
                  if (op.value === el.sValor) {
                    op.aIdPosicion = [...op.aIdPosicion, element.IDPosicion];
                  }
                });
              }
            });
          });

          let paramFiltro = {
            selectPosicion: selectPosicionesFP,
            listaPosicion: listaFiltroPosi,
            selectCaracteristica: selectCaracteristia,
            listaCaracteristica: selectCaracteristia,
            selectValor: [initiallAllPosition, ...listaValor],
            listaValor: listaValor,
            personalizada: 2,
          };
          listaFiltrosPersonalizados.push(paramFiltro);
          setFiltroPersonalizado2(true);
          setFiltroPersonalizadoNombre2(filtroPer2[0].DatoPersonalizacion);
          setListaValorPersonalizada2(listaValor);
          setSelectValorPersonaliza2([initiallAllPosition, ...listaValor]);
        }

        if (listaFiltrosPersonalizados.length > 0) {
          dispatch(
            onSetFiltroDetalleFlujoCaja({ aFiltrosDetalleFlujoCaja: listaFiltrosPersonalizados })
          );
        }

        //  Personalizacion de Filtros

        var {
          aListaCabecera,
          aListaSplitAgrupada,
          // aListaParcialAgrupada,
          // aListaTotalAgrupada,
          // aListaCaracAgrupada,
          aListaRegistros,
          aListaPosiciones,
        } = data.respuesta;

        dispatch(onSetDetalleFlujoCajaCabeceras(aListaCabecera));
        dispatch(
          onSetDetalleFlujoCajaCabecerasInicial({
            detalleFlujoCajaCabecerasInicial: aListaCabecera,
          })
        );

        var aListaSplitAgrupadaSaldo = aListaSplitAgrupada.filter((element) => {
          return element.Id.includes('S');
        });
        var aListaSplitAgrupadaIngreso = aListaSplitAgrupada.filter((element) => {
          return element.Id.includes('I');
        });
        var aListaSplitAgrupadaEgreso = aListaSplitAgrupada.filter((element) => {
          return element.Id.includes('E');
        });
        var finalArraySinFiltro = [
          ...aListaSplitAgrupadaSaldo,
          ...aListaSplitAgrupadaIngreso,
          ...aListaSplitAgrupadaEgreso,
        ];

        console.log(finalArraySinFiltro);
        setFinalArray(finalArraySinFiltro);
        setFinalArraySinFiltro(finalArraySinFiltro);
        setListaPosic(aListaPosiciones);
        setListaReg(aListaRegistros);
        // setFinalArrayTotalParciales(aListaParcialAgrupada[0]);
        // let sumaTot = 0;
        // let listaNuevaTot = [];
        // let paramTot = {};
        // aListaParcialAgrupada[0].Items.map((r) => {
        //   sumaTot = sumaTot + r.Valor;
        //   listaNuevaTot.push({ Valor: sumaTot });
        // });
        // paramTot.sNombre = 'Total Acumulado';
        // paramTot.Items = listaNuevaTot;
        // // setFinalArrayTotal(aListaTotalAgrupada[0]);
        // setFinalArrayTotal(paramTot);

        dispatch(onSetDetalleFlujoCajaCabeceras(aListaCabecera));

        setSelectedDesdobles(
          [...data.respuesta.aDesdobleFinal].map(function (item, index) {
            item.expanded = false;
            return item;
          })
        );
        // await loadSoluciones();
        await loadEstado();
      } else if (data.cabecera.statusCode === 400) {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else if (respuestaObtenerSplitRegistros.response.data.cabecera.statusCode === 404) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: respuestaObtenerSplitRegistros.response.data.cabecera.descripcion,
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const loadSoluciones = async () => {
    const responseListaLineaCredito = await callEndpoint(
      obtenerSoluciones({
        oTipoSolucion: ['LC', 'PR', 'FC', 'AP', 'IN', 'TC'],
      })
    );
    var listaModalSolFlujoCaja = [];
    if (responseListaLineaCredito != null) {
      if (responseListaLineaCredito.data) {
        if (responseListaLineaCredito.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaLineaCredito.data.respuesta.aListaSolucion.length;
            index++
          ) {
            const element = responseListaLineaCredito.data.respuesta.aListaSolucion[index];
            var obj = createSolucionModalFlujoCajaAdapter(element);
            listaModalSolFlujoCaja.push(obj);
          }
        }
      }
    }

    dispatch(onSetListaModalSolFlujoCaja({ listaModalSolFlujoCaja: listaModalSolFlujoCaja }));
    dispatch(
      onSetFilterListaModalSolFlujoCaja({
        filterListaModalSolFlujoCaja: listaModalSolFlujoCaja,
        listaModalSolFlujoCajaIni: listaModalSolFlujoCaja,
      })
    );
  };

  const loadEstado = async () => {
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    dispatch(onSetListaTipoEstadoSolFlujoCaja({ listaTipoEstadoSolFlujoCaja: aEstadoSol }));
  };

  const validarCampos = () => {
    var bValidForm = true;
    if (
      flujoCajaState.dDateRange[0] === null ||
      flujoCajaState.dDateRange[1] === null ||
      flujoCajaState.iIdEstructura === null
      // ||
      // flujoCajaState.iIdFechaPeriodo === null ||
      // flujoCajaState.iIdMoneda === null
    ) {
      bValidForm = false;
    }
    return bValidForm;
  };

  const onBuscarFlujoCaja = async () => {
    setCaracRegistros(false);
    setDatosRegistros(false);

    var bValidForm = validarCampos();
    if (bValidForm) {
      dispatch(onLimpiarFiltroDetalleFlujoCaja());
      let lista = [];
      var responseObtenerInfoFiltrosFlujoCajaCabecera = await callEndpoint(
        obtenerInfoFiltrosFlujoCajaCabecera({
          oIDEstructura: {
            IDEst: flujoCajaState.iIdEstructura,
          },
        })
      );
      if (responseObtenerInfoFiltrosFlujoCajaCabecera) {
        if (responseObtenerInfoFiltrosFlujoCajaCabecera.data) {
          responseObtenerInfoFiltrosFlujoCajaCabecera.data.respuesta.aListaAgrupadaCaracPos.forEach(
            (element) => {
              lista.push(createObtenerInfoFiltrosFlujoCajaCabeceraAdapter(element));
            }
          );
        }
      }
      dispatch(
        onSetListaPosicionesFiltrosFlujoCaja({
          listaPosicionesFiltros: lista,
        })
      );
      const aRegistroModificados = finalArray.filter((element) => {
        return element.sAccion === 'M'; //&& element.IdentificadorCPR === "R";
      });
      if (aRegistroModificados.length > 0) {
        Swal.fire({
          title: '¿Usted ha realizado movimiento de registros desea perder los cambios?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.colors.primary,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await realizarBusqueda();
            showParametros();
          }
        });
      } else {
        await realizarBusqueda();
        showParametros();
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Por favor, completar la versión, estructura y fecha del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleChangeTiempoProyeccion = (oEvent) => {
    var oParam = {
      iIdtiempoProyeccion: null,
      sTiempoProyeccion: '',
    };
    if (oEvent) {
      oParam.iIdtiempoProyeccion = oEvent.value;
      oParam.sTiempoProyeccion = oEvent.label;
      if (parseInt(flujoCajaState.iProyeccion, 10) > 0) {
        var diasAumentar = 0;
        switch (oEvent.value) {
          case '1':
            diasAumentar = parseInt(flujoCajaState.iProyeccion, 10) * 7;
            break;
          case '2':
            diasAumentar = parseInt(flujoCajaState.iProyeccion, 10) * 30;
            break;
          case '3':
            diasAumentar = parseInt(flujoCajaState.iProyeccion, 10);
            break;
          default:
            break;
        }
        if (flujoCajaState.dDateRange[0]) {
          const dFechaFinal = new Date(dDateRange[0]);
          let dFechaIncrementada = addDays(diasAumentar, dFechaFinal);
          var oParamFechaCrearFlujoCaja = {
            dFechaInicio: flujoCajaState.dDateRange[0],
            dFechaFin: dFechaIncrementada,
            sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'),
            sFechaFinal: dateTo_dd_mm_yyyy(dFechaIncrementada, '/'),
            validate: true,
          };
          dispatch(onChangeFechaCrearFlujoCaja(oParamFechaCrearFlujoCaja));
        }
      }
    }
    dispatch(onChangeTiempoPeridiocidad(oParam));
  };

  const handleChangeMultiKey = (event) => {
    dispatch(
      onSelectEtiquetaFlujoCaja({
        selectEtiquetaFlujoCaja: event,
      })
    );
    // const rowsInput = [...rowsData];
    // rowsInput[index]["aSeleccionKey"] = event;
    // setRowsData(rowsInput);
  };

  const handleUniverso = (event) => {
    dispatch(
      onUniverso({
        universo: [event],
      })
    );
    // const rowsInput = [...rowsData];
    // rowsInput[index]["aSeleccionKey"] = event;
    // setRowsData(rowsInput);
  };

  const handleSociedad = (event) => {
    dispatch(
      onSetSociedad({
        selectSociedad: event,
      })
    );
    // const rowsInput = [...rowsData];
    // rowsInput[index]["aSeleccionKey"] = event;
    // setRowsData(rowsInput);
  };
  const changeProyeccionNum = (dato) => {
    var oParam = {
      iProyeccion: 0,
    };
    oParam.iProyeccion = parseInt(dato);
    dispatch(onChangeProyeccion(oParam));
    if (flujoCajaState.dDateRange[0] && flujoCajaState.iIdtiempoProyeccion) {
      if (parseInt(oParam.iProyeccion, 10) > 0) {
        oParam.iIdtiempoProyeccion = flujoCajaState.iIdtiempoProyeccion;
        oParam.sTiempoProyeccion = flujoCajaState.sTiempoProyeccion;
        var diasAumentar = 0;
        switch (flujoCajaState.iIdtiempoProyeccion) {
          case '1':
            diasAumentar = parseInt(oParam.iProyeccion, 10) * 7;
            break;
          case '2':
            diasAumentar = parseInt(oParam.iProyeccion, 10) * 30;
            break;
          case '3':
            diasAumentar = parseInt(oParam.iProyeccion, 10);
            break;
          default:
            break;
        }
        if (flujoCajaState.dDateRange[0]) {
          const dFechaFinal = new Date(dDateRange[0]);
          let dFechaIncrementada = addDays(diasAumentar, dFechaFinal);
          var oParamFechaCrearFlujoCaja = {
            dFechaInicio: flujoCajaState.dDateRange[0],
            dFechaFin: dFechaIncrementada,
            sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'),
            sFechaFinal: dateTo_dd_mm_yyyy(dFechaIncrementada, '/'),
            validate: true,
          };
          dispatch(onChangeFechaCrearFlujoCaja(oParamFechaCrearFlujoCaja));
        }
      }
    }
  };

  const handleChangeProyeccion = (oEvent) => {
    var oParam = {
      iProyeccion: 0,
    };
    if (oEvent) {
      if (oEvent.target.value.length > 0) {
        changeProyeccionNum(oEvent.target.value);
      } else {
        dispatch(onChangeProyeccion(oParam));
      }
    }
  };

  const handleChangeVersion = (oEvent) => {
    var oParam = {
      sVersion: '',
      bValidVersionFlujoCaja: null,
      selectVersionFlujoCaja: [],
    };
    if (oEvent) {
      oParam.sVersion = oEvent.target.value;
    }
    dispatch(onChangeVersion(oParam));
    // onSelectVersionFlujoCaja()
  };

  const onEjecutarOptimizador = async (oEvent, finalArrayEliminar, finalArrayTotal) => {
    setShowGrafico(false);
    var bValidForm = validarCampos();
    let emptySolutions = flujoCajaState.selectSolucionesModalFlujoCaja.length === 0 ? true : false;
    dispatch(onSetOptimizador({ idOptimiacion: null }));
    dispatch(onSetTabProceso({ tabProceso: 0 }));
    dispatch(
      onLimpiarCombinacionSolucuionesFlujoCaja({
        selectListaSoluciones: [],
        selectListaAgrupadaXSoluciones: [],
      })
    );
    if (!bValidForm) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'Por favor, completar la versión, estructura , fecha , periodicidad y moneda del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    if (emptySolutions) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se han seleccionado soluciones.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    var ListaRegistrosFlujoCaja = finalArray.filter((element) => {
      return element.IdentificadorCPR === 'R';
    });
    var sFechaInicio = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[0], '-');
    var sFechaFinal = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[1], '-');
    var ListaSoluciones_EX = [];
    var Priorizacion_Moneda = [];
    var oCabecera = {
      sFechaInicio: sFechaInicio,
      sFechaFin: sFechaFinal,
      sPeriodo: flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo, //"M",
      sMoneda: flujoCajaState.selectMonedaTableFC[0].sAbreviatura, //"USD",
      sIdEstructura: flujoCajaState.iIdEstructura, //1
      aMontoMinimo: [],
      aReglaAplazamiento: [], // obligatorio|| no obligatorio  -  moneda
      oReglaAplazamiento: '', // 'obligatorio|| no obligatorio'
      aNoAplazar: [],
    };
    /*
    {
        "NombreSubAgrupador": // NOMBRE DE LA POSICION. Por ejemplo : BBVA PEN
- [ ]         *"FechaFormateada": "27.02.2023"
- [ ]         *"Moneda": "PEN"
- [ ]         *"Importe": "50000.0"
        "NombreCliente": [TipoConcepto]-[NombreSubAgrupador]-[IdRegistro === R1 (único)SOLO PARA NUEVOS , para los modificaos si el que tiene]
        "Real_Proyectado": sReal_Proyectado
        "Riesgo": 0
        "AgenteAsociado": “”
        "Saldo": 0,
        "TipoConcepto":  // DEL COMBO DE CONCEPTO tenemos que sacar que es. Por ejemplo : Ingreso, Egreso o Saldo.
- [ ]         *"IdRegistro”:[ IdRegistro === 0 SOLO PARA NUEVOS , para los modificaos si el que tiene] "10"
    },
    */
    let aIdPartida_3 = [];
    let listRegisterCashFlow = [];
    let listConcept = [];
    let listConceptUnique = [];
    for (let index = 0; index < ListaRegistrosFlujoCaja.length; index++) {
      const { Id_Partida3 } = ListaRegistrosFlujoCaja[index];
      const element = ListaRegistrosFlujoCaja[index];
      var obj = {};
      aIdPartida_3.push(Id_Partida3);

      var aEncontrado = element.Items.filter(function (item, index) {
        return item.EsSplit === 0;
      });
      if (aEncontrado.length > 0) {
        obj['FechaFormateada'] = formatString_YYYYMMDD_TO_DDMMYYYY(element.sFecha, '-', '/');
        obj['Moneda'] = flujoCajaState.selectMonedaTableFC[0].sAbreviatura;
        obj['Importe'] = aEncontrado[0].Valor;
        obj['IdConcepto'] = element.Id_Partida1;
        obj['IdPosicion'] = element.Id_Partida2;
        obj['IdRegistro'] = element.Id_Partida3;
        obj['NombreSubAgrupador'] = element.sNombre_Partida2;
        obj['Riesgo'] = 0;
        obj['AgenteAsociado'] = '';
        obj['Saldo'] = 0;
        obj['Real_Proyectado'] = '';
        obj['TipoConcepto'] = '';
        listConcept.push(element.Id_Partida1);
        // listRegisterCashFlow.push(obj);
      }
    }
    flujoCajaState.listaSucesosModificados.map((r) =>
      listRegisterCashFlow.push(cambiosModificadosAdapter(r, 0))
    );
    var hash = {};
    listConceptUnique = listConcept.filter(function (current) {
      var exists = !hash[current];
      hash[current] = true;
      return exists;
    });
    const responseListaConceptos = await callEndpoint(
      listarConceptosXFiltro({ aIdConcepto: listConceptUnique })
    );
    var listaConceptos = [];
    for (
      let index = 0;
      index < responseListaConceptos.data.respuesta.aListaConceptos.length;
      index++
    ) {
      const element = responseListaConceptos.data.respuesta.aListaConceptos[index];
      var obj = createConceptoAdapter(element);
      listaConceptos.push(obj);
    }
    listaConceptos.forEach((oConcepto) => {
      var aEncontrado = listRegisterCashFlow.filter((element2) => {
        return element2.IdConcepto === oConcepto.iIDAgrupador;
      });
      if (aEncontrado.length > 0) {
        aEncontrado.forEach((element) => {
          element['Real_Proyectado'] = oConcepto.sReal_Proyectado;
          element['TipoConcepto'] = oConcepto.sTipoConcepto;
          element['NombreCliente'] =
            oConcepto.sTipoConcepto + '-' + element.NombreSubAgrupador + '-' + element.IdRegistro;
        });
      }
    });
    const listaReglaFilter = flujoCajaState.aListaRegla.filter((item) => item.moneda != null);

    listaReglaFilter.forEach((element) => {
      var obj = {};
      obj.iIDTipoMoneda = element.moneda[0].iIDTipoMoneda;
      obj.sAbreviatura = element.moneda[0].sAbreviatura;
      obj.sNombreMoneda = element.moneda[0].sNombreMoneda;
      obj.sTipoRegla = element.tipo;
      if (element.tipo === 'Monto mínimo') {
        obj.valor = parseFloat(element.valor);
        oCabecera.aMontoMinimo.push(obj);
      } else if (element.moneda != null && element.tipo === 'Aplazar') {
        if (element.valor === 'Obligatorio') {
          obj.valor = 1;
        } else obj.valor = 0;
        oCabecera.aReglaAplazamiento.push(obj);
      }
    });

    const listaReglaFilterNM = flujoCajaState.aListaRegla.filter((item) => item.moneda === null);
    const listaReglaFilterNS = listaReglaFilterNM.filter(
      (item) => item.regla === 'No Solucionar con'
    );

    flujoCajaState.aListaSolucionesRegla.forEach((element) => {
      var finder = listaReglaFilterNS.filter((item) => item.valor === element.value);
      if (finder.length > 0) {
        ListaSoluciones_EX.push(1);
      } else ListaSoluciones_EX.push(0);
    });

    const listaReglaFilterPM = listaReglaFilterNM.filter(
      (item) => item.regla === 'Priorizacion Moneda'
    );

    if (listaReglaFilterPM.length > 0) {
      Priorizacion_Moneda = listaReglaFilterPM[0].valores;
    }

    listaReglaFilterNM.forEach((element) => {
      if (element.tipo === 'Aplazar') {
        if (element.valor === 'Obligatorio') {
          oCabecera.oReglaAplazamiento = 1;
        } else oCabecera.oReglaAplazamiento = 0;
      } else if (element.tipo === 'No aplazar a') {
        var obje = {};
        obje.nombre = element.valor;
        obje.valor = element.valores;
        oCabecera.aNoAplazar.push(obje);
      }
    });

    if (oCabecera.aMontoMinimo.length === 0) {
      for (let index = 0; index < flujoCajaState.aMonedasCrearFlujoCaja.length; index++) {
        const elementMoneda = flujoCajaState.aMonedasCrearFlujoCaja[index];
        var obj = {};
        obj.iIDTipoMoneda = elementMoneda.iIDTipoMoneda;
        obj.sAbreviatura = elementMoneda.sAbreviatura;
        obj.sNombreMoneda = elementMoneda.sNombreMoneda;
        obj.valor = 0;
        obj.sTipoRegla = 'Monto mínimo';
        oCabecera.aMontoMinimo.push(obj);
      }
    } else {
      for (let index = 0; index < flujoCajaState.aMonedasCrearFlujoCaja.length; index++) {
        const elementMoneda = flujoCajaState.aMonedasCrearFlujoCaja[index];
        var aEncontrado = listaReglaFilter.filter((element) => {
          return (
            element.moneda.iIDTipoMoneda === elementMoneda.iIDTipoMoneda &&
            element.tipo === 'Monto mínimo'
          );
        });
        if (aEncontrado.length === 0) {
          var obj = {};
          obj.iIDTipoMoneda = elementMoneda.iIDTipoMoneda;
          obj.sAbreviatura = elementMoneda.sAbreviatura;
          obj.sNombreMoneda = elementMoneda.sNombreMoneda;
          obj.valor = 0;
          obj.sTipoRegla = 'Monto mínimo';
          oCabecera.aMontoMinimo.push(obj);
        }
      }
    }
    // let ListaSoluciones = [];
    // flujoCajaState.selectSolucionesModalFlujoCaja.forEach((element) => {
    //   const { idSolucionBase } = element;
    //   ListaSoluciones.push(idSolucionBase);
    // });
    // return;
    var responseEjecutarOptimizadorScriptNube = await callEndpoint(
      ejecutarOptimizadorScriptNube({
        // ListaRegistrosFlujoCaja: aIdPartida_3, //ListaRegistrosFlujoCaja, // AListaRegistro2
        ListaRegistrosFlujoCaja: aListaRegistro2, //ListaRegistrosFlujoCaja, // AListaRegistro2
        oCabecera: oCabecera,
        ListaSoluciones: flujoCajaState.selectSolucionesModalFlujoCaja,
        // listRegisterCashFlow: [],
        listRegisterCashFlow: listRegisterCashFlow, //CAMBIO DE ULTIMO MOMENTO DEMO MRC BENJI
        // totalAcumFCIni: finalArrayTotal.Items,
        totalAcumFCIni: [],
        ListaSoluciones_EX: ListaSoluciones_EX,
        Priorizacion_Moneda: Priorizacion_Moneda,
        IDUsuario: authState.user.uid,
        IDUniverso: flujoCajaState.universo[0].value,
      })
    );

    if (responseEjecutarOptimizadorScriptNube === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseEjecutarOptimizadorScriptNube.data) {
        if (responseEjecutarOptimizadorScriptNube.status === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseEjecutarOptimizadorScriptNube.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
          var {
            aListaSoluciones,
            aListaAgrupadaXSoluciones,
            IDOptimizacion,
            aListaMontoMinimo,
            aListaLog,
          } = responseEjecutarOptimizadorScriptNube.data.respuesta;
          setIdOptimizador(responseEjecutarOptimizadorScriptNube.data.respuesta.ID_Matriz_Sol);
          dispatch(onSetListaSoluciones({ aListaSoluciones: aListaSoluciones }));
          dispatch(onSetListaSoluciones({ aListaAgrupadaXSoluciones: aListaAgrupadaXSoluciones }));
          dispatch(onSetOptimizador({ idOptimiacion: IDOptimizacion }));
          dispatch(onSetLog({ logSolucion: aListaLog }));
          dispatch(onSetTabProceso({ tabProceso: 1 }));
          dispatch(changeSelectListaSoluciones({ selectListaSoluciones: [aListaSoluciones[0]] }));

          dispatch(
            onSelectPeridiocidadTableSolucionFC({
              selectPeridiocidadTableSolucionFC: [
                {
                  ...flujoCajaState.selectPeridiocidadTableFC[0],
                  value: flujoCajaState.selectPeridiocidadTableFC[0].iIDTipoPeriodo,
                  label: flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo,
                },
              ],
              selectMonedaTableSolucionFC: [
                {
                  ...flujoCajaState.selectMonedaTableFC[0],
                  value: flujoCajaState.selectMonedaTableFC[0].iIDTipoMoneda, //flujoCajaState.iIdMoneda,
                  label: flujoCajaState.selectMonedaTableFC[0].label,
                  factorMultiplicador: flujoCajaState.factorMonedaTableTC[0].valor,
                  //flujoCajaState.sMoneda,
                },
              ],
            })
          );
          setSelectedDesdoblesInicialTableSolucionFC(selectedDesdobles);

          // const labels = flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras.map(function (
          //   item,
          //   index
          // ) {
          //   return item.sCabecera;
          // });

          // var responseObtenerPaletaColoresXSolucion = await callEndpoint(
          //   obtenerPaletaColoresXSolucion({})
          // );

          // let datasets = [];

          // const { borderColor, backgroundColor } = randomRGBA();
          // let obj = {
          //   label: 'Flujo Caja Inicial',
          //   data: finalArrayTotal.Items.map(function (item, index) {
          //     return item.Valor;
          //   }),
          //   borderColor: borderColor,
          //   backgroundColor: backgroundColor,
          // };

          // datasets.push(obj);

          // aListaAgrupadaXSoluciones.forEach((element, index) => {
          //   const { borderColor, backgroundColor } = randomRGBA();

          //   let objSol = {
          //     label: 'Solución ' + (index + 1),
          //     data: element.totalAcumFCIni.map(function (item) {
          //       return item.Valor;
          //     }),
          //     borderColor: borderColor,
          //     backgroundColor: backgroundColor,
          //   };

          //   datasets.push(objSol);
          // });

          // const { borderColor: borderColorMontoMinimo, backgroundColor: backgroundColorMontoMini } =
          //   randomRGBA();

          // let objMontoMinimo = {
          //   label: 'Monto Mínimo PEN',
          //   data: aListaMontoMinimo.map(function (item, index) {
          //     return item.Valor;
          //   }),
          //   borderColor: borderColorMontoMinimo,
          //   backgroundColor: backgroundColorMontoMini,
          // };

          // datasets.push(objMontoMinimo);

          // const data = {
          //   labelsChartFC: labels,
          //   datasetsChartFC: datasets,
          // };

          // dispatch(onGenerateDataLineChartFC(data));
        } else if (responseEjecutarOptimizadorScriptNube.status === 400) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: responseEjecutarOptimizadorScriptNube.data.cabecera.descripciom,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error en el servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Ocurrió un error en el servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onRegistrarFlujoCaja = async (formAregarCampos) => {
    var ListaEtiquetas = '';
    var ListaRegistrosFlujoCaja = finalArray.filter((element) => {
      return element.IdentificadorCPR === 'R';
    });
    flujoCajaState.selectEtiquetaFlujoCaja.forEach((element, index) => {
      if (index === 0) {
        ListaEtiquetas = element.IDEtiqueta.toString();
      } else {
        ListaEtiquetas = ListaEtiquetas + ',' + element.IDEtiqueta.toString();
      }
    });
    let ListaSoluciones = [];
    flujoCajaState.selectSolucionesModalFlujoCaja.forEach((element) => {
      // ListaSoluciones.push(element.idSolucionBase);
      ListaSoluciones.push(element);
    });
    let aIdPartida_3 = [];
    for (let index = 0; index < ListaRegistrosFlujoCaja.length; index++) {
      const { Id_Partida3 } = ListaRegistrosFlujoCaja[index];
      aIdPartida_3.push(Id_Partida3);
    }
    let aMontoMinimo = [];
    flujoCajaState.aRestriccionesDetalleFlujoCaja.forEach((element) => {
      if (element.selectTipoRegla.value === 'Monto mínimo') {
        var obj = {};
        obj.iIDTipoMoneda = element.selectMoneda[0].iIDTipoMoneda;
        obj.sAbreviatura = element.selectMoneda[0].sAbreviatura;
        obj.sNombreMoneda = element.selectMoneda[0].sNombreMoneda;
        obj.valor = parseFloat(element.valor);
        obj.sTipoRegla = 'Monto mínimo';
        aMontoMinimo.push(obj);
      }
    });
    if (aMontoMinimo.length === 0) {
      for (let index = 0; index < flujoCajaState.aMonedasCrearFlujoCaja.length; index++) {
        const elementMoneda = flujoCajaState.aMonedasCrearFlujoCaja[index];
        var obj = {};
        obj.iIDTipoMoneda = elementMoneda.iIDTipoMoneda;
        obj.sAbreviatura = elementMoneda.sAbreviatura;
        obj.sNombreMoneda = elementMoneda.sNombreMoneda;
        obj.valor = 0;
        obj.sTipoRegla = 'Monto mínimo';
        aMontoMinimo.push(obj);
      }
    } else {
      for (let index = 0; index < flujoCajaState.aMonedasCrearFlujoCaja.length; index++) {
        const elementMoneda = flujoCajaState.aMonedasCrearFlujoCaja[index];
        var aEncontrado = flujoCajaState.aRestriccionesDetalleFlujoCaja.filter((element) => {
          return element.selectMoneda.iIDTipoMoneda === elementMoneda.iIDTipoMoneda;
        });
        if (aEncontrado.length === 0) {
          var obj = {};
          obj.iIDTipoMoneda = elementMoneda.iIDTipoMoneda;
          obj.sAbreviatura = elementMoneda.sAbreviatura;
          obj.sNombreMoneda = elementMoneda.sNombreMoneda;
          obj.valor = 0;
          obj.sTipoRegla = 'Monto mínimo';
          aMontoMinimo.push(obj);
        }
      }
    }
    var oParam = {
      RegistroFlujoCaja: {
        sNombreFlujo: formAregarCampos.nombre,
        sDescripcionFlujo: formAregarCampos.descripcion,
      },
      sFechaInicio: dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[0], '-'),
      sFechaFin: dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[1], '-'),
      iIdPeridiocidad: flujoCajaState.selectPeridiocidadTableFC[0].iIDTipoPeriodo, //"M",
      sPeriodo: flujoCajaState.sCodFechaPeriodo, //"M",
      iIdMoneda: flujoCajaState.selectMonedaTableFCInicial[0].iIDTipoMoneda, //"PEN",
      sMoneda: flujoCajaState.sAbreviatura, //"PEN",
      sIdEstructura: flujoCajaState.iIdEstructura,
      sEstructura: flujoCajaState.sEstructura,
      sTiempoProyeccion: flujoCajaState.sTiempoProyeccion,
      sNombreVersion: flujoCajaState.sVersion,
      ListaEtiquetas: ListaEtiquetas, //"",
      // "sIdFlujoCajaGeneral": 6,
      ListaRegistrosFlujoCaja: aIdPartida_3,
      ListaSoluciones: ListaSoluciones,
      IDOptimizacion: flujoCajaState.idOptimiacion, //1,
      IDVersion: flujoCajaState.selectVersionFlujoCaja[0].value,
      aFiltrosDetalleFlujoCaja: flujoCajaState.aFiltrosDetalleFlujoCaja,
      aMontoMinimo: aMontoMinimo,
      aRestriccionesDetalleFlujoCaja: flujoCajaState.aRestriccionesDetalleFlujoCaja,
      listarEtiquetasFlujoCaja: flujoCajaState.listarEtiquetasFlujoCaja,
      selectEtiquetaFlujoCaja: flujoCajaState.selectEtiquetaFlujoCaja,
      selectSolucionesModalFlujoCaja: flujoCajaState.selectSolucionesModalFlujoCaja,

      selectVersionFiltroDetalleFlujoCaja: flujoCajaState.selectVersionFiltroDetalleFlujoCaja,
      selectVersionRestriccionesDetalleFlujoCaja:
        flujoCajaState.selectVersionRestriccionesDetalleFlujoCaja,
      selectSolucionesDetalleFlujoCaja: flujoCajaState.selectSolucionesDetalleFlujoCaja,
      ID_Matriz_Exp4: idMatrizExplotadaPrimer,
      ID_Matriz_Exp7: idMatrizExplotada,
      ID_Matriz_Sol: idOptimizador,
    };
    // navigate("/flujo_caja/reporte/listar");
    // return;
    var responseSaveHeaderAndDetailsFlowsCaja = await callEndpoint(
      saveHeaderAndDetailsFlowsCaja(oParam)
    );
    if (responseSaveHeaderAndDetailsFlowsCaja === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseSaveHeaderAndDetailsFlowsCaja.data) {
        if (responseSaveHeaderAndDetailsFlowsCaja.data.cabecera.statusCode === 200) {
          dispatch(onOpenModalAgregarNombreFlujoCaja({ modalOpenAgregarNombreFlujoCaja: false }));
          navigate(paths.CASH_FLOW.REPORT.BASE);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: responseSaveHeaderAndDetailsFlowsCaja.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (responseSaveHeaderAndDetailsFlowsCaja.data.cabecera.statusCode === 400) {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseSaveHeaderAndDetailsFlowsCaja.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error de servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error de servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const onGuardarFlujoCaja = async (oEvent, finalArrayEliminar) => {
    var bValidForm = validarCampos();
    var ListaRegistrosFlujoCaja = finalArray.filter((element) => {
      return element.IdentificadorCPR === 'R';
    });
    if (ListaRegistrosFlujoCaja.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'El flujo de caja no contiene ningun registro propio ni de sistema.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    if (bValidForm) {
      Swal.fire({
        title: '¿Desea guardar el Flujo de caja?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(onOpenModalAgregarNombreFlujoCaja({ modalOpenAgregarNombreFlujoCaja: true }));
        }
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'Por favor, completar la versión, estructura , fecha , periodicidad y moneda del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const loadEtiquetasM = async () => {
    const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());
    var listarEtiquetasFlujoCaja = [];
    for (
      let index = 0;
      index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
      index++
    ) {
      const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
      var obj = createFlujoCajaEtiquetaAdapter(element);
      listarEtiquetasFlujoCaja.push(obj);
    }
    let objEtiqueta = {
      listarEtiquetasFlujoCaja: listarEtiquetasFlujoCaja,
    };
    dispatch(onSetListaEtiqueFlujoCaja(objEtiqueta));
  };

  const onRealizarDesdoblesAutomatico = (finalArraySinFiltro, resultCaceberas) => {
    // var aFinalArray = [...finalArraySinFiltro];
    const aFinalArray = finalArraySinFiltro.map((object) => ({ ...object }));
    //let rows = finalArraySinFiltro.map(object => ({ ...object }))//[...finalArraySinFiltro];
    // let rows = [...aFinalArray];
    ///
    //Proceso 1.
    var aIdExcluir = [];
    var aHijosFinal = [];
    let rowsFinal = [];
    var aAgrupadoXDesdobleXhijo = [];
    for (let index = 0; index < selectedDesdobles.length; index++) {
      const oPadreDesdoble = selectedDesdobles[index];
      var aAgrupadoDatos = [];
      var oItem = oPadreDesdoble.oPadre;
      // props.finalArray.forEach((element) => {
      var aHijos = obtenerHijos(aFinalArray, oItem.Id).map((object) => ({ ...object }));
      var helper = {};
      aHijos.forEach((element) => {
        var aEncontrado = element.datos_reg.filter((element2) => {
          return element2.sNombreCampo === oPadreDesdoble.oDesdoble.sNombreCampo;
        });
        if (aEncontrado.length > 0) {
          var key =
            // aEncontrado[0].iIdCaracteristica +
            // "_" +
            aEncontrado[0].sNombreCampo + '_' + aEncontrado[0].sDatoPosicion;
          if (!helper[key]) {
            //inicializa
            helper[key] = {
              // iIdCaracteristica: aEncontrado[0].iIdCaracteristica,
              sNombreCampo: aEncontrado[0].sNombreCampo,
              sDatoPosicion: aEncontrado[0].sDatoPosicion,
              datos: [element],
            };
            aAgrupadoDatos.push(helper[key]);
          } else {
            helper[key].datos.push(element);
          }
          aHijosFinal.push(element);
        }
      });
      aHijos = aHijosFinal;
      //
      aAgrupadoDatos.forEach((element) => {
        let datos = element.datos;
        datos.forEach((element2) => {
          aIdExcluir.push(element2.Id);
        });
      });
      ////
      // var indexFind = -1;
      // rows.find(function (item, i) {
      //   if (item.Id === oItem.Id) {
      //     indexFind = i;
      //     return i;
      //   }
      // });
      var obj = { ...oPadreDesdoble, ...{ aAgrupadoDatos: aAgrupadoDatos } };
      aAgrupadoXDesdobleXhijo.push(obj);
    }
    if (selectedDesdobles.length > 0) {
      var colectData = collect;
      const rows = [...colectData(finalArraySinFiltro).whereNotIn('Id', aIdExcluir).all()];
      rowsFinal = rows;
    } else {
      const rows = [...finalArraySinFiltro];
      rowsFinal = rows;
    }
    aAgrupadoXDesdobleXhijo = aAgrupadoXDesdobleXhijo.map((object) => ({ ...object }));
    for (let j = 0; j < aAgrupadoXDesdobleXhijo.length; j++) {
      var indexAcum = 0;
      var element = aAgrupadoXDesdobleXhijo[j];
      var indexFind = -1;
      rowsFinal.find(function (item, i) {
        if (item.Id === element.oPadre.Id) {
          indexFind = i;
          return i;
        }
      });
      var oItem = element.oPadre;
      var collapse = element.collapse;
      var collapseIcon = element.collapseIcon;
      var aAgrupado = element.aAgrupadoDatos;
      aAgrupado = aAgrupado.sort((a, b) => (a.sDatoPosicion > b.sDatoPosicion ? 1 : -1));
      for (let p = 0; p < aAgrupado.length; p++) {
        indexAcum = indexAcum + 1;
        var IdDesdoble = element.oPadre.Id + '-' + indexAcum;
        ////----
        var aDatos = [...aAgrupado[p].datos];
        //var IdDesdoble = oItem.Id + "-" + (p + 1);
        let aDatosFinal = [];
        for (let index = 0; index < aDatos.length; index++) {
          var element2 = { ...aDatos[index] };
          // var Id = element.Id.replace(oItem.Id, IdDesdoble);
          // element.Id = Id;
          var aId = element2.Id.split('-');
          element2.Id = IdDesdoble + '-' + aId[aId.length - 1];
          element2.Items = [];
          aDatosFinal.push(element2);
          for (let w = 0; w < aDatos[index].Items.length; w++) {
            // const element2 = element.Items[w];
            var obj = { ...aDatos[index].Items[w] };
            var Id = aDatosFinal[index].Id + '-' + (w + 1);
            obj.Id = Id;
            aDatosFinal[index].Items.push(obj);
          }
        }
        var Items = [];
        for (
          let f = 0;
          f < resultCaceberas.length; //oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length;
          f++
        ) {
          var oCabecera = {
            Id: IdDesdoble,
            Id2: IdDesdoble,
            IdPadre: 'S-2-8',
            IdPadre2: 'S-2-8',
            IdHijo: 'S-2-8',
            IdHijo2: 'S-2-8',
            EsRaiz: 0,
            EsGrupo: 1,
            EsManual: 0,
            Posicion: aAgrupado[p].sDatoPosicion,
            Valor: 0,
          };
          // var acumulador = 0;
          // for (let j = 0; j < aDatosFinal.length; j++) {
          //   const element = aDatosFinal[j].Items[f].Valor;
          //   acumulador = acumulador + element;
          // }
          // oCabecera.Valor = acumulador;
          Items.push(oCabecera);
        }
        ////TEMPORAL GUILLE
        // if (p === 0) {
        indexFind = indexFind + 1;
        rowsFinal.splice(indexFind, 0, {
          EsGrupo: 1,
          EsManual: 0,
          EsRaiz: 0,
          EsSplit: 0,
          Id: IdDesdoble,
          Id2: IdDesdoble,
          IdPadre: IdDesdoble,
          IdPadre2: IdDesdoble,
          //collapse:element.collapse,
          //collapseIcon:element.collapseIcon,
          collapse: true,
          collapseIcon: true,
          Id_Partida1: oItem.Id_Partida1,
          Id_Partida2: oItem.Id_Partida2,
          Id_Partida3: oItem.Id_Partida3,
          IdentificadorCPR: 'D',
          Items: Items,
          LlaveCPR: 'D-' + (p + 1),
          Posicion: aAgrupado[p].sDatoPosicion,
          bEditable: 'NULL',
          datos_carpeta: [],
          datos_posic: [],
          datos_reg: [],
          sNombre_Partida1: oItem.sNombre_Partida1,
          sNombre_Partida2: oItem.sNombre_Partida2,
        });
        for (let indexAdd = 0; indexAdd < aDatosFinal.length; indexAdd++) {
          const element = aDatosFinal[indexAdd];
          element.collapse = true;
          element.collapseIcon = true;
          indexFind = indexFind + 1;
          rowsFinal.splice(indexFind, 0, element);
        }
      }
    }
    // var rowsInput = [];
    // [...rowsFinal].forEach(element => {
    //     var obj = {...element};
    //       obj.Items = [];
    //       element.Items.forEach(element2 => {
    //         obj.Items.push({...element2})
    //       });
    //     rowsInput.push(obj)
    // });
    // var rowsInputSaldo = rowsInput.filter((element) => {
    //   return element.Id.includes("S");
    // });
    // var rowsInputIngreso = rowsInput.filter((element) => {
    //   return element.Id.includes("I");
    // });
    // var rowsInputEgreso = rowsInput.filter((element) => {
    //   return element.Id.includes("E");
    // });
    // realizarRecalculo(rowsInputSaldo, rowsInput,resultCaceberas);
    // realizarRecalculo(rowsInputIngreso, rowsInput,resultCaceberas);
    // realizarRecalculo(rowsInputEgreso, rowsInput,resultCaceberas);
    // var aListaSplitAgrupadaSaldo = rowsInput.filter((element) => {
    //   return element.Id.includes("S");
    // });
    // collapseChild("S",aListaSplitAgrupadaSaldo)
    // var aListaSplitAgrupadaIngreso =rowsInput.filter((element) => {
    //   return element.Id.includes("I");
    // });
    // collapseChild("I",aListaSplitAgrupadaIngreso)
    // var aListaSplitAgrupadaEgreso = rowsInput.filter((element) => {
    //   return element.Id.includes("E");
    // });
    // collapseChild("E",aListaSplitAgrupadaEgreso)
    // let aListaSplitAgrupada= [...aListaSplitAgrupadaSaldo, ...aListaSplitAgrupadaIngreso , ...aListaSplitAgrupadaEgreso]
    // setFinalArray(aListaSplitAgrupada);
    return rowsFinal;
  };

  const calcularTotalYsubTotal = (aListaSplitAgrupada, resultCaceberas) => {
    const rowsInput = aListaSplitAgrupada; //finalArray;
    var objecto = {};
    objecto.EsRaiz = 1;
    objecto.Id = 'Total Acumulado';
    objecto.sNombre = 'Total Acumulado';
    objecto.Items = [];
    var objectoParcial = {};
    objectoParcial.EsRaiz = 1;
    objectoParcial.Id = 'Total Periodo';
    objectoParcial.sNombre = 'Total Periodo';
    objectoParcial.Items = [];
    var aEncontradoGruposRaiz = rowsInput.filter((element) => {
      return element.EsGrupo === 1 && element.EsRaiz === 1;
    });
    for (
      let index = 0;
      index < resultCaceberas.length;
      //detalleFlujoCajaCabeceras.length;
      index++
    ) {
      var fAcumulado = 0;
      var item = {
        Id: objecto.Id + '-' + index,
        Id_Movimiento: null,
        EsRaiz: 1,
        EsGrupo: 1,
        Valor: 0,
      };
      for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
        const element = aEncontradoGruposRaiz[w];
        if (element.Posicion === 'Egreso') {
          fAcumulado = fAcumulado + element.Items[index].Valor;
        } else {
          fAcumulado = fAcumulado + element.Items[index].Valor;
        }
      }
      item.Valor = fAcumulado;
      objecto.Items.push(item);
      objectoParcial.Items.push({ ...item });

      // return {
      //   objecto,
      //   objectoParcial,
      // };
    }
    setFinalArrayTotalParciales(objectoParcial);
    // props.setFinalArray(rowsInput);
    for (let index = 0; index < objecto.Items.length; index++) {
      const element = objecto.Items[index - 1];
      if (!element) {
        objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
      } else {
        objecto.Items[index].Valor = objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
      }
    }
    setFinalArrayTotal(objecto);
  };

  const obtenerHijos = (rowsInput, nuevoId) => {
    var aEncontrado = rowsInput.filter((element) => {
      return element.Id.includes(nuevoId) && element.Id !== nuevoId;
    });
    return aEncontrado;
  };

  const realizarRecalculo = (rowsInputSaldo, rowsInput, resultCaceberas) => {
    rowsInputSaldo.forEach((element) => {
      element.iFila = element.Id.split('-').length + 1;
    });
    var aFilterAcumuladores = rowsInputSaldo.filter((element) => {
      return (
        element.IdentificadorCPR === 'C' ||
        element.IdentificadorCPR === 'P' ||
        element.IdentificadorCPR === 'D'
      );
    });
    if (aFilterAcumuladores.length > 0) {
      aFilterAcumuladores.sort((a, b) => (a.iFila < b.iFila ? 1 : -1));
      aFilterAcumuladores.forEach((obj) => {
        var aEncontrado = rowsInputSaldo.filter((element) => {
          return (
            element.Id.includes(obj.Id + '-') &&
            element.Id !== obj.Id &&
            obj.Id.split('-').length + 1 === element.Id.split('-').length
          );
        });
        if (aEncontrado.length > 0) {
          var aEncontradoPadre = rowsInput.filter((element) => {
            return element.Id === obj.Id;
          });
          for (
            let i = 0;
            i < resultCaceberas.length; //oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length;
            i++
          ) {
            var acumulador2 = 0;
            for (let j = 0; j < aEncontrado.length; j++) {
              const element2 = aEncontrado[j].Items[i];
              acumulador2 = acumulador2 + element2.Valor;
            }
            aEncontradoPadre[0].Items[i].Valor = acumulador2;
          }
        } else {
        }
      });
    }
  };

  const realizarRecalculoMejorado = (aRows, rowsInput, resultCaceberas) => {
    for (let indexMej = 0; indexMej < aRows.length; indexMej++) {
      const elementInput = rowsInput.filter((element) => {
        return element.Id.includes(aRows[indexMej]);
      });
      elementInput.forEach((element) => {
        element.iFila = element.Id.split('-').length + 1;
      });
      var aFilterAcumuladores = elementInput.filter((element) => {
        return (
          element.IdentificadorCPR === 'C' ||
          element.IdentificadorCPR === 'P' ||
          element.IdentificadorCPR === 'D'
        );
      });
      if (aFilterAcumuladores.length > 0) {
        aFilterAcumuladores.sort((a, b) => (a.iFila < b.iFila ? 1 : -1));
        aFilterAcumuladores.forEach((obj) => {
          var aEncontrado = elementInput.filter((element) => {
            return (
              element.Id.includes(obj.Id + '-') &&
              element.Id !== obj.Id &&
              obj.Id.split('-').length + 1 === element.Id.split('-').length
            );
          });
          if (aEncontrado.length > 0) {
            var aEncontradoPadre = rowsInput.filter((element) => {
              return element.Id === obj.Id;
            });
            for (
              let i = 0;
              i < resultCaceberas.length; //oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length;
              i++
            ) {
              var acumulador2 = 0;
              for (let j = 0; j < aEncontrado.length; j++) {
                const element2 = aEncontrado[j].Items[i];
                acumulador2 = acumulador2 + element2.Valor;
              }
              aEncontradoPadre[0].Items[i].Valor = acumulador2;
            }
          } else {
          }
        });
      }
    }

    return rowsInput;
  };

  const onClickTabSolucione = () => {
    setShowGrafico(false);
    if (flujoCajaState.idOptimiacion === null) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se ha realizado la ejecución del optimizador.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    if (flujoCajaState.idOptimiacion != null && flujoCajaState.aListaSoluciones.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No se encontraron soluciones sugeridas.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    if (flujoCajaState.idOptimiacion != null && flujoCajaState.aListaSoluciones.length > 0) {
      dispatch(onSetTabProceso({ tabProceso: 1 }));
      return;
    }
    // if (flujoCajaState.aListaSoluciones.length > 0) {
    //   dispatch(onSetTabProceso({ tabProceso: 1 }))
    // } else {
    //   Swal.fire({
    //     position: "center",
    //     icon: "warning",
    //     title: "No se ha realizado la ejecución del optimizador.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

  const filterPeridiocidad = () => {
    const detalleFlujoCajaCabecerasInicial = [...flujoCajaState.detalleFlujoCajaCabecerasInicial];
    // var sCodFechaPeriodo = flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo ;
    var sCodFechaPeriodo = flujoCajaState.selectPeridiocidadTableFC[0].value;
    let aListaDetalleAgrupado2 = [];
    let helper = {};
    if (sCodFechaPeriodo === 2) {
      aListaDetalleAgrupado2 = detalleFlujoCajaCabecerasInicial.reduce(function (r, o) {
        var key = o.Anio + '_' + o.Semana;
        // var key = o.Anio+"_"+o.Semana
        if (!helper[key]) {
          //inicializa
          helper[key] = {
            Semana: o.Semana,
            Mes: o.Mes,
            Anio: o.Anio,
            sCabecera: 'Sem ' + o.Semana + '/' + o.Anio,
            sFechaMax: '',
            sFechaMin: '',
            Items: [o],
          };
          r.push(helper[key]);
        } else {
          helper[key].Items.push(o);
        }
        return r;
      }, []);

      aListaDetalleAgrupado2.forEach((element, index) => {
        element.sFechaMin = element.Items[0].FECHAMIN_SEM; //element.Items[0].sFechaMin;
        element.sFechaMax = element.Items[element.Items.length - 1].FECHAMAX_SEM; //element.Items[element.Items.length-1].sFechaMax;
        if (index === 0) {
          element.desde = 0;
          element.hasta = element.Items.length - 1;
        } else {
          element.desde = aListaDetalleAgrupado2[index - 1].hasta + 1;
          element.hasta = aListaDetalleAgrupado2[index - 1].hasta + element.Items.length;
        }
        // element.desde = 0;
        //   element.hasta = element.Items.length-1;
      });
      dispatch(onSetDetalleFlujoCajaCabeceras(aListaDetalleAgrupado2));
    } else if (sCodFechaPeriodo === 3) {
      aListaDetalleAgrupado2 = detalleFlujoCajaCabecerasInicial.reduce(function (r, o) {
        var key = o.Mes + '_' + o.Anio;
        if (!helper[key]) {
          //inicializa
          helper[key] = {
            Mes: o.Mes,
            Anio: o.Anio,
            sCabecera: monthToString(o.Mes) + ' ' + o.Anio,
            sFechaMax: '',
            sFechaMin: '',
            Items: [o],
          };
          r.push(helper[key]);
        } else {
          helper[key].Items.push(o);
        }
        return r;
      }, []);
      aListaDetalleAgrupado2.forEach((element, index) => {
        element.sFechaMin = element.Items[0].FECHAMIN_MES; //element.Items[0].sFechaMin;
        element.sFechaMax = element.Items[element.Items.length - 1].FECHAMAX_MES; //.sFechaMax;
        if (index === 0) {
          element.desde = 0;
          element.hasta = element.Items.length - 1;
        } else {
          element.desde = aListaDetalleAgrupado2[index - 1].hasta + 1;
          element.hasta = aListaDetalleAgrupado2[index - 1].hasta + element.Items.length;
        }
        // element.desde = 0;
        //   element.hasta = element.Items.length-1;
      });
      dispatch(onSetDetalleFlujoCajaCabeceras(aListaDetalleAgrupado2));
    } else if (sCodFechaPeriodo === 1) {
      detalleFlujoCajaCabecerasInicial.forEach((element, index) => {
        const obj = { ...element };
        obj['desde'] = index;
        obj['hasta'] = index + 1;
        aListaDetalleAgrupado2.push(obj);
      });
      dispatch(onSetDetalleFlujoCajaCabeceras(aListaDetalleAgrupado2));
    }
    return aListaDetalleAgrupado2;
  };

  const filterPeridiocidadXParametro = (selectPeridiocidadTableSolucionFC) => {
    const detalleFlujoCajaCabecerasInicial = [...flujoCajaState.detalleFlujoCajaCabecerasInicial];
    // var sCodFechaPeriodo = flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo ;
    var sCodFechaPeriodo = selectPeridiocidadTableSolucionFC[0].value;
    let aListaDetalleAgrupado2 = [];
    let helper = {};
    if (sCodFechaPeriodo === 2) {
      aListaDetalleAgrupado2 = detalleFlujoCajaCabecerasInicial.reduce(function (r, o) {
        var key = o.Anio + '_' + o.Semana;
        // var key = o.Anio+"_"+o.Semana
        if (!helper[key]) {
          //inicializa
          helper[key] = {
            Semana: o.Semana,
            Mes: o.Mes,
            Anio: o.Anio,
            sCabecera: 'Sem ' + o.Semana + '/' + o.Anio,
            sFechaMax: '',
            sFechaMin: '',
            Items: [o],
          };
          r.push(helper[key]);
        } else {
          helper[key].Items.push(o);
        }
        return r;
      }, []);
      aListaDetalleAgrupado2.forEach((element, index) => {
        element.sFechaMin = element.Items[0].FECHAMIN_SEM;
        element.sFechaMax = element.Items[element.Items.length - 1].FECHAMAX_SEM;
        if (index === 0) {
          element.desde = 0;
          element.hasta = element.Items.length - 1;
        } else {
          element.desde = aListaDetalleAgrupado2[index - 1].hasta + 1;
          element.hasta = aListaDetalleAgrupado2[index - 1].hasta + element.Items.length;
        }
        // element.desde = 0;
        //   element.hasta = element.Items.length-1;
      });
      dispatch(
        onSetDetalleFlujoCajaCabecerasSolucion({
          detalleFlujoCajaCabecerasSolucion: aListaDetalleAgrupado2,
        })
      );
    } else if (sCodFechaPeriodo === 3) {
      aListaDetalleAgrupado2 = detalleFlujoCajaCabecerasInicial.reduce(function (r, o) {
        var key = o.Mes + '_' + o.Anio;
        if (!helper[key]) {
          //inicializa
          helper[key] = {
            Mes: o.Mes,
            Anio: o.Anio,
            sCabecera: monthToString(o.Mes) + '-' + o.Anio,
            sFechaMax: '',
            sFechaMin: '',
            Items: [o],
          };
          r.push(helper[key]);
        } else {
          helper[key].Items.push(o);
        }
        return r;
      }, []);
      aListaDetalleAgrupado2.forEach((element, index) => {
        element.sFechaMin = element.Items[0].FECHAMIN_MES;
        element.sFechaMax = element.Items[element.Items.length - 1].FECHAMAX_MES;
        if (index === 0) {
          element.desde = 0;
          element.hasta = element.Items.length - 1;
        } else {
          element.desde = aListaDetalleAgrupado2[index - 1].hasta + 1;
          element.hasta = aListaDetalleAgrupado2[index - 1].hasta + element.Items.length;
        }
        // element.desde = 0;
        //   element.hasta = element.Items.length-1;
      });
      dispatch(
        onSetDetalleFlujoCajaCabecerasSolucion({
          detalleFlujoCajaCabecerasSolucion: aListaDetalleAgrupado2,
        })
      );
    } else if (sCodFechaPeriodo === 1) {
      detalleFlujoCajaCabecerasInicial.forEach((element, index) => {
        const obj = { ...element };
        obj['desde'] = index;
        obj['hasta'] = index + 1;
        aListaDetalleAgrupado2.push(obj);
      });
      dispatch(
        onSetDetalleFlujoCajaCabecerasSolucion({
          detalleFlujoCajaCabecerasSolucion: aListaDetalleAgrupado2,
        })
      );
    }
    return aListaDetalleAgrupado2;
  };

  const collapseChild = (idCarpetaPadreGeneral, aListaSplitAgrupada) => {
    if (aListaSplitAgrupada.length > 0) {
      aListaSplitAgrupada[0]['collapse'] = false;
      aListaSplitAgrupada[0]['collapseIcon'] = true;
      var aEncontrado = aListaSplitAgrupada.filter((element) => {
        return (
          element.Id.includes(idCarpetaPadreGeneral + '-') && element.Id !== idCarpetaPadreGeneral
        );
      });
      var aEncontrado2 = aListaSplitAgrupada.filter((element) => {
        return (
          element.Id.includes(idCarpetaPadreGeneral + '-') &&
          element.Id !== idCarpetaPadreGeneral &&
          idCarpetaPadreGeneral.split('-').length + 1 === element.Id.split('-').length
        );
      });
      if (aEncontrado.length > 0) {
        aEncontrado2.forEach((element) => {
          element.collapse = false;
          element.collapseIcon = false;
          var aEncontrado = aListaSplitAgrupada.filter((elemen2) => {
            return elemen2.Id.includes(element.Id + '-') && elemen2.Id !== element.Id;
          });
          aEncontrado.forEach((element) => {
            element.collapse = true;
            element['collapseIcon'] = false;
          });
        });
        return [aListaSplitAgrupada[0], aEncontrado2[0]];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const onBuscarDetalle = () => {
    dispatch(
      changeListaAgruadaXSoluciones({ IdSolucion: flujoCajaState.selectListaSoluciones[0].value })
    );
  };

  //Excel
  const onExcel = async (finalArray, finalArrayTotal, finalArrayTotalParciales) => {
    let now = new Date();

    let NombreArchivo =
      'FC_' +
      flujoCajaState.sVersion +
      '_' +
      String(now.getDate()).padStart(2, '0') +
      '-' +
      String(now.getMonth() + 1).padStart(2, '0') +
      '-' +
      now.getFullYear();

    let wb = await JsoToExcelJsFC(NombreArchivo, 'FlujoCaja', 1, [
      {
        data: flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras,
        value: 'sCabecera',
        validar: flujoCajaState.selectPeridiocidadTableFC[0].label,
        primerDato: 'Posición / Periodo',
      },
      {
        dataCase: 1,
        data: finalArray,
        value: 'Posicion',
        arrData: 'Items',
        arrValue: 'Valor',
        needId: true,
        idValue: 'Id',
      },
      {
        dataCase: 2,
        data: finalArrayTotalParciales,
        value: 'Id',
        arrData: 'Items',
        arrValue: 'Valor',
      },
      {
        dataCase: 2,
        data: finalArrayTotal,
        value: 'Id',
        arrData: 'Items',
        arrValue: 'Valor',
      },
    ]);
  };

  const generarGrafico = async () => {
    setShowGrafico(!showGrafico);

    if (!showGrafico) {
      let saldoPrimero = 0;
      finalArraySinFiltro.map((v) => {
        let id = v.Id.split('-');
        if (v.IdentificadorCPR === 'R' && id[0] === 'S') {
          saldoPrimero += v.Items[0].Valor;
        }
      });
      const labels = flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras.map(function (
        item,
        index
      ) {
        return item.sCabecera;
      });

      let datasets = [];

      let obj = {
        label: 'Flujo Caja Periodo',
        data: finalArrayTotalParciales.Items.map(function (item, index) {
          if (index === 0) {
            return item.Valor - saldoPrimero;
          } else {
            return item.Valor;
          }
        }),
        pointStyle: 'circle',
        pointRadius: 1,
        // pointStyle: false,
        fill: true,
        borderColor: theme.colorsDashboard[0],
        backgroundColor: theme.colorsDashboardRGBA[0],
      };
      let obj1 = {
        label: 'Flujo Caja Acumulado',
        data: finalArrayTotal.Items.map(function (item, index) {
          return item.Valor;
        }),
        pointStyle: 'circle',
        pointRadius: 1,
        fill: true,
        borderColor: theme.colorsDashboard[1],
        backgroundColor: theme.colorsDashboardRGBA[1],
      };

      datasets.push(obj);
      datasets.push(obj1);

      const data = {
        labelsChartFI: labels,
        datasetsChartFI: datasets,
      };

      setOptions({
        chart: {
          foreColor: '#adb0bb',
          fontFamily: theme.font.family,
        },
        responsive: true,
        tension: 0.4,
        interaction: {
          mode: 'index',
        },

        yaxis: {
          labels: {
            formatter: function (value) {
              return new Intl.NumberFormat('en-US').format(value);
            },
          },
          title: {
            text: flujoCajaState.selectMonedaTableFC.label,
            offsetX: 0,
            offsetY: 0,
          },
        },
        xaxis: {
          title: {
            text: 'Diario',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: (ctx) => 'Competencias de Flujo de Caja',
          },
          tooltip: {
            usePointStyle: true,
          },
        },
      });
      dispatch(onGenerateDataLineChartFC(data));
    }
  };

  const downloadImage = useCallback(() => {
    const link = document.createElement('a');
    link.download = 'Competencias_de_FC.png';
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === '*')) {
      return `${placeholderButtonLabel}: Todos`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  return (
    <Content style={{ overflow: 'auto' }}>
      <ParamCard>
        <ParamCardHeader>
          <ParamCardTitle>Parámetros :</ParamCardTitle>
          {false ? (
            <></>
          ) : (
            <ContentRow>
              {finalArray.length === 0 ? (
                <></>
              ) : (
                <>
                  <ButtonForm
                    isAction={true}
                    isOnlyIcon={false}
                    noBackground={true}
                    name={'Filtros'}
                    action={openModalFiltros}
                    type={'tertiary'}
                  />

                  <ButtonForm
                    isAction={true}
                    isOnlyIcon={false}
                    noBackground={true}
                    name={'Reglas'}
                    action={openModalRestricciones}
                    type={'tertiary'}
                  />

                  <ButtonForm
                    isAction={true}
                    isOnlyIcon={false}
                    noBackground={true}
                    name={'Soluciones'}
                    action={openModalSoluciones}
                    type={'tertiary'}
                  />

                  <ButtonForm
                    icon={<FaCodeBranch />}
                    isAction={true}
                    isOnlyIcon={false}
                    noBackground={true}
                    name={'Guardar Version'}
                    action={(e) => onGuardarVersion(e, finalArrayEliminar)}
                    type={'tertiary'}
                  />

                  {flujoCajaState.selectVersionFlujoCaja.length > 0 ? (
                    <>
                      <ButtonForm
                        isAction={true}
                        isOnlyIcon={false}
                        noBackground={true}
                        name={'Guardar Cambios'}
                        icon={<FaExpand />}
                        action={(e) => onGuardarCambios(e)}
                        type={'tertiary'}
                      />
                      <ButtonForm
                        isAction={true}
                        isOnlyIcon={false}
                        noBackground={true}
                        name={'Ejecutar OPT'}
                        icon={<FaClipboard />}
                        action={(e) =>
                          onEjecutarOptimizador(e, finalArrayEliminar, finalArrayTotal)
                        }
                        type={'tertiary'}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {flujoCajaState.idOptimiacion &&
                  flujoCajaState.selectVersionFlujoCaja.length > 0 &&
                  flujoCajaState.aListaSoluciones.length > 0 ? (
                    <ButtonForm
                      isAction={true}
                      isOnlyIcon={false}
                      noBackground={true}
                      name={'Guardar F.C'}
                      icon={<FaDice />}
                      action={(e) => onGuardarFlujoCaja(e, finalArrayEliminar)}
                      type={'tertiary'}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </ContentRow>
          )}
          <ContentButton>
            <ButtonForm
              icon={<FaAngleDown />}
              isAction={false}
              isOnlyIcon={true}
              noBackground={true}
              rotateIcon={expandedParametroEntrada}
              size={'18px'}
              style={{
                marginRight: '10px',
                overflow: 'hidden',
              }}
              action={(e) => showParametros(e)}
            />
          </ContentButton>
        </ParamCardHeader>
        <ParamCardBody
          className={expandedParametroEntrada ? 'show' : ''}
          setHeight={accodionHeightParametroEntrada}
          ref={ref2}
        >
          <Content>
            <ContentForm width={'100%'}>
              <RowForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Versión :'}></LabelForm>
                    <InputSearchForm
                      placeholder="Ingrese..."
                      enterButton={<FaCubes />}
                      value={flujoCajaState.sVersion}
                      onChange={(e) => handleChangeVersion(e)}
                      onSearch={onPressBuscarVersion}
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Estructura : '}></LabelForm>
                    <SelectForm
                      bValid={true}
                      value={
                        flujoCajaState.iIdEstructura === null
                          ? []
                          : [
                              {
                                value: flujoCajaState.iIdEstructura,
                                label: flujoCajaState.sEstructura,
                              },
                            ]
                      }
                      handleChange={(evnt, v) => handleChangeEstructuraBaseCrearFlujoCaja(v)}
                      options={aListaEstructurasBaseCrearFlujoCaja}
                      placeholder="Seleccione Estructura"
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Etiqueta : '}></LabelForm>
                    <SelectForm
                      isMultiple={true}
                      bValid={true}
                      value={flujoCajaState.selectEtiquetaFlujoCaja}
                      handleChange={(evnt, v) => handleChangeMultiKey(v)}
                      options={flujoCajaState.listarEtiquetasFlujoCaja}
                      placeholder="Seleccione etiqueta"
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Fecha : '}></LabelForm>
                    <DatePickerForm
                      dateFormat="dd/MM/yyyy"
                      selectsRange={true}
                      startDate={flujoCajaState.dDateRange[0]}
                      endDate={flujoCajaState.dDateRange[1]}
                      onChange={(evnt) => handleChangeFechaCrearFlujoCaja(evnt)}
                      isClearable={true}
                      placeholderText="Ingrese"
                      locale="es"
                      showMonthDropdown
                      showYearDropdown
                      portalId="root-portal"
                      validate={flujoCajaState.bValidFecha}
                      alignText={'center'}
                    />
                  </RowForm>
                </ColForm>

                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Horizonte :'}></LabelForm>
                    <RowDoubleInput>
                      <Input
                        width={'37%'}
                        onKeyPress={(evnt) => onlyNumbers(evnt)}
                        placeholder="Ingrese..."
                        onChange={(e) => handleChangeProyeccion(e)}
                        value={flujoCajaState.iProyeccion}
                      ></Input>
                      <SelectForm
                        width={'60%'}
                        bValid={true}
                        value={
                          flujoCajaState.iIdtiempoProyeccion === null
                            ? []
                            : [
                                {
                                  value: flujoCajaState.iIdtiempoProyeccion,
                                  label: flujoCajaState.sTiempoProyeccion,
                                },
                              ]
                        }
                        handleChange={(evnt, v) => handleChangeTiempoProyeccion(v)}
                        options={listaProyeccion}
                        placeholder="Seleccione tiempo"
                      />
                    </RowDoubleInput>
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Universo : '}></LabelForm>
                    <SelectForm
                      bValid={true}
                      disableClearable={true}
                      value={flujoCajaState.universo}
                      handleChange={(evnt, v) => handleUniverso(v)}
                      options={listaUniverso}
                      placeholder="Seleccione Universo"
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Sociedad : '}></LabelForm>
                    <SelectForm
                      bValid={true}
                      disableClearable={true}
                      value={flujoCajaState.selectSociedad}
                      handleChange={(evnt, v) => handleSociedad(v)}
                      options={aSociedadesCrearFlujoCaja}
                      placeholder="Seleccione Sociedad"
                      isMultiple={true}
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <ButtonForm
                      action={(e) => onBuscarFlujoCaja()}
                      name="Aplicar"
                      isAction={true}
                      typeColor="primary"
                      width="98%"
                      height="32px"
                    />
                  </RowForm>
                </ColForm>
              </RowForm>
            </ContentForm>
          </Content>
        </ParamCardBody>
      </ParamCard>
      {loading === null ? (
        <> Ingrese los parametros de entrada para realizar la busqueda </>
      ) : finalArray.length === 0 ? (
        <NoData title={'Ingrese los parámetros de entrada para realizar la búsqueda.'}></NoData>
      ) : (
        <>
          <TabBarCreacion
            marginTopContentBar="10px"
            onClickTab1={(e) => {
              setShowGrafico(false);
              dispatch(onSetTabProceso({ tabProceso: 0, labelsChartFC: [], datasetsChartFC: [] }));
            }}
            tabProceso={flujoCajaState.tabProceso}
            labelTab1={'Flujo Inicial'}
            onClickTab2={(e) => onClickTabSolucione(e)}
            labelTab2={'Flujo Solucionado'}
          />
          {flujoCajaState.tabProceso === 0 ? (
            <>
              <ContentForm width={'100%'} style={{ marginBottom: '5px', padding: '0' }}>
                <RowForm style={{ width: '100%' }}>
                  <ColForm xs={20} sm={11} md={11} lg={8} xl={5}>
                    <RowForm>
                      <LabelForm title={'Periodicidad : '}></LabelForm>

                      <SelectForm
                        bValid={true}
                        disableClearable={true}
                        value={flujoCajaState.selectPeridiocidadTableFC}
                        handleChange={(evnt, v) => handleChangePeridiocidadTableFC(v)}
                        options={aTiemposPeriodoCrearFlujoCaja}
                        placeholder="Seleccione Periodo"
                      />
                    </RowForm>
                  </ColForm>
                  <ColForm xs={20} sm={11} md={11} lg={8} xl={5}>
                    <RowForm>
                      <LabelForm title={'Moneda : '}></LabelForm>
                      <SelectForm
                        bValid={true}
                        disableClearable={true}
                        value={flujoCajaState.selectMonedaTableFC}
                        handleChange={(evnt, v) => handleChangeMonedaTableFC(v)}
                        options={aMonedasCrearFlujoCaja}
                        placeholder="Seleccione Moneda"
                      />
                    </RowForm>
                  </ColForm>

                  <ColForm
                    xs={20}
                    sm={11}
                    md={11}
                    lg={8}
                    xl={5}
                    style={!filtroPersonalizado1 ? { display: 'none' } : { display: 'block' }}
                  >
                    <RowForm>
                      <LabelForm title={`${filtroPersonalizadoNombre1} : `}></LabelForm>

                      <MultiSelectCheckboxes
                        options={[initiallAllPosition, ...listaValorPersonalizada1]}
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        selectedOptions={selectValorPersonaliza1}
                        onChange={(value, oEvent) =>
                          handlePersonaliza1ValorMultiCheckbox(value, oEvent)
                        }
                        placeholderButtonLabel={'Valores'}
                      />
                    </RowForm>
                  </ColForm>
                  <ColForm
                    xs={20}
                    sm={11}
                    md={11}
                    lg={8}
                    xl={5}
                    style={!filtroPersonalizado2 ? { display: 'none' } : { display: 'block' }}
                  >
                    <RowForm>
                      <LabelForm title={`${filtroPersonalizadoNombre2} : `}></LabelForm>

                      <MultiSelectCheckboxes
                        options={[initiallAllPosition, ...listaValorPersonalizada2]}
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        selectedOptions={selectValorPersonaliza2}
                        onChange={(value, oEvent) =>
                          handlePersonaliza2ValorMultiCheckbox(value, oEvent)
                        }
                        placeholderButtonLabel={'Valores'}
                      />
                    </RowForm>
                  </ColForm>

                  <ColForm
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <RowForm style={{ justifyContent: 'flex-end' }}>
                      <Tooltip title="Descargar Excel">
                        <>
                          <ButtonForm
                            action={(e) =>
                              onExcel(finalArray, finalArrayTotal, finalArrayTotalParciales)
                            }
                            icon={<FaFileExcel />}
                            isAction={true}
                            isOnlyIcon={true}
                            typeColor="secondary"
                            style={{ marginRight: '2px' }}
                          />
                        </>
                      </Tooltip>
                      <Tooltip title="Generar Gráfico">
                        <>
                          <ButtonForm
                            action={(e) => generarGrafico()}
                            icon={<FaChartLine />}
                            isAction={true}
                            isOnlyIcon={true}
                            typeColor="secondary"
                            style={{ marginRight: '2px' }}
                          />
                        </>
                      </Tooltip>
                      <Tooltip title="Descargar Gráfico">
                        <>
                          <ButtonForm
                            action={(e) => downloadImage()}
                            icon={<FaDownload />}
                            isAction={true}
                            isOnlyIcon={true}
                            typeColor="secondary"
                          />
                        </>
                      </Tooltip>
                    </RowForm>
                  </ColForm>
                </RowForm>
              </ContentForm>
              <TableDetalleFlujoCaja
                finalArray={finalArray}
                setFinalArray={setFinalArray}
                //GNM
                finalArraySinFiltro={finalArraySinFiltro}
                setFinalArraySinFiltro={setFinalArraySinFiltro}
                //GNM
                finalArrayTotal={finalArrayTotal}
                setFinalArrayTotal={setFinalArrayTotal}
                finalArrayTotalParciales={finalArrayTotalParciales}
                setFinalArrayTotalParciales={setFinalArrayTotalParciales}
                editTable={editTable}
                setEditTable={setEditTable}
                finalArrayEliminar={finalArrayEliminar}
                setFinalArrayEliminar={setFinalArrayEliminar}
                objRightClick={objRightClick}
                setObjRightClick={setObjRightClick}
                objRightPosicionClick={objRightPosicionClick}
                setObjRightPosicionClick={setObjRightPosicionClick}
                objRightRegistroClick={objRightRegistroClick}
                setObjRightRegistroClick={setObjRightRegistroClick}
                formAgregarRegM={formAgregarRegM}
                setFormAgregarRegM={setFormAgregarRegM}
                limpiarFormAgregarRegM={limpiarFormAgregarRegM}
                selectedDesdobles={selectedDesdobles}
                setSelectedDesdobles={setSelectedDesdobles}
                selectionCollapseModel={selectionCollapseModel}
                setSelectionCollapseModel={setSelectionCollapseModel}
                //prueba
                calcularTotalYsubTotal={calcularTotalYsubTotal}
                realizarRecalculoMejorado={realizarRecalculoMejorado}
                collapseChild={collapseChild}
                setShowGrafico={setShowGrafico}
              />
              {showGrafico ? (
                <>
                  <Line
                    ref={ref}
                    options={options}
                    data={{
                      labels: flujoCajaState.labelsChartFI,
                      datasets: flujoCajaState.datasetsChartFI,
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Solucion
              finalArray={finalArray}
              setFinalArray={setFinalArray}
              //GNM
              finalArraySinFiltro={finalArraySinFiltro}
              setFinalArraySinFiltro={setFinalArraySinFiltro}
              //GNM
              finalArrayTotal={finalArrayTotal}
              setFinalArrayTotal={setFinalArrayTotal}
              finalArrayTotalParciales={finalArrayTotalParciales}
              setFinalArrayTotalParciales={setFinalArrayTotalParciales}
              editTable={editTable}
              setEditTable={setEditTable}
              finalArrayEliminar={finalArrayEliminar}
              setFinalArrayEliminar={setFinalArrayEliminar}
              objRightClick={objRightClick}
              setObjRightClick={setObjRightClick}
              objRightRegistroClick={objRightRegistroClick}
              setObjRightRegistroClick={setObjRightRegistroClick}
              formAgregarRegM={formAgregarRegM}
              setFormAgregarRegM={setFormAgregarRegM}
              limpiarFormAgregarRegM={limpiarFormAgregarRegM}
              //------------------DATOS QUE SIRVEN
              objRightPosicionClick={objRightPosicionClick}
              setObjRightPosicionClick={setObjRightPosicionClick}
              selectedDesdoblesInicialTableSolucionFC={selectedDesdoblesInicialTableSolucionFC}
              setSelectedDesdoblesInicialTableSolucionFC={
                setSelectedDesdoblesInicialTableSolucionFC
              }
            />
          )}
        </>
      )}
      <ModalAgregarNombre onRegistrarFlujoCaja={onRegistrarFlujoCaja} />
      <ModalRestricciones
        loadRestriccionesVersionFCXUsuario={loadRestriccionesVersionFCXUsuario}
        selectionRestriccionesModel={selectionRestriccionesModel}
        setSelectionRestriccionesModel={setSelectionRestriccionesModel}
      />
      <ModalVersion onPressBuscarVersion={onPressBuscarVersion} />
      <ModalSoluciones loadSolucionesVersionFCXUsuario={loadSolucionesVersionFCXUsuario} />
      <ModalFiltros
        loadFiltrosVersionFCXUsuario={loadFiltrosVersionFCXUsuario}
        selectionFiltrosModel={selectionFiltrosModel}
        setSelectionFiltrosModel={setSelectionFiltrosModel}
        setSelectValorPersonaliza1={setSelectValorPersonaliza1}
        setSelectValorPersonaliza2={setSelectValorPersonaliza2}
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </Content>
  );
};
