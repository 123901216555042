import React, { useCallback, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import {
  TableDetalleFlujoCaja,
  ModalFiltros,
  ModalRestricciones,
  ModalSoluciones,
  Solucion,
} from './components';

import { useDispatch, useSelector } from 'react-redux';
import { useFetchAndLoad } from '../../../../common/hooks';
import {
  Input,
  LabelForm,
  LoadingSpinner,
  ButtonForm,
  SelectForm,
  TabBarCreacion,
} from '../../../../components';
import {
  crearVersionFlujoCaja,
  guardarRegistrosFlujoCaja,
  obtenerDetalleFlujoCajaGeneral,
  obtenerEstructurasBase,
  obtenerInfoFiltrosFlujoCajaCabecera,
  obtenerListadoEtiquetas,
  obtenerListadoMoneda,
  obtenerListadoPeriodo,
  obtenerListadoTC,
  obtenerPaletaColoresXSolucion,
  obtenerSoluciones,
  obtenerSplitRegistros,
  obtenerUrlImg,
  obtenerVersionesFlujoCaja,
  saveHeaderAndDetailsFlowsCaja,
  updateVerionFlujoCaja,
} from '../../../../services';
import { createEstructuraBaseAdapter } from '../../../../adapters/estructuraBase.adapter';
import {
  onSetDetalleFlujoCajaCabeceras,
  onChangeEstructuraCrearFlujoCaja,
  onChangeFechaCrearFlujoCaja,
  onChangeFechaPeriodoCrearFlujoCaja,
  onChangeMonedaCrearFlujoCaja,
  onOpenModalFiltrosDetalleFlujoCaja,
  onOpenModalRestriccionesDetalleFlujoCaja,
  onOpenModalVersionFlujoCaja,
  onSetEstructurasBaseCrearFlujoCaja,
  onSetListaVersionesFlujoCaja,
  onSetPeriodoCrearFlujoCaja,
  onSetMonedasCrearFlujoCaja,
  onChangeTiempoPeridiocidad,
  onChangeProyeccion,
  onChangeVersion,
  onSetListaPosicionesFiltrosFlujoCaja,
  onLimpiarFiltroDetalleFlujoCaja,
  onSetTabProceso,
  onSetListaSoluciones,
  changeSelectListaSoluciones,
  onSetListaAgrupadaXSolucionesParcial,
  onSetListaAgrupadaXSolucionesTotal,
  changeListaAgruadaXSoluciones,
  onSetDetalleListaAgrupadaXSoluciones,
  onLimpiarRestriccionDetalleFlujoCaja,
  onSetListaEtiqueFlujoCaja,
  onSelectEtiquetaFlujoCaja,
  onOpenModalSolucionesDetalleFlujoCaja,
  limpiarSolucionesFlujoCaja,
  onSetListaModalSolFlujoCaja,
  onSetFilterListaModalSolFlujoCaja,
  onSetListaTipoEstadoSolFlujoCaja,
  collapseDesdobles,
  onSetOptimizador,
  onLimpiarCamposCrearFlujoCaja,
  onLimpiarCamposFlujoCaja,
  onSelectVersionFlujoCaja,
  updateTitle,
  onOpenModalAgregarNombreFlujoCaja,
  onSelectPeridiocidadTableFC,
  onSetDetalleFlujoCajaCabecerasInicial,
  onSelectMonedaTableFC,
  onSetTipoCambioFlujoCaja,
  onSetFactorMonedaTableFC,
  loadVisualizarConceptoFC,
  onSetFiltroDetalleFlujoCaja,
  onSelectPeridiocidadTableSolucionFC,
  onSetDetalleFlujoCajaCabecerasSolucion,
  onSetListaAgrupadaXSolucionesSumarizada,
  onGenerateDataLineChartFC,
} from '../../../../redux/states';
import {
  createEstadoSolucionSelectAdapter,
  createFlujoCajaEtiquetaAdapter,
  createMonedaAdapter,
  createPeriodoAdapter,
  createSolucionModalFlujoCajaAdapter,
  createVersionFlujoCajaAdapter,
} from '../../../../adapters';
import {
  addDays,
  dateTo_dd_mm_yyyy,
  onlyNumbers,
  dateTo_yyyy_mm_dd,
  monthToString,
  stringFechaToDateWithOutTime,
  JsoToExcelJsFC,
  theme,
  paths,
} from '../../../../common';
import { createObtenerInfoFiltrosFlujoCajaCabeceraAdapter } from '../../../../adapters/obtenerInfoFiltrosFlujoCajaCabecera.adapter';
import collect from 'collect.js';
import { EstadoSolucionesData } from '../../../../common';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  ColForm,
  Content,
  ContentButton,
  ContentForm,
  ContentRow,
  DatePickerForm,
  ParamCard,
  ParamCardBody,
  ParamCardHeader,
  ParamCardTitle,
  RowDoubleInput,
  RowForm,
} from '../../../../styles';
import { initialFormAgregarRegM, listaProyeccion } from './misc';
import { Tooltip } from 'antd';
import { FaAngleDown, FaChartLine, FaDownload, FaFileExcel } from 'react-icons/fa6';
registerLocale('es', es);

export const VisualizarFlujoCaja = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const flujoCajaState = useSelector((store) => store.flujoCaja);
  const personalizacionState = useSelector((store) => store.personalizacion);
  const [expandedParametroEntrada, setExpandedParametroEntrada] = useState(false);
  const [accodionHeightParametroEntrada, setAccodionHeightParametroEntrada] = useState(0);
  const {
    aListaEstructurasBaseCrearFlujoCaja,
    aTiemposPeriodoCrearFlujoCaja,
    aMonedasCrearFlujoCaja,
    dDateRange,
  } = flujoCajaState;
  const [finalArray, setFinalArray] = useState([]);
  const [finalArraySinFiltro, setFinalArraySinFiltro] = useState([]);
  const [finalArrayEliminar, setFinalArrayEliminar] = useState([]);
  const [finalArrayTotal, setFinalArrayTotal] = useState({});
  const [finalArrayTotalParciales, setFinalArrayTotalParciales] = useState({});
  const [finalArrayCaracAgrupada, setFinalArrayCaracAgrupada] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [objRightClick, setObjRightClick] = useState({});
  const [objRightPosicionClick, setObjRightPosicionClick] = useState(null);
  const [objRightRegistroClick, setObjRightRegistroClick] = useState(null);
  const [selectedDesdobles, setSelectedDesdobles] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [loadingDetalle, setLoadingDetalle] = useState(true);
  const [selectionFiltrosModel, setSelectionFiltrosModel] = React.useState([]);
  const [formAgregarRegM, setFormAgregarRegM] = useState(initialFormAgregarRegM);
  const authState = useSelector((store) => store.auth);
  const limpiarFormAgregarRegM = () => {
    setFormAgregarRegM(initialFormAgregarRegM);
  };
  const [showGrafico, setShowGrafico] = useState(false);
  const [options, setOptions] = useState(null);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const showParametros = () => setExpandedParametroEntrada(!expandedParametroEntrada);

  useEffect(() => {
    if (personalizacionState.listaPersonalizada.length > 0 && authState.user.uid != '') {
      onObtenerDetalleFlujoCaja();
    }
  }, [personalizacionState.listaPersonalizada, authState.user]);

  useEffect(() => {
    const getHeight = ref2.current ? ref2.current.scrollHeight : 0; //ref2.current.scrollHeight;
    setAccodionHeightParametroEntrada(getHeight);
  }, [expandedParametroEntrada]);

  useEffect(() => {
    const onPageLoad = () => {};
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    }
  }, []);

  useEffect(() => {
    let result = [];
    let resultCaceberas = flujoCajaState.detalleFlujoCajaCabecerasInicial.map((object) => ({
      ...object,
    })); //[...props.finalArraySinFiltro];
    if (finalArraySinFiltro.length > 0) {
      let lista = [];
      const finalArraySinFiltroCopy = finalArraySinFiltro.map((object) => ({ ...object })); //[...props.finalArraySinFiltro];
      const selectedFilters = {
        posiciones: [],
        caracteristicas: [],
        valores: [],
      };
      flujoCajaState.aFiltrosDetalleFlujoCaja.forEach((element) => {
        element.selectPosicion.forEach((element2) => {
          if (element2.value != '*') {
            selectedFilters.posiciones.push(element2.IdPosicion);
          }
        });
        selectedFilters.caracteristicas.push(element.selectCaracteristica);
        element.selectValor.forEach((element2) => {
          if (element2.value != '*') {
            selectedFilters.valores.push(element2);
          }
        });
      });
      const { posiciones, valores, caracteristicas } = selectedFilters;
      if (posiciones.length === 0 && valores.length === 0 && caracteristicas.length === 0) {
        finalArraySinFiltroCopy.forEach((element) => {
          lista.push({ ...element });
        });
      } else {
        var colectData = collect;
        finalArraySinFiltroCopy.forEach((element) => {
          if (element.IdentificadorCPR === 'C') {
            //
            element.Items.forEach((element2) => {
              element2.Valor = 0;
            });
            lista.push(element);
            // return;
          } else if (element.IdentificadorCPR === 'P') {
            lista.push(element);
          } else if (element.IdentificadorCPR === 'D') {
            lista.push(element);
          } else if (element.IdentificadorCPR === 'R') {
            let datos_reg = [];
            if (posiciones.includes(element.Id_Partida2)) {
              var Id_Partida2 = element.Id_Partida2;
              let datos_reg = element.datos_reg;
              var no_error = false;
              //
              for (let index = 0; index < datos_reg.length; index++) {
                const element = datos_reg[index];
                // var aEncontrado = selectedFilters.valores.filter(
                //   (o) => o.NombreInstancia === element.sDatoPosicion && Id_Partida2 === o.IdPosicion
                // );

                var aEncontrado = selectedFilters.valores.filter(function (item, index) {
                  return (
                    item.aIdPosicion.filter(function (item2, index2) {
                      return item2 === Id_Partida2;
                    }).length > 0
                  );
                });

                if (aEncontrado.length > 0) {
                  no_error = true;
                  break;
                }
              }
              if (no_error) {
                lista.push(element);
              }
            } else {
              lista.push(element);
            }
          }
        });
      }
      result = lista;
      resultCaceberas = filterPeridiocidad();
      result = onRealizarDesdoblesAutomatico(result, resultCaceberas);
      //  if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo != "D"){
      var aListaFinal = [];
      // for (let index = 0; index < resultCaceberas.length; index++) {
      for (var index2 = 0; index2 < result.length; index2++) {
        const element2 = { ...result[index2] };
        const elementCopia = { ...result[index2] };
        // for (let index2 = 0; index2 < result.length; index2++) {
        elementCopia.Items = [];
        if (element2.IdentificadorCPR === 'R') {
          var indexEncontrado = -1;
          element2.Items.find(function (item, i) {
            if (item.EsSplit === 0) {
              indexEncontrado = i;
              return i;
            }
          });
        }
        for (var index = 0; index < resultCaceberas.length; index++) {
          const element = { ...resultCaceberas[index] };
          var acumulador = 0;
          let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;
          var obj = {};
          obj['Id'] = Id + '-' + (index + 1);
          obj['Id2'] = Id + '-' + (index + 1);
          obj['IdPadre'] = Id;
          obj['IdPadre2'] = Id;
          obj['IdHijo'] = Id + '-' + (index + 1);
          obj['IdHijo2'] = Id + '-' + (index + 1);
          obj['IdRegistro'] = index + 1;
          obj['sAccion'] = 'C';
          obj['EsRaiz'] = EsRaiz;
          obj['EsGrupo'] = EsGrupo;
          obj['EsManual'] = EsManual;
          obj['EsSplit'] = EsSplit;
          obj['bEditable'] = bEditable;
          obj['Posicion'] = Posicion;
          obj['Valor'] = 0;
          try {
            if (element2.IdentificadorCPR === 'R') {
              // if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo === "D"){
              if (flujoCajaState.selectPeridiocidadTableFC[0].value === 1) {
                const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = element2.Items[index];
                obj = {
                  ...obj,
                  ...{
                    EsGrupo: EsGrupo,
                    EsManual: EsManual,
                    EsRaiz: EsRaiz,
                    EsSplit: EsSplit,
                    bEditable: bEditable,
                  },
                };
                obj.Valor =
                  element2.Items[index].Valor * flujoCajaState.factorMonedaTableTC[0].valor;
              } else {
                if (element.desde <= indexEncontrado && indexEncontrado <= element.hasta) {
                  var aEncontrado = element2.Items.filter(function (item) {
                    return item.EsSplit === 0;
                  });
                  if (aEncontrado.length > 0) {
                    const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = aEncontrado[0];
                    obj = {
                      ...obj,
                      ...{
                        EsGrupo: EsGrupo,
                        EsManual: EsManual,
                        EsRaiz: EsRaiz,
                        EsSplit: EsSplit,
                        bEditable: bEditable,
                      },
                    };
                    obj.Valor = aEncontrado[0].Valor * flujoCajaState.factorMonedaTableTC[0].valor;
                  }
                }
              }
            }
          } catch (error) {}
          elementCopia.Items.push(obj);
        }
        aListaFinal.push(elementCopia);
      }
      // setFinalArray(aListaFinal)
      //  }else{
      //   aListaFinal = result;
      //  }
      var rowsInput = [];
      [...aListaFinal].forEach((element) => {
        var obj = { ...element };
        obj.Items = [];
        element.Items.forEach((element2) => {
          obj.Items.push({ ...element2 });
        });
        rowsInput.push(obj);
      });
      var rowsInputSaldo = rowsInput.filter((element) => {
        return element.Id.includes('S');
      });
      var rowsInputIngreso = rowsInput.filter((element) => {
        return element.Id.includes('I');
      });
      var rowsInputEgreso = rowsInput.filter((element) => {
        return element.Id.includes('E');
      });
      realizarRecalculo(rowsInputSaldo, rowsInput, resultCaceberas);
      realizarRecalculo(rowsInputIngreso, rowsInput, resultCaceberas);
      realizarRecalculo(rowsInputEgreso, rowsInput, resultCaceberas);
      var aListaSplitAgrupadaSaldo = rowsInput.filter((element) => {
        return element.Id.includes('S');
      });
      collapseChild('S', aListaSplitAgrupadaSaldo);
      var aListaSplitAgrupadaIngreso = rowsInput.filter((element) => {
        return element.Id.includes('I');
      });
      collapseChild('I', aListaSplitAgrupadaIngreso);
      var aListaSplitAgrupadaEgreso = rowsInput.filter((element) => {
        return element.Id.includes('E');
      });
      collapseChild('E', aListaSplitAgrupadaEgreso);
      let aListaSplitAgrupada = [
        ...aListaSplitAgrupadaSaldo,
        ...aListaSplitAgrupadaIngreso,
        ...aListaSplitAgrupadaEgreso,
      ];
      calcularTotalYsubTotal(aListaSplitAgrupada, resultCaceberas);
      realizarRecalculoSubTotal_Total(aListaSplitAgrupada, resultCaceberas);
      setFinalArray(aListaSplitAgrupada);
    }
  }, [
    selectedDesdobles,
    setSelectedDesdobles,
    flujoCajaState.selectPeridiocidadTableFC,
    flujoCajaState.onSelectPeridiocidadTableFC,
    flujoCajaState.aFiltrosDetalleFlujoCaja,
    flujoCajaState.selectMonedaTableFC,
    // flujoCajaState.factorMonedaTableTC
  ]);

  useEffect(() => {
    if (flujoCajaState.selectListaSoluciones.length > 0) {
      onBuscarDetalle();
    }
  }, [flujoCajaState.selectListaSoluciones, changeSelectListaSoluciones]);

  useEffect(() => {
    (async () => {
      if (
        flujoCajaState.selectListaAgrupadaXSoluciones.length > 0 &&
        flujoCajaState.selectListaSoluciones.length > 0
      ) {
        // onBuscarDetalle();
        var finalArrayNuevo = finalArray.map((object) => ({ ...object }));
        //AGREGGAMOS EL ARRAY
        let result = [];
        let resultCaceberas = flujoCajaState.detalleFlujoCajaCabecerasInicial.map((object) => ({
          ...object,
        })); //[...props.finalArraySinFiltro];
        let lista = [];
        const finalArraySinFiltroCopy = finalArraySinFiltro.map((object) => ({ ...object })); //[...props.finalArraySinFiltro];
        const selectedFilters = {
          posiciones: [],
          caracteristicas: [],
          valores: [],
        };
        // flujoCajaState.aFiltrosDetalleFlujoCaja.forEach((element) => {
        //   element.selectPosicion.forEach((element2) => {
        //     selectedFilters.posiciones.push(element2.IdPosicion);
        //   });
        //   selectedFilters.caracteristicas.push(element.selectCaracteristica);
        //   element.selectValor.forEach((element2) => {
        //     selectedFilters.valores.push(element2);
        //   });
        // });
        flujoCajaState.aFiltrosDetalleFlujoCaja.forEach((element) => {
          element.selectPosicion.forEach((element2) => {
            if (element2.value != '*') {
              selectedFilters.posiciones.push(element2.IdPosicion);
            }
          });
          selectedFilters.caracteristicas.push(element.selectCaracteristica);
          element.selectValor.forEach((element2) => {
            if (element2.value != '*') {
              selectedFilters.valores.push(element2);
            }
          });
        });
        const { posiciones, valores, caracteristicas } = selectedFilters;
        if (posiciones.length === 0 && valores.length === 0 && caracteristicas.length === 0) {
          finalArraySinFiltroCopy.forEach((element) => {
            lista.push({ ...element });
          });
        } else {
          var colectData = collect;
          finalArraySinFiltroCopy.forEach((element) => {
            if (element.IdentificadorCPR === 'C') {
              //
              element.Items.forEach((element2) => {
                element2.Valor = 0;
              });
              lista.push(element);
              // return;
            } else if (element.IdentificadorCPR === 'P') {
              lista.push(element);
            } else if (element.IdentificadorCPR === 'D') {
              lista.push(element);
            } else if (element.IdentificadorCPR === 'R') {
              let datos_reg = [];
              if (posiciones.includes(element.Id_Partida2)) {
                var Id_Partida2 = element.Id_Partida2;
                let datos_reg = element.datos_reg;
                var no_error = false;
                //
                for (let index = 0; index < datos_reg.length; index++) {
                  const element = datos_reg[index];
                  // var aEncontrado = selectedFilters.valores.filter(
                  //   (o) =>
                  //     o.NombreInstancia === element.sDatoPosicion && Id_Partida2 === o.IdPosicion
                  // );
                  // var aEncontrado = selectedFilters.valores.filter(
                  //   (o) => o.NombreInstancia === element.sDatoPosicion && Id_Partida2 === o.IdPosicion
                  // );

                  var aEncontrado = selectedFilters.valores.filter(function (item, index) {
                    return (
                      item.aIdPosicion.filter(function (item2, index2) {
                        return item2 === Id_Partida2;
                      }).length > 0
                    );
                  });

                  if (aEncontrado.length > 0) {
                    no_error = true;
                    break;
                  }
                }
                if (no_error) {
                  lista.push(element);
                }
              } else {
                lista.push(element);
              }
            }
          });
        }
        result = lista;
        resultCaceberas = filterPeridiocidadXParametro(
          flujoCajaState.selectPeridiocidadTableSolucionFC
        );
        result = onRealizarDesdoblesAutomatico(result, resultCaceberas);
        //  if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo != "D"){
        var aListaFinal = [];
        // for (let index = 0; index < resultCaceberas.length; index++) {
        for (var index2 = 0; index2 < result.length; index2++) {
          const element2 = { ...result[index2] };
          const elementCopia = { ...result[index2] };
          // for (let index2 = 0; index2 < result.length; index2++) {
          elementCopia.Items = [];
          if (element2.IdentificadorCPR === 'R') {
            var indexEncontrado = -1;
            element2.Items.find(function (item, i) {
              if (item.EsSplit === 0) {
                indexEncontrado = i;
                return i;
              }
            });
          }
          for (var index = 0; index < resultCaceberas.length; index++) {
            const element = { ...resultCaceberas[index] };
            var acumulador = 0;
            let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;
            var obj = {};
            obj['Id'] = Id + '-' + (index + 1);
            obj['Id2'] = Id + '-' + (index + 1);
            obj['IdPadre'] = Id;
            obj['IdPadre2'] = Id;
            obj['IdHijo'] = Id + '-' + (index + 1);
            obj['IdHijo2'] = Id + '-' + (index + 1);
            obj['IdRegistro'] = index + 1;
            obj['sAccion'] = 'C';
            obj['EsRaiz'] = EsRaiz;
            obj['EsGrupo'] = EsGrupo;
            obj['EsManual'] = EsManual;
            obj['EsSplit'] = EsSplit;
            obj['bEditable'] = bEditable;
            obj['Posicion'] = Posicion;
            obj['Valor'] = 0;
            try {
              if (element2.IdentificadorCPR === 'R') {
                // if(flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo === "D"){
                if (flujoCajaState.selectPeridiocidadTableSolucionFC[0].value === 1) {
                  const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = element2.Items[index];
                  obj = {
                    ...obj,
                    ...{
                      EsGrupo: EsGrupo,
                      EsManual: EsManual,
                      EsRaiz: EsRaiz,
                      EsSplit: EsSplit,
                      bEditable: bEditable,
                    },
                  };
                  obj.Valor =
                    element2.Items[index].Valor *
                    flujoCajaState.factorMonedaTableSolucionFTC[0].valor;
                } else {
                  if (element.desde <= indexEncontrado && indexEncontrado <= element.hasta) {
                    var aEncontrado = element2.Items.filter(function (item) {
                      return item.EsSplit === 0;
                    });
                    if (aEncontrado.length > 0) {
                      const { EsGrupo, EsManual, EsRaiz, EsSplit, bEditable } = aEncontrado[0];
                      obj = {
                        ...obj,
                        ...{
                          EsGrupo: EsGrupo,
                          EsManual: EsManual,
                          EsRaiz: EsRaiz,
                          EsSplit: EsSplit,
                          bEditable: bEditable,
                        },
                      };
                      obj.Valor =
                        aEncontrado[0].Valor * flujoCajaState.factorMonedaTableSolucionFTC[0].valor;
                    }
                  }
                }
              }
            } catch (error) {}
            elementCopia.Items.push(obj);
          }
          aListaFinal.push(elementCopia);
        }
        var rowsInput = [];
        [...aListaFinal].forEach((element) => {
          var obj = { ...element };
          obj.Items = [];
          element.Items.forEach((element2) => {
            obj.Items.push({ ...element2 });
          });
          rowsInput.push(obj);
        });
        var rowsInputSaldo = rowsInput.filter((element) => {
          return element.Id.includes('S');
        });
        var rowsInputIngreso = rowsInput.filter((element) => {
          return element.Id.includes('I');
        });
        var rowsInputEgreso = rowsInput.filter((element) => {
          return element.Id.includes('E');
        });
        realizarRecalculo(rowsInputSaldo, rowsInput, resultCaceberas);
        realizarRecalculo(rowsInputIngreso, rowsInput, resultCaceberas);
        realizarRecalculo(rowsInputEgreso, rowsInput, resultCaceberas);
        var aListaSplitAgrupadaSaldo = rowsInput.filter((element) => {
          return element.Id.includes('S');
        });
        collapseChild('S', aListaSplitAgrupadaSaldo);
        var aListaSplitAgrupadaIngreso = rowsInput.filter((element) => {
          return element.Id.includes('I');
        });
        collapseChild('I', aListaSplitAgrupadaIngreso);
        var aListaSplitAgrupadaEgreso = rowsInput.filter((element) => {
          return element.Id.includes('E');
        });
        collapseChild('E', aListaSplitAgrupadaEgreso);
        let aListaSplitAgrupada = [
          ...aListaSplitAgrupadaSaldo,
          ...aListaSplitAgrupadaIngreso,
          ...aListaSplitAgrupadaEgreso,
        ];
        let posi = aListaSplitAgrupada.length;
        let aListaSoluciones = flujoCajaState.selectListaAgrupadaXSoluciones[0].aListaSoluciones;
        var aListaSolucionesSumarizada = [];
        // for (let index = 0; index < resultCaceberas.length; index++) {

        var responseObtenerPaletaColoresXSolucion = await callEndpoint(
          obtenerPaletaColoresXSolucion({})
        );

        for (var index2 = 0; index2 < aListaSoluciones.length; index2++) {
          const element2 = { ...aListaSoluciones[index2] };
          const elementCopia = { ...aListaSoluciones[index2] };
          // for (let index2 = 0; index2 < result.length; index2++) {
          elementCopia.Items = [];

          const HexColor =
            responseObtenerPaletaColoresXSolucion.data.respuesta.aRegPaletaC_X_Solucion.filter(
              function (item, index) {
                return item.CodTipoSolucion === elementCopia.CodigoSolucion;
              }
            )[0].HexColor;
          elementCopia.HexColor = HexColor;

          for (var index = 0; index < resultCaceberas.length; index++) {
            const element = { ...resultCaceberas[index] };
            var acumulador = 0;
            let { Id, EsGrupo, EsManual, EsRaiz, EsSplit, bEditable, Posicion } = element2;
            var obj = {};
            obj['Id'] = Id + '-' + (index + 1);
            obj['Id2'] = Id + '-' + (index + 1);
            obj['IdPadre'] = Id;
            obj['IdPadre2'] = Id;
            obj['IdHijo'] = Id + '-' + (index + 1);
            obj['IdHijo2'] = Id + '-' + (index + 1);
            obj['IdRegistro'] = index + 1;
            obj['sAccion'] = 'C';
            obj['EsRaiz'] = EsRaiz;
            obj['EsGrupo'] = EsGrupo;
            obj['EsManual'] = EsManual;
            obj['EsSplit'] = EsSplit;
            obj['bEditable'] = bEditable;
            obj['Posicion'] = Posicion;
            obj['Valor'] = 0;
            try {
              if (flujoCajaState.selectPeridiocidadTableSolucionFC[0].value === 1) {
                obj.Valor =
                  element2.Items[index].Valor *
                  flujoCajaState.factorMonedaTableSolucionFTC[0].valor;
              } else {
                for (
                  let indexRecorrido = element.desde;
                  indexRecorrido <= element.hasta;
                  indexRecorrido++
                ) {
                  obj['Valor'] = element2.Items[indexRecorrido].Valor + obj['Valor'];
                }
                obj['Valor'] = obj['Valor'] * flujoCajaState.factorMonedaTableSolucionFTC[0].valor;
              }
            } catch (error) {}
            elementCopia.Items.push(obj);
          }
          aListaSolucionesSumarizada.push(elementCopia);
        }
        for (let index = 0; index < aListaSolucionesSumarizada.length; index++) {
          let element = { ...aListaSolucionesSumarizada[index] };
          element['collapseIcon'] = false;
          element['collapse'] = false;
          //  aListaSplitAgrupada.splice(posi, 0, element);
          posi++;
        }
        // calcularTotalYsubTotal(aListaSplitAgrupada,resultCaceberas)
        // realizarRecalculoSubTotal_Total(aListaSplitAgrupada,resultCaceberas)
        // setFinalArray(aListaSplitAgrupada);
        dispatch(
          onSetDetalleListaAgrupadaXSoluciones({
            detalleListaAgrupadaXSoluciones: aListaSplitAgrupada,
          })
        );
        dispatch(
          onSetListaAgrupadaXSolucionesSumarizada({
            aListaSolucionesSumarizada: aListaSolucionesSumarizada,
          })
        );
        var detalleFlujoCajaCabeceras = resultCaceberas;
        var objecto = {};
        objecto.EsRaiz = 1;
        objecto.Id = 'Total Acumulado';
        objecto.sNombre = 'Total Acumulado';
        objecto.Items = [];
        var objectoParcial = {};
        objectoParcial.EsRaiz = 1;
        objectoParcial.Id = 'Total Periodo';
        objectoParcial.sNombre = 'Total Periodo';
        objectoParcial.Items = [];
        var aEncontradoGruposRaiz = aListaSplitAgrupada.filter((element) => {
          return element.EsGrupo === 1 && element.EsRaiz === 1;
        });
        for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
          var fAcumulado = 0;
          var item = {
            Id: objecto.Id + '-' + index,
            Id_Movimiento: null,
            EsRaiz: 1,
            EsGrupo: 1,
            Valor: 0,
          };
          for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
            const element = aEncontradoGruposRaiz[w];
            // if (element.Posicion === 'Egreso') {
            //   fAcumulado = fAcumulado - element.Items[index].Valor;
            // } else {
            //   fAcumulado = fAcumulado + element.Items[index].Valor;
            // }
            fAcumulado = fAcumulado + element.Items[index].Valor;
          }
          for (let ss = 0; ss < aListaSolucionesSumarizada.length; ss++) {
            const element = aListaSolucionesSumarizada[ss];
            fAcumulado = fAcumulado + element.Items[index].Valor;
          }
          item.Valor = fAcumulado;
          objecto.Items.push(item);
          objectoParcial.Items.push({ ...item });
        }
        // props.setFinalArrayTotalParciales(objectoParcial);
        for (let index = 0; index < objecto.Items.length; index++) {
          const element = objecto.Items[index - 1];
          if (!element) {
            objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
          } else {
            objecto.Items[index].Valor =
              objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
          }
        }
        dispatch(
          onSetListaAgrupadaXSolucionesParcial({
            detalleListaAgrupadaXSolucionesParcial: objectoParcial,
          })
        );
        dispatch(
          onSetListaAgrupadaXSolucionesTotal({ detalleListaAgrupadaXSolucionesTotal: objecto })
        );
      }
    })();
  }, [flujoCajaState.selectListaAgrupadaXSoluciones]);

  const onObtenerDetalleFlujoCaja = async () => {
    dispatch(updateTitle({ title: 'Visualizar Flujo Caja' }));
    // para descargar la data de un archivo
    // let obtenerDetalleFlujoCajaGeneralResponse = {};
    //
    // const urAlrchivo = await obtenerUrlImg('1/jsonPreOpt/466');
    // await fetch(urAlrchivo)
    //   .then((response) => response.text())
    //   .then((data) => {
    //
    //     try {
    //       const jsonData = JSON.parse(data);
    //       console.log('JSON válido:', jsonData);
    //     } catch (error) {
    //       console.error('Error al parsear JSON:', error.message);
    //       console.log('Contenido del archivo:', data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Error al descargar o procesar el archivo:', error);
    //   });
    var obtenerDetalleFlujoCajaGeneralResponse = await callEndpoint(
      obtenerDetalleFlujoCajaGeneral({
        oIDFlujoCajaGeneral: {
          IDFlujoCajaGeneral: id,
        },
      })
    );
    if (obtenerDetalleFlujoCajaGeneralResponse != null) {
      if (obtenerDetalleFlujoCajaGeneralResponse.data) {
        let aListaEstructuras = [];
        var { data } = await callEndpoint(obtenerEstructurasBase());
        data.respuesta.aListaEstructuras.forEach(function (item, index) {
          var o = createEstructuraBaseAdapter(item);
          aListaEstructuras.push(o);
        });
        dispatch(onSetEstructurasBaseCrearFlujoCaja(aListaEstructuras));
        let aListaPeriodo = [];
        var { data } = await callEndpoint(obtenerListadoPeriodo());
        data.respuesta.aListaPeriodo.forEach(function (item, index) {
          var o = createPeriodoAdapter(item);
          aListaPeriodo.push(o);
        });
        dispatch(onSetPeriodoCrearFlujoCaja(aListaPeriodo));
        let tipoMoneda = personalizacionState.listaPersonalizada.filter(
          (v) => v.DescPersonalizacion === 'texto_moneda'
        )[0].DatoPersonalizacion;
        let aListaMoneda = [];
        var { data } = await callEndpoint(obtenerListadoMoneda());
        data.respuesta.aListaMoneda.forEach(function (item, index) {
          var o = createMonedaAdapter(item, tipoMoneda);
          aListaMoneda.push(o);
        });
        dispatch(onSetMonedasCrearFlujoCaja(aListaMoneda));
        var { data } = await callEndpoint(obtenerListadoTC());
        let aListaTC = [];
        data.respuesta.aListaTC.forEach(function (item, index) {
          var o = createMonedaAdapter(item, tipoMoneda);
          aListaTC.push(o);
        });

        dispatch(onSetTipoCambioFlujoCaja({ aListaTC: aListaTC }));
        await loadEtiquetasM();
        var {
          IDFlujoCajaGeneral,
          iIdMoneda,
          sMoneda,
          sFiIdPeridiocidadechaFin,
          sPeriodo,
          sFechaFin,
          sFechaInicio,
          sNombreVersion,
          sIdEstructura,
          sEstructura,
          iIdtiempoProyeccion,
          sTiempoProyeccion,
          aListaCabecera,
          aListaDetalle,
          aDesdobleFinal,
          aFiltrosDetalleFlujoCaja,
          aListaSplitAgrupada,
          aListaParcialAgrupada,
          aListaTotalAgrupada,
          aListaCaracAgrupada,
          aListaSoluciones,
          aListaAgrupadaXSoluciones,
          selectEtiquetaFlujoCaja,
          listarEtiquetasFlujoCaja,
          selectSolucionesModalFlujoCaja,
          aRestriccionesDetalleFlujoCaja,
          IDOptimizacion,
        } = obtenerDetalleFlujoCajaGeneralResponse.data.respuesta;
        dispatch(onSetDetalleFlujoCajaCabeceras(aListaCabecera));
        dispatch(
          onSetDetalleFlujoCajaCabecerasInicial({
            detalleFlujoCajaCabecerasInicial: aListaCabecera,
          })
        );
        setSelectedDesdobles(
          [...aDesdobleFinal].map(function (item, index) {
            item.expanded = false;
            return item;
          })
        );
        var aListaSplitAgrupadaSaldo = aListaSplitAgrupada.filter((element) => {
          return element.Id.includes('S');
        });
        var aListaSplitAgrupadaIngreso = aListaSplitAgrupada.filter((element) => {
          return element.Id.includes('I');
        });
        var aListaSplitAgrupadaEgreso = aListaSplitAgrupada.filter((element) => {
          return element.Id.includes('E');
        });
        var finalArraySinFiltro = [
          ...aListaSplitAgrupadaSaldo,
          ...aListaSplitAgrupadaIngreso,
          ...aListaSplitAgrupadaEgreso,
        ];
        const selectObtPeriodo = aListaPeriodo.filter((f) => f.sCodFechaPeriodo === sPeriodo);

        const selectObtMoneda = aListaMoneda.filter((f) => f.sAbreviatura === sMoneda);

        dispatch(
          onSelectPeridiocidadTableSolucionFC({
            selectPeridiocidadTableSolucionFC: selectObtPeriodo,
          })
        );
        dispatch(
          onSelectMonedaTableFC({
            selectMonedaTableFC: selectObtMoneda,
            selectMonedaTableSolucionFC: selectObtMoneda,
          })
        );

        dispatch(
          onSelectPeridiocidadTableFC({
            selectPeridiocidadTableFC: selectObtPeriodo,
          })
        );

        setFinalArray(finalArraySinFiltro);
        setFinalArraySinFiltro(finalArraySinFiltro);
        setFinalArrayTotal(aListaTotalAgrupada[0]);
        setFinalArrayTotalParciales(aListaParcialAgrupada[0]);
        setFinalArrayCaracAgrupada(aListaCaracAgrupada);
        dispatch(onSetDetalleFlujoCajaCabeceras(aListaCabecera));

        var oParam = {
          ...{ valor: 1 },
          ...{
            iIDTipoMonedaInicial: iIdMoneda,
            iIDTipoMonedaFinal: iIdMoneda,
          },
        };
        dispatch(
          onSetFactorMonedaTableFC(
            {
              factorMonedaTableTC: [oParam],
            },
            {
              factorMonedaTableSolucionFTC: [oParam],
            }
          )
        );
        dispatch(
          onSetFiltroDetalleFlujoCaja({ aFiltrosDetalleFlujoCaja: aFiltrosDetalleFlujoCaja })
        );
        await loadSoluciones();
        await loadEstado();
        dispatch(
          loadVisualizarConceptoFC({
            IDFlujoCajaGeneral: id,
            sVersion: sNombreVersion,
            dDateRange: [
              stringFechaToDateWithOutTime(sFechaInicio, '-'),
              stringFechaToDateWithOutTime(sFechaFin, '-'),
            ],
            bValidFecha: true,
            iIdEstructura: sIdEstructura,
            sEstructura: sEstructura,
            iIdtiempoProyeccion: iIdtiempoProyeccion === 0 ? null : iIdtiempoProyeccion,
            sTiempoProyeccion: sTiempoProyeccion,
            iIdMoneda: iIdMoneda,
            sMoneda: sMoneda,
            iIdFechaPeriodo: sFiIdPeridiocidadechaFin,
            sFechaPeriodo: sPeriodo,
            selectEtiquetaFlujoCaja: selectEtiquetaFlujoCaja,
            listarEtiquetasFlujoCaja: listarEtiquetasFlujoCaja,
            aRestriccionesDetalleFlujoCaja: aRestriccionesDetalleFlujoCaja,
          })
        );
        dispatch(
          onSetListaSoluciones({
            aListaSoluciones: aListaSoluciones,
            aListaAgrupadaXSoluciones: aListaAgrupadaXSoluciones,
          })
        );
        dispatch(onSetOptimizador({ idOptimiacion: IDOptimizacion }));
        dispatch(
          onSetListaModalSolFlujoCaja({ listaModalSolFlujoCaja: selectSolucionesModalFlujoCaja })
        );
        dispatch(
          onSetFilterListaModalSolFlujoCaja({
            filterListaModalSolFlujoCaja: selectSolucionesModalFlujoCaja,
          })
        );
      }
    }
    setLoadingDetalle(false);
  };

  const handleChangeEstructuraBaseCrearFlujoCaja = (oEvent) => {
    var oParam = {};
    oParam.iIdEstructura = null;
    oParam.sEstructura = '';
    oParam.validate = null;
    if (oEvent) {
      oParam.iIdEstructura = oEvent.value;
      oParam.sEstructura = oEvent.label;
      oParam.validate = true;
      dispatch(onChangeEstructuraCrearFlujoCaja(oParam));
    } else {
      dispatch(onChangeEstructuraCrearFlujoCaja(oParam));
    }
  };
  const handleChangeFechaPeriodoCrearFlujoCaja = (oEvent) => {
    var oParam = {};
    oParam.iIdFechaPeriodo = null;
    oParam.sFechaPeriodo = '';
    oParam.sCodigoPeriodo = '';
    oParam.validate = null;
    if (oEvent) {
      oParam.iIdFechaPeriodo = oEvent.value;
      oParam.sCodFechaPeriodo = oEvent.sCodFechaPeriodo;
      oParam.sFechaPeriodo = oEvent.label;
      oParam.validate = true;
      dispatch(onChangeFechaPeriodoCrearFlujoCaja(oParam));
    } else {
      dispatch(onChangeFechaPeriodoCrearFlujoCaja(oParam));
    }
  };
  const handleChangePeridiocidadTableFC = (oEvent) => {
    var oParam = {};
    oParam.iIdFechaPeriodo = oEvent.value;
    oParam.sCodFechaPeriodo = oEvent.sCodFechaPeriodo;
    oParam.sFechaPeriodo = oEvent.label;
    oParam.validate = true;
    oParam = { ...oParam, ...oEvent };
    dispatch(onSelectPeridiocidadTableFC({ selectPeridiocidadTableFC: [oParam] }));
  };
  const handleChangeMonedaTableFC = (oEvent) => {
    var oParam = {};
    oParam.validate = true;
    oParam = { ...oParam, ...oEvent };

    // let quiero = flujoCajaState.aListaTC.filter(function (item, index) {
    //   return item.iIDTipoMoneda === oEvent.iIDTipoMoneda;
    // })[0].fImporte;
    // let tengo = flujoCajaState.aListaTC.filter(function (item, index) {
    //   return item.iIDTipoMoneda === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial; //flujoCajaState.selectMonedaTableFC[0].iIDTipoMonedaInicial
    // })[0].fImporte;
    // let factorMonedaTableTC = quiero / tengo;

    let findFactorTC = flujoCajaState.aListaTC.filter(function (item, index) {
      return (
        item.MonedaBase === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial &&
        item.NombreMoneda === oEvent.sAbreviatura
      );
    });

    let factorMonedaTableTC = 0;
    if (findFactorTC.length > 0) {
      factorMonedaTableTC = findFactorTC[0].Factor * findFactorTC[0].Importe;
    } else {
      let findFactorTCRe = flujoCajaState.aListaTC.filter(function (item, index) {
        return (
          item.NombreMoneda === flujoCajaState.factorMonedaTableTC[0].iIDTipoMonedaInicial &&
          item.MonedaBase === oEvent.sAbreviatura
        );
      });
      factorMonedaTableTC =
        findFactorTCRe[0].Factor / (findFactorTCRe[0].Factor * findFactorTCRe[0].Importe);
    }

    var oParamFactor = {
      iIDTipoMonedaInicial: flujoCajaState.selectMonedaTableFCInicial[0].sAbreviatura,
      iIDTipoMonedaFinal: oEvent.sAbreviatura,
      valor: factorMonedaTableTC,
    };
    dispatch(
      onSelectMonedaTableFC({
        factorMonedaTableTC: [oParamFactor],
        selectMonedaTableFC: [oParam],
      })
    );
  };
  const handleChangeMonedaCrearFlujoCaja = (oEvent) => {
    var oParam = {};
    oParam.iIdMoneda = null;
    oParam.sMoneda = '';
    oParam.validate = null;
    if (oEvent) {
      oParam.iIdMoneda = oEvent.value;
      oParam.sCodMoneda = oEvent.sCodMoneda;
      oParam.sMoneda = oEvent.label;
      oParam.sAbreviatura = oEvent.sAbreviatura;
      oParam.validate = true;
      dispatch(onChangeMonedaCrearFlujoCaja(oParam));
    } else {
      dispatch(onChangeMonedaCrearFlujoCaja(oParam));
    }
  };
  const handleChangeFechaCrearFlujoCaja = (oEvent) => {
    var oParam = {
      dFechaInicio: oEvent[0],
      dFechaFin: oEvent[1],
    };
    var sFechaInicio = '';
    var sFechaFinal = '';
    var sDia = '';
    var sMes = '';
    var sAnio = '';
    if (oParam.dFechaInicio != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      var iDia = oParam.dFechaInicio.getDate();
      var iMes = oParam.dFechaInicio.getMonth() + 1;
      var iAnio = oParam.dFechaInicio.getFullYear();
      if (oParam.dFechaInicio.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaInicio = sDia + '/' + sMes + '/' + sAnio;
    }
    if (oParam.dFechaFin != null) {
      sDia = '';
      sMes = '';
      sAnio = '';
      let iDia = oParam.dFechaFin.getDate();
      let iMes = oParam.dFechaFin.getMonth() + 1;
      let iAnio = oParam.dFechaFin.getFullYear();
      if (oParam.dFechaFin.getDate() < 10) {
        sDia = '0' + iDia;
      } else {
        sDia = iDia.toString();
      }
      if (iMes < 10) {
        sMes = '0' + iMes;
      } else {
        sMes = iMes.toString();
      }
      sAnio = iAnio;
      sFechaFinal = sDia + '/' + sMes + '/' + sAnio;
    }
    oParam.sFechaInicio = sFechaInicio;
    oParam.sFechaFinal = sFechaFinal;
    dispatch(onChangeFechaCrearFlujoCaja(oParam));
  };
  const onPressGuardarEdicionTabla = (objRightClick) => {
    const rowsInput = [...finalArray];
    rowsInput[objRightClick.indexPadre].Items.forEach((element) => {
      if (element.Id === objRightClick.item2.Id) {
        element.bEditable = false;
      }
    });
    setFinalArray(rowsInput);
    setEditTable(!editTable);
  };
  const openModalFiltros = async () => {
    let selectionFiltrosModel = [];
    for (let index = 0; index < flujoCajaState.aFiltrosDetalleFlujoCaja.length; index++) {
      const element = { ...flujoCajaState.aFiltrosDetalleFlujoCaja[index] };
      selectionFiltrosModel.push(element);
    }
    setSelectionFiltrosModel(selectionFiltrosModel);
    dispatch(onOpenModalFiltrosDetalleFlujoCaja(true));
  };
  const openModalRestricciones = () => {
    dispatch(onOpenModalRestriccionesDetalleFlujoCaja(true));
  };
  const openModalSoluciones = () => {
    dispatch(onOpenModalSolucionesDetalleFlujoCaja({ modalSolucionesFlujoCaja: true }));
  };
  const onGuardarCambios = async (oEvent) => {
    let oParam = {
      listaSucesosCreados: flujoCajaState.listaSucesosCreados,
      listaSucesosModificados: flujoCajaState.listaSucesosModificados,
      listaSucesosEliminados: flujoCajaState.listaSucesosEliminados,
    };
    var responseGuardarRegistrosFlujoCaja = await callEndpoint(guardarRegistrosFlujoCaja(oParam));
    if (responseGuardarRegistrosFlujoCaja === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseGuardarRegistrosFlujoCaja.data.cabecera.statusCode === 200) {
        dispatch(
          onLimpiarCamposFlujoCaja({
            listaSucesosCreados: [],
            listaSucesosModificados: [],
            listaSucesosEliminados: [],
          })
        );
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: responseGuardarRegistrosFlujoCaja.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: responseGuardarRegistrosFlujoCaja.data.cabecera.descripcion,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
    const aRegistroModificados = finalArray.filter((element) => {
      return element.sAccion === 'M'; //&& element.IdentificadorCPR === "R";
    });
  };
  const onGuardarVersion = async (oEvent, finalArrayEliminar) => {
    var bValidForm = validarCampos();
    if (bValidForm) {
      var updateVersion = true;
      if (flujoCajaState.selectVersionFlujoCaja.length === 0) {
        updateVersion = false;
      }
      Swal.fire({
        title: !updateVersion ? '¿Desea guardar la versión?' : '¿Desea sobreescribir la versión?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          var ListaEtiquetas = '';
          flujoCajaState.selectEtiquetaFlujoCaja.forEach((element, index) => {
            if (index === 0) {
              ListaEtiquetas = element.IDEtiqueta.toString();
            } else {
              ListaEtiquetas = ListaEtiquetas + ',' + element.IDEtiqueta.toString();
            }
          });
          if (!updateVersion) {
            var oParam = {
              RegistroVersionFlujoCaja: {
                sNombreVersion: flujoCajaState.sVersion, //"NombreVersion",
                iIdEstructuraBase: flujoCajaState.iIdEstructura, //1,
                iProyeccion: flujoCajaState.iProyeccion,
                iIdtiempoProyeccion: flujoCajaState.iIdtiempoProyeccion,
                sTiempoProyeccion: flujoCajaState.sTiempoProyeccion,
                sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'), //flujoCajaState.sFechaInicio,
                sFechaFin: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[1], '/'), //flujoCajaState.sFechaFinal,
                iIdPeridiocidad: flujoCajaState.iIdFechaPeriodo, // 1
                iIdMoneda: flujoCajaState.iIdMoneda, // 1
                aFiltros: [],
                aReglas: [],
                aSoluciones: [],
                ListaEtiquetas: ListaEtiquetas,
              },
            };
            var responseCrearVersionFlujoCaja = await callEndpoint(crearVersionFlujoCaja(oParam));
            if (responseCrearVersionFlujoCaja === null) {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Ocurrió un error en el servicio.',
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              if (responseCrearVersionFlujoCaja.data.cabecera.statusCode === 200) {
                var oParamSelectVersionFlujoCaja = {};
                oParamSelectVersionFlujoCaja.selectVersionFlujoCaja = [
                  {
                    value:
                      responseCrearVersionFlujoCaja.data.respuesta.RegistroVersionFlujoCaja
                        .idVersion,
                    label: flujoCajaState.sVersion,
                  },
                ];
                oParamSelectVersionFlujoCaja.bValidVersionFlujoCaja = true;
                dispatch(onSelectVersionFlujoCaja(oParamSelectVersionFlujoCaja));
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: responseCrearVersionFlujoCaja.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: responseCrearVersionFlujoCaja.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }
          } else {
            var oParamUpdateVersionFlujoCaja = {
              UpdateVersionFlujoCaja: {
                iIdVersion: flujoCajaState.selectVersionFlujoCaja[0].value,
                sNombreVersion: flujoCajaState.sVersion, //"NombreVersion",
                iIdEstructuraBase: flujoCajaState.iIdEstructura, //1,
                iProyeccion: flujoCajaState.iProyeccion,
                iIdtiempoProyeccion: flujoCajaState.iIdtiempoProyeccion,
                sTiempoProyeccion: flujoCajaState.sTiempoProyeccion,
                sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'), //flujoCajaState.sFechaInicio,
                sFechaFin: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[1], '/'), //flujoCajaState.sFechaFinal,
                iIdPeridiocidad: flujoCajaState.iIdFechaPeriodo, // 1
                iIdMoneda: flujoCajaState.iIdMoneda, // 1
                aFiltros: [],
                aReglas: [],
                aSoluciones: [],
                ListaEtiquetas: ListaEtiquetas,
              },
            };
            var responseUpdateVersionFlujoCaja = await callEndpoint(
              updateVerionFlujoCaja(oParamUpdateVersionFlujoCaja)
            );
            if (responseUpdateVersionFlujoCaja === null) {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Ocurrió un error en el servicio.',
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              if (responseUpdateVersionFlujoCaja.data.cabecera.statusCode === 200) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: responseUpdateVersionFlujoCaja.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
                // const aRegistroModificados = finalArray.filter((element) => {
                //   return element.sAccion === "M" && element.IdentificadorCPR === "R";
                // });
                // const aRegistroNuevos = finalArray.filter((element) => {
                //   return element.sAccion === "N" && element.IdentificadorCPR === "R"
                // });
                // const aRegistroEliminados = finalArrayEliminar;
                // let aMovimientosModif = [];
                // let aMovimientosNuevos = []
                // let aMovimientosEliminados = [];
                // aRegistroModificados.forEach(element => {
                //   let { Id_Partida2, Id_Partida3, sFecha, sAccion, Items, posiCabecera } = element;
                //   let obj = {}
                //   obj.Id_Partida2 = Id_Partida2
                //   obj.Id_Partida3 = Id_Partida3;
                //   obj.sFecha = sFecha;
                //   obj.sAccion = sAccion;
                //   obj.Valor = Items[posiCabecera].Valor;
                //   aMovimientosModif.push(obj)
                // });
                // aRegistroEliminados.forEach(element => {
                //   let { Id_Partida1, Id_Partida2, Id_Partida3, sAccion } = element;
                //   let obj = {}
                //   obj.Id_Partida1 = Id_Partida1
                //   obj.Id_Partida2 = Id_Partida2
                //   obj.Id_Partida3 = Id_Partida3;
                //   obj.sAccion = sAccion;
                //   aMovimientosEliminados.push(obj)
                // });
                // var oParam = {
                //   "aMovimientosModif": aMovimientosModif,
                //   "aMovimientosNuevos": aMovimientosNuevos,
                //   "aMovimientosEliminados": aMovimientosEliminados
                // }
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: responseCrearVersionFlujoCaja.data.cabecera.descripcion,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }
          }
        }
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'Por favor, completar la versión, estructura , fecha , periodicidad y moneda del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const onPressBuscarVersion = async () => {
    let aListaVersiones = [];
    var { data } = await callEndpoint(obtenerVersionesFlujoCaja());
    data.respuesta.aListaVersiones.forEach(function (item, index) {
      var o = createVersionFlujoCajaAdapter(item);
      aListaVersiones.push(o);
    });
    dispatch(onOpenModalVersionFlujoCaja(true));
    dispatch(onSetListaVersionesFlujoCaja(aListaVersiones));
  };
  const realizarBusqueda = async () => {
    dispatch(onSetTabProceso({ tabProceso: 0 }));
    setFinalArray([]);
    setFinalArraySinFiltro([]);
    setSelectedDesdobles([]);
    dispatch(changeSelectListaSoluciones({ selectListaSoluciones: [] }));
    dispatch(collapseDesdobles({ collapseDesdobles: null }));
    dispatch(onLimpiarFiltroDetalleFlujoCaja([]));
    dispatch(onLimpiarRestriccionDetalleFlujoCaja([]));
    dispatch(
      onSetListaSoluciones({
        aListaSoluciones: [],
        selectListaSoluciones: [],
        aListaAgrupadaXSoluciones: [],
        selectListaAgrupadaXSoluciones: [],
        detalleListaAgrupadaXSoluciones: [],
        detalleListaAgrupadaXSolucionesTotal: [],
        detalleListaAgrupadaXSolucionesParcial: [],
      })
    );
    dispatch(
      limpiarSolucionesFlujoCaja({
        idOptimiacion: null,
        modalSolucionesFlujoCaja: false,
        listaModalSolFlujoCaja: [],
        filterListaModalSolFlujoCaja: [],
        searchModalSolSearchFlujoCaja: '',
        // tipoSolucionesFlujoCaja : [],
        selectTipoEstadoSolFlujoCaja: [],
        selectTipoSoluFlujocaja: [],
        selectSolucionesModalFlujoCaja: [],
        dDateVigenciaSoluFlujoCaja: [null, null],
        bValidDateVigenciaSoluFlujoCaja: null,
      })
    );
    dispatch(
      onLimpiarCamposCrearFlujoCaja({
        listaSucesosCreados: [],
        listaSucesosEliminados: [],
        listaSucesosModificados: [],
      })
    );
    // var sFechaInicio = formatString_DD_MM_YYYY_TO_YYYY_MM_DD(
    //   flujoCajaState.sFechaInicio
    // );
    // var sFechaFinal = formatString_DD_MM_YYYY_TO_YYYY_MM_DD(
    //   flujoCajaState.sFechaFinal
    // );
    var sFechaInicio = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[0], '-');
    var sFechaFinal = dateTo_yyyy_mm_dd(flujoCajaState.dDateRange[1], '-');
    var ListaEtiquetas = '';
    if (flujoCajaState.selectEtiquetaFlujoCaja.length > 0) {
      flujoCajaState.selectEtiquetaFlujoCaja.forEach((element, index) => {
        if (index === 0) {
          ListaEtiquetas = element.value.toString();
        } else {
          ListaEtiquetas = ListaEtiquetas + ',' + element.value.toString();
        }
      });
    }
    var { data } = await callEndpoint(
      obtenerSplitRegistros({
        sFechaInicio: sFechaInicio,
        sFechaFin: sFechaFinal,
        sPeriodo: 'D', //flujoCajaState.sCodFechaPeriodo, //"M",
        sMoneda: flujoCajaState.sAbreviatura, //"USD",
        sIdEstructura: flujoCajaState.iIdEstructura, //1,
        ListaEtiquetas: ListaEtiquetas,
      })
    );
    var {
      aListaCabecera,
      aListaSplitAgrupada,
      aListaParcialAgrupada,
      aListaTotalAgrupada,
      aListaCaracAgrupada,
    } = data.respuesta;
    dispatch(onSetDetalleFlujoCajaCabeceras(aListaCabecera));
    dispatch(
      onSetDetalleFlujoCajaCabecerasInicial({ detalleFlujoCajaCabecerasInicial: aListaCabecera })
    );
    // setFinalArray([...aListaSplitAgrupada]);
    // setFinalArraySinFiltro([...aListaSplitAgrupada]);
    var aListaSplitAgrupadaSaldo = aListaSplitAgrupada.filter((element) => {
      return element.Id.includes('S');
    });
    var aListaSplitAgrupadaIngreso = aListaSplitAgrupada.filter((element) => {
      return element.Id.includes('I');
    });
    var aListaSplitAgrupadaEgreso = aListaSplitAgrupada.filter((element) => {
      return element.Id.includes('E');
    });
    var finalArraySinFiltro = [
      ...aListaSplitAgrupadaSaldo,
      ...aListaSplitAgrupadaIngreso,
      ...aListaSplitAgrupadaEgreso,
    ];

    setFinalArray(finalArraySinFiltro);
    setFinalArraySinFiltro(finalArraySinFiltro);
    setFinalArrayTotal(aListaTotalAgrupada[0]);
    setFinalArrayTotalParciales(aListaParcialAgrupada[0]);
    setFinalArrayCaracAgrupada(aListaCaracAgrupada);
    dispatch(onSetDetalleFlujoCajaCabeceras(aListaCabecera));
    dispatch(
      onSelectMonedaTableFC({
        selectMonedaTableFC: [
          {
            value: flujoCajaState.iIdMoneda,
            label: flujoCajaState.sMoneda,
          },
        ],
      })
    );
    dispatch(
      onSelectPeridiocidadTableFC({
        selectPeridiocidadTableFC: [
          {
            value: flujoCajaState.iIdFechaPeriodo,
            label: flujoCajaState.sFechaPeriodo,
          },
        ],
      })
    );
    var oParam = {
      ...flujoCajaState.factorMonedaTableTC[0],
      ...{
        iIDTipoMonedaInicial: flujoCajaState.iIdMoneda,
        iIDTipoMonedaFinal: flujoCajaState.iIdMoneda,
      },
    };
    dispatch(
      onSetFactorMonedaTableFC({
        factorMonedaTableTC: [oParam],
      })
    );
    setSelectedDesdobles(
      [...data.respuesta.aDesdobleFinal].map(function (item, index) {
        item.expanded = false;
        return item;
      })
    );
    //   let selectedDesdobles = [...data.respuesta.aDesdobleFinal];
    //   let finalArrayDesdoblado = onRealizarDesdoblesAutomaticoMejorado(finalArraySinFiltro,selectedDesdobles)
    //     let lista = [];
    //     const finalArraySinFiltroCopy = finalArrayDesdoblado.map(object => ({ ...object }))//[...props.finalArraySinFiltro];
    //     const selectedFilters = {
    //       posiciones: [],
    //       caracteristicas: [],
    //       valores: []
    //     };
    //     flujoCajaState.aFiltrosDetalleFlujoCaja.forEach(element => {
    //       element.selectPosicion.forEach(element2 => {
    //         selectedFilters.posiciones.push(element2.IdPosicion)
    //       });
    //       // element.selectCaracteristica.forEach(element2 => {
    //       selectedFilters.caracteristicas.push(element.selectCaracteristica)
    //       //  });
    //       element.selectValor.forEach(element2 => {
    //         selectedFilters.valores.push(element2)
    //       });
    //     });
    //     const { posiciones, valores, caracteristicas } = selectedFilters;
    //     if (posiciones.length === 0 && valores.length === 0 && caracteristicas.length === 0) {
    //       finalArraySinFiltroCopy.forEach(element => {
    //         lista.push({...element})
    //       });
    //     } else {
    //       var colectData = collect;
    //       finalArraySinFiltroCopy.forEach(element => {
    //         if (element.IdentificadorCPR === "C") {
    //           //
    //           element.Items.forEach(element2 => {
    //             element2.Valor = 0;
    //           });
    //           lista.push(element)
    //           // return;
    //         } else if (element.IdentificadorCPR === "P") {
    //           lista.push(element)
    //         } else if (element.IdentificadorCPR === "D") {
    //           lista.push(element)
    //         }else if(element.IdentificadorCPR === "R"){
    //           let datos_reg = [];
    //         //
    //           if(posiciones.includes(element.Id_Partida2)){
    //             // var test = colectData( element.datos_reg).whereIn('price', caracteristicas);
    //             var Id_Partida2 = element.Id_Partida2;
    //             let datos_reg = element.datos_reg;
    //             var no_error = false;
    // //
    //             for (let index = 0; index < datos_reg.length; index++) {
    //               const element = datos_reg[index];
    //               var aEncontrado = selectedFilters.valores.filter((o) => o.NombreInstancia === element.sDatoPosicion && Id_Partida2 === o.IdPosicion);
    //               if(aEncontrado.length>0){
    //                 no_error = true;
    //                 break;
    //               }
    //             }
    //             if(no_error){
    //               lista.push(element)
    //             }
    //           }else{
    //             lista.push(element)
    //           }
    //         }
    //       });
    //     }
    //     var listaFinal = onRealizarDesdoblesAutomatico(lista);
    //
    //     var aListaSplitAgrupadaSaldo = listaFinal.filter((element) => {
    //       return element.Id.includes("S");
    //     });
    //     collapseChild("S",aListaSplitAgrupadaSaldo)
    //     var aListaSplitAgrupadaIngreso =listaFinal.filter((element) => {
    //       return element.Id.includes("I");
    //     });
    //     collapseChild("I",aListaSplitAgrupadaIngreso)
    //     var aListaSplitAgrupadaEgreso = listaFinal.filter((element) => {
    //       return element.Id.includes("E");
    //     });
    //     collapseChild("E",aListaSplitAgrupadaEgreso)
    //     aListaSplitAgrupada= [...aListaSplitAgrupadaSaldo, ...aListaSplitAgrupadaIngreso , ...aListaSplitAgrupadaEgreso]
    //     setFinalArray(aListaSplitAgrupada);
    await loadSoluciones();
    await loadEstado();
  };
  const loadSoluciones = async () => {
    const responseListaLineaCredito = await callEndpoint(
      obtenerSoluciones({
        oTipoSolucion: ['LC', 'PR', 'FC', 'AP', 'IN', 'TC'],
      })
    );
    var listaModalSolFlujoCaja = [];
    if (responseListaLineaCredito != null) {
      if (responseListaLineaCredito.data) {
        if (responseListaLineaCredito.data.cabecera.statusCode === 200) {
          for (
            let index = 0;
            index < responseListaLineaCredito.data.respuesta.aListaSolucion.length;
            index++
          ) {
            const element = responseListaLineaCredito.data.respuesta.aListaSolucion[index];
            var obj = createSolucionModalFlujoCajaAdapter(element);
            listaModalSolFlujoCaja.push(obj);
          }
        }
      }
    }
    dispatch(onSetListaModalSolFlujoCaja({ listaModalSolFlujoCaja: listaModalSolFlujoCaja }));
    dispatch(
      onSetFilterListaModalSolFlujoCaja({ filterListaModalSolFlujoCaja: listaModalSolFlujoCaja })
    );
  };
  const loadEstado = async () => {
    let aEstadoSol = [];
    EstadoSolucionesData.forEach((element) => {
      aEstadoSol.push(createEstadoSolucionSelectAdapter(element));
    });
    dispatch(onSetListaTipoEstadoSolFlujoCaja({ listaTipoEstadoSolFlujoCaja: aEstadoSol }));
  };
  const validarCampos = () => {
    var bValidForm = true;
    if (
      flujoCajaState.dDateRange[0] === null ||
      flujoCajaState.dDateRange[1] === null ||
      flujoCajaState.iIdEstructura === null ||
      flujoCajaState.iIdFechaPeriodo === null ||
      flujoCajaState.iIdMoneda === null
    ) {
      bValidForm = false;
    }
    return bValidForm;
  };
  const onBuscarFlujoCaja = async () => {
    // const aRegistroModificados = finalArray.filter((element) => {
    //   return element.sAccion === "M" && element.IdentificadorCPR === "R";
    // });
    var bValidForm = validarCampos();
    // Swal.fire({
    //   title: "¿Usted ha realizado movimiento de registros desea perder los cambios?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "${theme.colors.primary}",
    //   cancelButtonColor: "#d33",
    //   cancelButtonText: "Cancelar",
    //   confirmButtonText: "Confirmar",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //   }
    // });
    // return;
    if (bValidForm) {
      dispatch(onLimpiarFiltroDetalleFlujoCaja());
      let lista = [];
      var responseObtenerInfoFiltrosFlujoCajaCabecera = await callEndpoint(
        obtenerInfoFiltrosFlujoCajaCabecera({
          oIDEstructura: {
            IDEst: flujoCajaState.iIdEstructura,
          },
        })
      );
      if (responseObtenerInfoFiltrosFlujoCajaCabecera) {
        if (responseObtenerInfoFiltrosFlujoCajaCabecera.data) {
          responseObtenerInfoFiltrosFlujoCajaCabecera.data.respuesta.aListaAgrupadaCaracPos.forEach(
            (element) => {
              lista.push(createObtenerInfoFiltrosFlujoCajaCabeceraAdapter(element));
            }
          );
        }
      }
      dispatch(
        onSetListaPosicionesFiltrosFlujoCaja({
          listaPosicionesFiltros: lista,
        })
      );
      const aRegistroModificados = finalArray.filter((element) => {
        return element.sAccion === 'M'; //&& element.IdentificadorCPR === "R";
      });
      if (aRegistroModificados.length > 0) {
        Swal.fire({
          title: '¿Usted ha realizado movimiento de registros desea perder los cambios?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.colors.primary,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await realizarBusqueda();
          }
        });
      } else {
        await realizarBusqueda();
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'Por favor, completar la versión, estructura , fecha , periodicidad y moneda del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleChangeTiempoProyeccion = (oEvent) => {
    var oParam = {
      iIdtiempoProyeccion: null,
      sTiempoProyeccion: '',
    };
    if (oEvent) {
      oParam.iIdtiempoProyeccion = oEvent.value;
      oParam.sTiempoProyeccion = oEvent.label;
      if (parseInt(flujoCajaState.iProyeccion, 10) > 0) {
        var diasAumentar = 0;
        switch (oEvent.value) {
          case '1':
            diasAumentar = parseInt(flujoCajaState.iProyeccion, 10) * 7;
            break;
          case '2':
            diasAumentar = parseInt(flujoCajaState.iProyeccion, 10) * 30;
            break;
          case '3':
            diasAumentar = parseInt(flujoCajaState.iProyeccion, 10);
            break;
          default:
            break;
        }
        if (flujoCajaState.dDateRange[0]) {
          const dFechaFinal = new Date(dDateRange[0]);
          let dFechaIncrementada = addDays(diasAumentar, dFechaFinal);
          var oParamFechaCrearFlujoCaja = {
            dFechaInicio: flujoCajaState.dDateRange[0],
            dFechaFin: dFechaIncrementada,
            sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'),
            sFechaFinal: dateTo_dd_mm_yyyy(dFechaIncrementada, '/'),
            validate: true,
          };
          dispatch(onChangeFechaCrearFlujoCaja(oParamFechaCrearFlujoCaja));
        }
      }
    }
    dispatch(onChangeTiempoPeridiocidad(oParam));
  };
  const handleChangeMultiKey = (event) => {
    dispatch(
      onSelectEtiquetaFlujoCaja({
        selectEtiquetaFlujoCaja: event,
      })
    );
    // const rowsInput = [...rowsData];
    // rowsInput[index]["aSeleccionKey"] = event;
    // setRowsData(rowsInput);
  };
  const handleChangeProyeccion = (oEvent) => {
    var oParam = {
      iProyeccion: 0,
    };
    if (oEvent) {
      if (oEvent.target.value.length > 0) {
        oParam.iProyeccion = parseInt(oEvent.target.value);
        dispatch(onChangeProyeccion(oParam));
        if (flujoCajaState.dDateRange[0] && flujoCajaState.iIdtiempoProyeccion) {
          if (parseInt(oParam.iProyeccion, 10) > 0) {
            oParam.iIdtiempoProyeccion = flujoCajaState.iIdtiempoProyeccion;
            oParam.sTiempoProyeccion = flujoCajaState.sTiempoProyeccion;
            var diasAumentar = 0;
            switch (flujoCajaState.iIdtiempoProyeccion) {
              case '1':
                diasAumentar = parseInt(oParam.iProyeccion, 10) * 7;
                break;
              case '2':
                diasAumentar = parseInt(oParam.iProyeccion, 10) * 30;
                break;
              case '3':
                diasAumentar = parseInt(oParam.iProyeccion, 10);
                break;
              default:
                break;
            }
            if (flujoCajaState.dDateRange[0]) {
              const dFechaFinal = new Date(dDateRange[0]);
              let dFechaIncrementada = addDays(diasAumentar, dFechaFinal);
              var oParamFechaCrearFlujoCaja = {
                dFechaInicio: flujoCajaState.dDateRange[0],
                dFechaFin: dFechaIncrementada,
                sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'),
                sFechaFinal: dateTo_dd_mm_yyyy(dFechaIncrementada, '/'),
                validate: true,
              };
              dispatch(onChangeFechaCrearFlujoCaja(oParamFechaCrearFlujoCaja));
            }
          }
        }
      } else {
        dispatch(onChangeProyeccion(oParam));
      }
    }
  };
  const handleChangeVersion = (oEvent) => {
    var oParam = {
      sVersion: '',
      bValidVersionFlujoCaja: null,
      selectVersionFlujoCaja: [],
    };
    if (oEvent) {
      oParam.sVersion = oEvent.target.value;
    }
    dispatch(onChangeVersion(oParam));
    // onSelectVersionFlujoCaja()
  };
  const onRegistrarFlujoCaja = async () => {
    var ListaEtiquetas = '';
    var ListaRegistrosFlujoCaja = finalArray.filter((element) => {
      return element.IdentificadorCPR === 'R';
    });
    flujoCajaState.selectEtiquetaFlujoCaja.forEach((element, index) => {
      if (index === 0) {
        ListaEtiquetas = element.IDEtiqueta.toString();
      } else {
        ListaEtiquetas = ListaEtiquetas + ',' + element.IDEtiqueta.toString();
      }
    });
    let ListaSoluciones = [];
    flujoCajaState.selectSolucionesModalFlujoCaja.forEach((element) => {
      ListaSoluciones.push(element.idSolucionBase);
    });
    let aIdPartida_3 = [];
    for (let index = 0; index < ListaRegistrosFlujoCaja.length; index++) {
      const { Id_Partida3 } = ListaRegistrosFlujoCaja[index];
      aIdPartida_3.push(Id_Partida3);
    }
    let aMontoMinimo = [];
    flujoCajaState.aRestriccionesDetalleFlujoCaja.forEach((element) => {
      if (element.selectTipoRegla.value === 'Monto mínimo') {
        var obj = {};
        obj.iIDTipoMoneda = element.selectMoneda.iIDTipoMoneda;
        obj.sAbreviatura = element.selectMoneda.sAbreviatura;
        obj.sNombreMoneda = element.selectMoneda.sNombreMoneda;
        obj.valor = parseFloat(element.valor);
        obj.sTipoRegla = 'Monto mínimo';
        aMontoMinimo.push(obj);
      }
    });
    if (aMontoMinimo.length === 0) {
      for (let index = 0; index < flujoCajaState.aMonedasCrearFlujoCaja.length; index++) {
        const elementMoneda = flujoCajaState.aMonedasCrearFlujoCaja[index];
        var obj = {};
        obj.iIDTipoMoneda = elementMoneda.iIDTipoMoneda;
        obj.sAbreviatura = elementMoneda.sAbreviatura;
        obj.sNombreMoneda = elementMoneda.sNombreMoneda;
        obj.valor = 0;
        obj.sTipoRegla = 'Monto mínimo';
        aMontoMinimo.push(obj);
      }
    } else {
      for (let index = 0; index < flujoCajaState.aMonedasCrearFlujoCaja.length; index++) {
        const elementMoneda = flujoCajaState.aMonedasCrearFlujoCaja[index];
        var aEncontrado = flujoCajaState.aRestriccionesDetalleFlujoCaja.filter((element) => {
          return element.selectMoneda.iIDTipoMoneda === elementMoneda.iIDTipoMoneda;
        });
        if (aEncontrado.length === 0) {
          var obj = {};
          obj.iIDTipoMoneda = elementMoneda.iIDTipoMoneda;
          obj.sAbreviatura = elementMoneda.sAbreviatura;
          obj.sNombreMoneda = elementMoneda.sNombreMoneda;
          obj.valor = 0;
          obj.sTipoRegla = 'Monto mínimo';
          aMontoMinimo.push(obj);
        }
      }
    }
    var oParam = {
      sFechaInicio: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[0], '/'),
      sFechaFin: dateTo_dd_mm_yyyy(flujoCajaState.dDateRange[1], '/'),
      sPeriodo: flujoCajaState.sCodFechaPeriodo, //"M",
      sMoneda: flujoCajaState.sAbreviatura, //"PEN",
      sIdEstructura: flujoCajaState.iIdEstructura,
      ListaEtiquetas: ListaEtiquetas, //"",
      // "sIdFlujoCajaGeneral": 6,
      ListaRegistrosFlujoCaja: aIdPartida_3,
      ListaSoluciones: ListaSoluciones,
      IDOptimizacion: flujoCajaState.idOptimiacion, //1,
      IDVersion: flujoCajaState.selectVersionFlujoCaja[0].value,
      aFiltrosDetalleFlujoCaja: flujoCajaState.aFiltrosDetalleFlujoCaja,
      aMontoMinimo: aMontoMinimo,
    };
    navigate(paths.CASH_FLOW.REPORT.BASE);
    return;
    var responseSaveHeaderAndDetailsFlowsCaja = await callEndpoint(
      saveHeaderAndDetailsFlowsCaja(oParam)
    );
    if (responseSaveHeaderAndDetailsFlowsCaja === null) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error en el servicio.',
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (responseSaveHeaderAndDetailsFlowsCaja.data) {
        if (responseSaveHeaderAndDetailsFlowsCaja.data.cabecera.statusCode === 200) {
          navigate(paths.CASH_FLOW.REPORT.BASE);
        } else if (responseSaveHeaderAndDetailsFlowsCaja.data.cabecera.statusCode === 400) {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: responseSaveHeaderAndDetailsFlowsCaja.data.cabecera.descripcion,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ocurrió un error de servicio.',
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ocurrió un error de servicio.',
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  const onGuardarFlujoCaja = async (oEvent, finalArrayEliminar) => {
    var bValidForm = validarCampos();
    var ListaRegistrosFlujoCaja = finalArray.filter((element) => {
      return element.IdentificadorCPR === 'R';
    });
    if (ListaRegistrosFlujoCaja.length === 0) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'El flujo de caja no contiene ningun registro propio ni de sistema.',
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    if (bValidForm) {
      Swal.fire({
        title: '¿Desea guardar el Flujo de caja?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.colors.primary,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(onOpenModalAgregarNombreFlujoCaja({ modalOpenAgregarNombreFlujoCaja: true }));
        }
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title:
          'Por favor, completar la versión, estructura , fecha , periodicidad y moneda del formulario.',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const TestMemo = React.memo(() => {
    return <TableDetalleFlujoCaja />;
  });

  const loadEtiquetasM = async () => {
    const responseListarEtiquetas = await callEndpoint(obtenerListadoEtiquetas());

    var listarEtiquetasFlujoCaja = [];
    for (
      let index = 0;
      index < responseListarEtiquetas.data.respuesta.aListaEtiqueta.length;
      index++
    ) {
      const element = responseListarEtiquetas.data.respuesta.aListaEtiqueta[index];
      var obj = createFlujoCajaEtiquetaAdapter(element);
      listarEtiquetasFlujoCaja.push(obj);
    }
    let objEtiqueta = {
      listarEtiquetasFlujoCaja: listarEtiquetasFlujoCaja,
    };
    dispatch(onSetListaEtiqueFlujoCaja(objEtiqueta));
  };

  const onRealizarDesdoblesAutomatico = (finalArraySinFiltro, resultCaceberas) => {
    // var aFinalArray = [...finalArraySinFiltro];
    const aFinalArray = finalArraySinFiltro.map((object) => ({ ...object }));
    //let rows = finalArraySinFiltro.map(object => ({ ...object }))//[...finalArraySinFiltro];
    // let rows = [...aFinalArray];
    ///
    //Proceso 1.
    var aIdExcluir = [];
    var aHijosFinal = [];
    let rowsFinal = [];
    var aAgrupadoXDesdobleXhijo = [];
    for (let index = 0; index < selectedDesdobles.length; index++) {
      const oPadreDesdoble = selectedDesdobles[index];
      var aAgrupadoDatos = [];
      var oItem = oPadreDesdoble.oPadre;
      // props.finalArray.forEach((element) => {
      var aHijos = obtenerHijos(aFinalArray, oItem.Id).map((object) => ({ ...object }));
      var helper = {};
      aHijos.forEach((element) => {
        var aEncontrado = element.datos_reg.filter((element2) => {
          return element2.sNombreCampo === oPadreDesdoble.oDesdoble.sNombreCampo;
        });
        if (aEncontrado.length > 0) {
          var key =
            // aEncontrado[0].iIdCaracteristica +
            // "_" +
            aEncontrado[0].sNombreCampo + '_' + aEncontrado[0].sDatoPosicion;
          if (!helper[key]) {
            //inicializa
            helper[key] = {
              // iIdCaracteristica: aEncontrado[0].iIdCaracteristica,
              sNombreCampo: aEncontrado[0].sNombreCampo,
              sDatoPosicion: aEncontrado[0].sDatoPosicion,
              datos: [element],
            };
            aAgrupadoDatos.push(helper[key]);
          } else {
            helper[key].datos.push(element);
          }
          aHijosFinal.push(element);
        }
      });
      aHijos = aHijosFinal;
      //
      aAgrupadoDatos.forEach((element) => {
        let datos = element.datos;
        datos.forEach((element2) => {
          aIdExcluir.push(element2.Id);
        });
      });
      ////
      // var indexFind = -1;
      // rows.find(function (item, i) {
      //   if (item.Id === oItem.Id) {
      //     indexFind = i;
      //     return i;
      //   }
      // });
      var obj = { ...oPadreDesdoble, ...{ aAgrupadoDatos: aAgrupadoDatos } };
      aAgrupadoXDesdobleXhijo.push(obj);
    }
    if (selectedDesdobles.length > 0) {
      var colectData = collect;
      const rows = [...colectData(finalArraySinFiltro).whereNotIn('Id', aIdExcluir).all()];
      rowsFinal = rows;
    } else {
      const rows = [...finalArraySinFiltro];
      rowsFinal = rows;
    }
    aAgrupadoXDesdobleXhijo = aAgrupadoXDesdobleXhijo.map((object) => ({ ...object }));
    for (let j = 0; j < aAgrupadoXDesdobleXhijo.length; j++) {
      var indexAcum = 0;
      var element = aAgrupadoXDesdobleXhijo[j];
      var indexFind = -1;
      rowsFinal.find(function (item, i) {
        if (item.Id === element.oPadre.Id) {
          indexFind = i;
          return i;
        }
      });
      var oItem = element.oPadre;
      var collapse = element.collapse;
      var collapseIcon = element.collapseIcon;
      var aAgrupado = element.aAgrupadoDatos;
      for (let p = 0; p < aAgrupado.length; p++) {
        indexAcum = indexAcum + 1;
        var IdDesdoble = element.oPadre.Id + '-' + indexAcum;
        ////----
        var aDatos = [...aAgrupado[p].datos];
        //var IdDesdoble = oItem.Id + "-" + (p + 1);
        let aDatosFinal = [];
        for (let index = 0; index < aDatos.length; index++) {
          var element2 = { ...aDatos[index] };
          // var Id = element.Id.replace(oItem.Id, IdDesdoble);
          // element.Id = Id;
          var aId = element2.Id.split('-');
          element2.Id = IdDesdoble + '-' + aId[aId.length - 1];
          element2.Items = [];
          aDatosFinal.push(element2);
          for (let w = 0; w < aDatos[index].Items.length; w++) {
            // const element2 = element.Items[w];
            var obj = { ...aDatos[index].Items[w] };
            var Id = aDatosFinal[index].Id + '-' + (w + 1);
            obj.Id = Id;
            aDatosFinal[index].Items.push(obj);
          }
        }
        var Items = [];
        for (
          let f = 0;
          f < resultCaceberas.length; //oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length;
          f++
        ) {
          var oCabecera = {
            Id: IdDesdoble,
            Id2: IdDesdoble,
            IdPadre: 'S-2-8',
            IdPadre2: 'S-2-8',
            IdHijo: 'S-2-8',
            IdHijo2: 'S-2-8',
            EsRaiz: 0,
            EsGrupo: 1,
            EsManual: 0,
            Posicion: aAgrupado[p].sDatoPosicion,
            Valor: 0,
          };
          // var acumulador = 0;
          // for (let j = 0; j < aDatosFinal.length; j++) {
          //   const element = aDatosFinal[j].Items[f].Valor;
          //   acumulador = acumulador + element;
          // }
          // oCabecera.Valor = acumulador;
          Items.push(oCabecera);
        }
        ////TEMPORAL GUILLE
        //if (p === 0) {
        indexFind = indexFind + 1;
        rowsFinal.splice(indexFind, 0, {
          EsGrupo: 1,
          EsManual: 0,
          EsRaiz: 0,
          EsSplit: 0,
          Id: IdDesdoble,
          Id2: IdDesdoble,
          IdPadre: IdDesdoble,
          IdPadre2: IdDesdoble,
          //collapse:element.collapse,
          //collapseIcon:element.collapseIcon,
          collapse: true,
          collapseIcon: true,
          Id_Partida1: oItem.Id_Partida1,
          Id_Partida2: oItem.Id_Partida2,
          Id_Partida3: oItem.Id_Partida3,
          IdentificadorCPR: 'D',
          Items: Items,
          LlaveCPR: 'D-' + (p + 1),
          Posicion: aAgrupado[p].sDatoPosicion,
          bEditable: 'NULL',
          datos_carpeta: [],
          datos_posic: [],
          datos_reg: [],
          sNombre_Partida1: oItem.sNombre_Partida1,
          sNombre_Partida2: oItem.sNombre_Partida2,
        });
        for (let indexAdd = 0; indexAdd < aDatosFinal.length; indexAdd++) {
          const element = aDatosFinal[indexAdd];
          element.collapse = true;
          element.collapseIcon = true;
          indexFind = indexFind + 1;
          rowsFinal.splice(indexFind, 0, element);
        }
      }
    }
    // var rowsInput = [];
    // [...rowsFinal].forEach(element => {
    //     var obj = {...element};
    //       obj.Items = [];
    //       element.Items.forEach(element2 => {
    //         obj.Items.push({...element2})
    //       });
    //     rowsInput.push(obj)
    // });
    // var rowsInputSaldo = rowsInput.filter((element) => {
    //   return element.Id.includes("S");
    // });
    // var rowsInputIngreso = rowsInput.filter((element) => {
    //   return element.Id.includes("I");
    // });
    // var rowsInputEgreso = rowsInput.filter((element) => {
    //   return element.Id.includes("E");
    // });
    // realizarRecalculo(rowsInputSaldo, rowsInput,resultCaceberas);
    // realizarRecalculo(rowsInputIngreso, rowsInput,resultCaceberas);
    // realizarRecalculo(rowsInputEgreso, rowsInput,resultCaceberas);
    // var aListaSplitAgrupadaSaldo = rowsInput.filter((element) => {
    //   return element.Id.includes("S");
    // });
    // collapseChild("S",aListaSplitAgrupadaSaldo)
    // var aListaSplitAgrupadaIngreso =rowsInput.filter((element) => {
    //   return element.Id.includes("I");
    // });
    // collapseChild("I",aListaSplitAgrupadaIngreso)
    // var aListaSplitAgrupadaEgreso = rowsInput.filter((element) => {
    //   return element.Id.includes("E");
    // });
    // collapseChild("E",aListaSplitAgrupadaEgreso)
    // let aListaSplitAgrupada= [...aListaSplitAgrupadaSaldo, ...aListaSplitAgrupadaIngreso , ...aListaSplitAgrupadaEgreso]
    // setFinalArray(aListaSplitAgrupada);
    return rowsFinal;
  };
  const calcularTotalYsubTotal = (aListaSplitAgrupada, resultCaceberas) => {
    const rowsInput = aListaSplitAgrupada; //finalArray;
    var detalleFlujoCajaCabeceras = resultCaceberas; //oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
    var objecto = {};
    objecto.EsRaiz = 1;
    objecto.Id = 'Total Acumulado';
    objecto.sNombre = 'Total Acumulado';
    objecto.Items = [];
    var objectoParcial = {};
    objectoParcial.EsRaiz = 1;
    objectoParcial.Id = 'Total Periodo';
    objectoParcial.sNombre = 'Total Periodo';
    objectoParcial.Items = [];
    var aEncontradoGruposRaiz = rowsInput.filter((element) => {
      return element.EsGrupo === 1 && element.EsRaiz === 1;
    });
    for (
      let index = 0;
      index < resultCaceberas.length;
      //detalleFlujoCajaCabeceras.length;
      index++
    ) {
      var fAcumulado = 0;
      var item = {
        Id: objecto.Id + '-' + index,
        Id_Movimiento: null,
        EsRaiz: 1,
        EsGrupo: 1,
        Valor: 0,
      };
      for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
        const element = aEncontradoGruposRaiz[w];
        // if (element.Posicion === 'Egreso') {
        //   fAcumulado = fAcumulado - element.Items[index].Valor;
        // } else {
        //   fAcumulado = fAcumulado + element.Items[index].Valor;
        // }
        fAcumulado = fAcumulado + element.Items[index].Valor;
      }
      item.Valor = fAcumulado;
      objecto.Items.push(item);
      objectoParcial.Items.push({ ...item });
    }
    setFinalArrayTotalParciales(objectoParcial);
    // props.setFinalArray(rowsInput);
    for (let index = 0; index < objecto.Items.length; index++) {
      const element = objecto.Items[index - 1];
      if (!element) {
        objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
      } else {
        objecto.Items[index].Valor = objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
      }
    }
    setFinalArrayTotal(objecto);
  };
  const obtenerHijos = (rowsInput, nuevoId) => {
    var aEncontrado = rowsInput.filter((element) => {
      return element.Id.includes(nuevoId) && element.Id !== nuevoId;
    });
    return aEncontrado;
  };
  const realizarRecalculo = (rowsInputSaldo, rowsInput, resultCaceberas) => {
    rowsInputSaldo.forEach((element) => {
      element.iFila = element.Id.split('-').length + 1;
    });
    var aFilterAcumuladores = rowsInputSaldo.filter((element) => {
      return (
        element.IdentificadorCPR === 'C' ||
        element.IdentificadorCPR === 'P' ||
        element.IdentificadorCPR === 'D'
      );
    });
    if (aFilterAcumuladores.length > 0) {
      aFilterAcumuladores.sort((a, b) => (a.iFila < b.iFila ? 1 : -1));
      aFilterAcumuladores.forEach((obj) => {
        var aEncontrado = rowsInputSaldo.filter((element) => {
          return (
            element.Id.includes(obj.Id + '-') &&
            element.Id !== obj.Id &&
            obj.Id.split('-').length + 1 === element.Id.split('-').length
          );
        });
        if (aEncontrado.length > 0) {
          var aEncontradoPadre = rowsInput.filter((element) => {
            return element.Id === obj.Id;
          });
          for (
            let i = 0;
            i < resultCaceberas.length; //oFlujoCajaAgregar.detalleFlujoCajaCabeceras.length;
            i++
          ) {
            var acumulador2 = 0;
            for (let j = 0; j < aEncontrado.length; j++) {
              const element2 = aEncontrado[j].Items[i];
              acumulador2 = acumulador2 + element2.Valor;
            }
            aEncontradoPadre[0].Items[i].Valor = acumulador2;
          }
        } else {
        }
      });
    }
  };
  const realizarRecalculoSubTotal_Total = (lista, resultCaceberas) => {
    var detalleFlujoCajaCabeceras = resultCaceberas;
    //flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
    var objecto = {};
    objecto.EsRaiz = 1;
    objecto.Id = 'Total Acumulado';
    objecto.sNombre = 'Total Acumulado';
    objecto.Items = [];
    var objectoParcial = {};
    objectoParcial.EsRaiz = 1;
    objectoParcial.Id = 'Total Periodo';
    objectoParcial.sNombre = 'Total Periodo';
    objectoParcial.Items = [];
    var aEncontradoGruposRaiz = lista.filter((element) => {
      return element.EsGrupo === 1 && element.EsRaiz === 1;
    });
    for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
      var fAcumulado = 0;
      var item = {
        Id: objecto.Id + '-' + index,
        Id_Movimiento: null,
        EsRaiz: 1,
        EsGrupo: 1,
        Valor: 0,
      };
      for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
        const element = aEncontradoGruposRaiz[w];
        // if (element.Posicion === 'Egreso') {
        //   fAcumulado = fAcumulado - element.Items[index].Valor;
        // } else {
        //   fAcumulado = fAcumulado + element.Items[index].Valor;
        // }
        fAcumulado = fAcumulado + element.Items[index].Valor;
      }
      item.Valor = fAcumulado;
      objecto.Items.push(item);
      objectoParcial.Items.push({ ...item });
    }
    setFinalArrayTotalParciales(objectoParcial);
    for (let index = 0; index < objecto.Items.length; index++) {
      const element = objecto.Items[index - 1];
      if (!element) {
        objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
      } else {
        objecto.Items[index].Valor = objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
      }
    }
    setFinalArrayTotal(objecto);
  };
  const onClickTabSolucione = () => {
    // if(flujoCajaState.idOptimiacion === null){
    //   Swal.fire({
    //     position: "center",
    //     icon: "warning",
    //     title: "No se ha realizado la ejecución del optimizador.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // }
    // if(flujoCajaState.idOptimiacion != null && flujoCajaState.aListaSoluciones.length===0){
    //   Swal.fire({
    //     position: "center",
    //     icon: "warning",
    //     title: "No se encontraron soluciones sugeridas.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // }
    // if(flujoCajaState.idOptimiacion != null && flujoCajaState.aListaSoluciones.length>0){
    dispatch(onSetTabProceso({ tabProceso: 1 }));
    //   return;
    // }
  };

  const filterPeridiocidad = () => {
    const detalleFlujoCajaCabecerasInicial = [...flujoCajaState.detalleFlujoCajaCabecerasInicial];
    // var sCodFechaPeriodo = flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo ;
    var sCodFechaPeriodo = flujoCajaState.selectPeridiocidadTableFC[0].value;
    let aListaDetalleAgrupado2 = [];
    let helper = {};
    if (sCodFechaPeriodo === 2) {
      aListaDetalleAgrupado2 = detalleFlujoCajaCabecerasInicial.reduce(function (r, o) {
        var key = o.Anio + '_' + o.Mes + '_' + o.Semana;
        if (!helper[key]) {
          //inicializa
          helper[key] = {
            Semana: o.Semana,
            Mes: o.Mes,
            Anio: o.Anio,
            sCabecera: o.Semana + '-' + monthToString(o.Mes) + '-' + o.Anio,
            sFechaMax: '',
            sFechaMin: '',
            Items: [o],
          };
          r.push(helper[key]);
        } else {
          helper[key].Items.push(o);
        }
        return r;
      }, []);
      aListaDetalleAgrupado2.forEach((element, index) => {
        element.sFechaMin = element.Items[0].sFechaMin;
        element.sFechaMax = element.Items[element.Items.length - 1].sFechaMax;
        if (index === 0) {
          element.desde = 0;
          element.hasta = element.Items.length - 1;
        } else {
          element.desde = aListaDetalleAgrupado2[index - 1].hasta + 1;
          element.hasta = aListaDetalleAgrupado2[index - 1].hasta + element.Items.length;
        }
        // element.desde = 0;
        //   element.hasta = element.Items.length-1;
      });
      dispatch(onSetDetalleFlujoCajaCabeceras(aListaDetalleAgrupado2));
    } else if (sCodFechaPeriodo === 3) {
      aListaDetalleAgrupado2 = detalleFlujoCajaCabecerasInicial.reduce(function (r, o) {
        var key = o.Mes + '_' + o.Anio;
        if (!helper[key]) {
          //inicializa
          helper[key] = {
            Mes: o.Mes,
            Anio: o.Anio,
            sCabecera: monthToString(o.Mes) + '-' + o.Anio,
            sFechaMax: '',
            sFechaMin: '',
            Items: [o],
          };
          r.push(helper[key]);
        } else {
          helper[key].Items.push(o);
        }
        return r;
      }, []);
      aListaDetalleAgrupado2.forEach((element, index) => {
        element.sFechaMin = element.Items[0].sFechaMin;
        element.sFechaMax = element.Items[element.Items.length - 1].sFechaMax;
        if (index === 0) {
          element.desde = 0;
          element.hasta = element.Items.length - 1;
        } else {
          element.desde = aListaDetalleAgrupado2[index - 1].hasta + 1;
          element.hasta = aListaDetalleAgrupado2[index - 1].hasta + element.Items.length;
        }
        // element.desde = 0;
        //   element.hasta = element.Items.length-1;
      });
      dispatch(onSetDetalleFlujoCajaCabeceras(aListaDetalleAgrupado2));
    } else if (sCodFechaPeriodo === 1) {
      detalleFlujoCajaCabecerasInicial.forEach((element, index) => {
        const obj = { ...element };
        obj['desde'] = index;
        obj['hasta'] = index + 1;
        aListaDetalleAgrupado2.push(obj);
      });
      dispatch(onSetDetalleFlujoCajaCabeceras(aListaDetalleAgrupado2));
    }
    return aListaDetalleAgrupado2;
  };
  const filterPeridiocidadXParametro = (selectPeridiocidadTableSolucionFC) => {
    const detalleFlujoCajaCabecerasInicial = [...flujoCajaState.detalleFlujoCajaCabecerasInicial];
    // var sCodFechaPeriodo = flujoCajaState.selectPeridiocidadTableFC[0].sCodFechaPeriodo ;
    var sCodFechaPeriodo = selectPeridiocidadTableSolucionFC[0].value;
    let aListaDetalleAgrupado2 = [];
    let helper = {};
    if (sCodFechaPeriodo === 2) {
      aListaDetalleAgrupado2 = detalleFlujoCajaCabecerasInicial.reduce(function (r, o) {
        var key = o.Anio + '_' + o.Mes + '_' + o.Semana;
        if (!helper[key]) {
          //inicializa
          helper[key] = {
            Semana: o.Semana,
            Mes: o.Mes,
            Anio: o.Anio,
            sCabecera: o.Semana + '-' + monthToString(o.Mes) + '-' + o.Anio,
            sFechaMax: '',
            sFechaMin: '',
            Items: [o],
          };
          r.push(helper[key]);
        } else {
          helper[key].Items.push(o);
        }
        return r;
      }, []);
      aListaDetalleAgrupado2.forEach((element, index) => {
        element.sFechaMin = element.Items[0].sFechaMin;
        element.sFechaMax = element.Items[element.Items.length - 1].sFechaMax;
        if (index === 0) {
          element.desde = 0;
          element.hasta = element.Items.length - 1;
        } else {
          element.desde = aListaDetalleAgrupado2[index - 1].hasta + 1;
          element.hasta = aListaDetalleAgrupado2[index - 1].hasta + element.Items.length;
        }
        // element.desde = 0;
        //   element.hasta = element.Items.length-1;
      });
      dispatch(
        onSetDetalleFlujoCajaCabecerasSolucion({
          detalleFlujoCajaCabecerasSolucion: aListaDetalleAgrupado2,
        })
      );
    } else if (sCodFechaPeriodo === 3) {
      aListaDetalleAgrupado2 = detalleFlujoCajaCabecerasInicial.reduce(function (r, o) {
        var key = o.Mes + '_' + o.Anio;
        if (!helper[key]) {
          //inicializa
          helper[key] = {
            Mes: o.Mes,
            Anio: o.Anio,
            sCabecera: monthToString(o.Mes) + '-' + o.Anio,
            sFechaMax: '',
            sFechaMin: '',
            Items: [o],
          };
          r.push(helper[key]);
        } else {
          helper[key].Items.push(o);
        }
        return r;
      }, []);
      aListaDetalleAgrupado2.forEach((element, index) => {
        element.sFechaMin = element.Items[0].sFechaMin;
        element.sFechaMax = element.Items[element.Items.length - 1].sFechaMax;
        if (index === 0) {
          element.desde = 0;
          element.hasta = element.Items.length - 1;
        } else {
          element.desde = aListaDetalleAgrupado2[index - 1].hasta + 1;
          element.hasta = aListaDetalleAgrupado2[index - 1].hasta + element.Items.length;
        }
        // element.desde = 0;
        //   element.hasta = element.Items.length-1;
      });
      dispatch(
        onSetDetalleFlujoCajaCabecerasSolucion({
          detalleFlujoCajaCabecerasSolucion: aListaDetalleAgrupado2,
        })
      );
    } else if (sCodFechaPeriodo === 1) {
      detalleFlujoCajaCabecerasInicial.forEach((element, index) => {
        const obj = { ...element };
        obj['desde'] = index;
        obj['hasta'] = index + 1;
        aListaDetalleAgrupado2.push(obj);
      });
      dispatch(
        onSetDetalleFlujoCajaCabecerasSolucion({
          detalleFlujoCajaCabecerasSolucion: aListaDetalleAgrupado2,
        })
      );
    }
    return aListaDetalleAgrupado2;
  };
  const collapseChild = (idCarpetaPadreGeneral, aListaSplitAgrupada) => {
    aListaSplitAgrupada[0]['collapse'] = false;
    aListaSplitAgrupada[0]['collapseIcon'] = true;
    var aEncontrado = aListaSplitAgrupada.filter((element) => {
      return (
        element.Id.includes(idCarpetaPadreGeneral + '-') && element.Id !== idCarpetaPadreGeneral
      );
    });
    var aEncontrado2 = aListaSplitAgrupada.filter((element) => {
      return (
        element.Id.includes(idCarpetaPadreGeneral + '-') &&
        element.Id !== idCarpetaPadreGeneral &&
        idCarpetaPadreGeneral.split('-').length + 1 === element.Id.split('-').length
      );
    });
    if (aEncontrado.length > 0) {
      aEncontrado2.forEach((element) => {
        element.collapse = false;
        element.collapseIcon = false;
        var aEncontrado = aListaSplitAgrupada.filter((elemen2) => {
          return elemen2.Id.includes(element.Id + '-') && elemen2.Id !== element.Id;
        });
        aEncontrado.forEach((element) => {
          element.collapse = true;
          element['collapseIcon'] = false;
        });
      });
    }
  };

  const onBuscarDetalle = () => {
    dispatch(
      changeListaAgruadaXSoluciones({ IdSolucion: flujoCajaState.selectListaSoluciones[0].value })
    );
  };

  const onRealizarRecalculoSubTotales_Total = () => {
    const rowsInput = [...flujoCajaState.detalleListaAgrupadaXSoluciones];
    var detalleFlujoCajaCabeceras = flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras;
    var objecto = {};
    objecto.EsRaiz = 1;
    objecto.Id = 'Total Acumulado';
    objecto.sNombre = 'Total Acumulado';
    objecto.Items = [];
    var objectoParcial = {};
    objectoParcial.EsRaiz = 1;
    objectoParcial.Id = 'Total Periodo';
    objectoParcial.sNombre = 'Total Periodo';
    objectoParcial.Items = [];
    var aEncontradoGruposRaiz = rowsInput.filter((element) => {
      return element.EsGrupo === 1 && element.EsRaiz === 1;
    });
    for (let index = 0; index < detalleFlujoCajaCabeceras.length; index++) {
      var fAcumulado = 0;
      var item = {
        Id: objecto.Id + '-' + index,
        Id_Movimiento: null,
        EsRaiz: 1,
        EsGrupo: 1,
        Valor: 0,
      };
      for (let w = 0; w < aEncontradoGruposRaiz.length; w++) {
        const element = aEncontradoGruposRaiz[w];
        // if (element.Posicion === 'Egreso') {
        //   fAcumulado = fAcumulado - element.Items[index].Valor;
        // } else {
        //   fAcumulado = fAcumulado + element.Items[index].Valor;
        // }
        fAcumulado = fAcumulado + element.Items[index].Valor;
      }
      item.Valor = fAcumulado;
      objecto.Items.push(item);
      objectoParcial.Items.push({ ...item });
    }
    // props.setFinalArrayTotalParciales(objectoParcial);
    for (let index = 0; index < objecto.Items.length; index++) {
      const element = objecto.Items[index - 1];
      if (!element) {
        objecto.Items[index].Valor = objecto.Items[index].Valor + 0;
      } else {
        objecto.Items[index].Valor = objecto.Items[index].Valor + objecto.Items[index - 1].Valor;
      }
    }
    // props.setFinalArrayTotal(objecto);
    dispatch(
      onSetListaAgrupadaXSolucionesParcial({
        detalleListaAgrupadaXSolucionesParcial: objectoParcial,
      })
    );
    dispatch(onSetListaAgrupadaXSolucionesTotal({ detalleListaAgrupadaXSolucionesTotal: objecto }));
  };
  // const debounce = (fn, delay) => {
  //   let timerId;
  //   return (...args) => {
  //     clearTimeout(timerId);
  //     timerId = setTimeout(fn, delay, [...args]);
  //   };
  // };
  // const fetch = (url, options) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(`response - ${Math.floor(Math.random() * 1000)}`);
  //     }, 2000);
  //   });
  // };

  const onExcel = async (finalArray, finalArrayTotal, finalArrayTotalParciales) => {
    let now = new Date();

    let NombreArchivo =
      'FC_' +
      flujoCajaState.sVersion +
      '_' +
      String(now.getDate()).padStart(2, '0') +
      '-' +
      String(now.getMonth() + 1).padStart(2, '0') +
      '-' +
      now.getFullYear();
    let wb = await JsoToExcelJsFC(NombreArchivo, 'FlujoCaja', 1, [
      {
        data: flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras,
        value: 'sCabecera',
        validar: flujoCajaState.selectPeridiocidadTableFC[0].label,
        primerDato: 'Posición / Periodo',
      },
      {
        dataCase: 1,
        data: finalArray,
        value: 'Posicion',
        arrData: 'Items',
        arrValue: 'Valor',
        needId: true,
        idValue: 'Id',
      },
      {
        dataCase: 2,
        data: finalArrayTotalParciales,
        value: 'Id',
        arrData: 'Items',
        arrValue: 'Valor',
      },
      {
        dataCase: 2,
        data: finalArrayTotal,
        value: 'Id',
        arrData: 'Items',
        arrValue: 'Valor',
      },
    ]);
  };

  const generarGrafico = async () => {
    setShowGrafico(!showGrafico);
    if (!showGrafico) {
      const labels = flujoCajaState.oFlujoCajaAgregar.detalleFlujoCajaCabeceras.map(function (
        item,
        index
      ) {
        return item.sCabecera;
      });

      let datasets = [];

      let obj = {
        label: 'Flujo Caja Periodo',
        data: finalArrayTotalParciales.Items.map(function (item, index) {
          return item.Valor;
        }),
        pointStyle: 'circle',
        pointRadius: 1,
        // pointStyle: false,
        fill: true,
        borderColor: theme.colorsDashboard[0],
        backgroundColor: theme.colorsDashboardRGBA[0],
      };
      let obj1 = {
        label: 'Flujo Caja Total',
        data: finalArrayTotal.Items.map(function (item, index) {
          return item.Valor;
        }),
        pointStyle: 'circle',
        pointRadius: 1,
        fill: true,
        borderColor: theme.colorsDashboard[1],
        backgroundColor: theme.colorsDashboardRGBA[1],
      };

      datasets.push(obj);
      datasets.push(obj1);

      const data = {
        labelsChartFI: labels,
        datasetsChartFI: datasets,
      };

      setOptions({
        responsive: true,
        tension: 0.4,
        interaction: {
          mode: 'index',
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: (ctx) => 'Competencias de Flujo de Caja',
          },
          tooltip: {
            usePointStyle: true,
          },
        },
      });
      dispatch(onGenerateDataLineChartFC(data));
    }
  };

  const downloadImage = useCallback(() => {
    const link = document.createElement('a');
    link.download = 'Competencias_de_FC.png';
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);

  return loadingDetalle === true ? (
    <LoadingSpinner />
  ) : flujoCajaState.IDFlujoCajaGeneral === null ? (
    <Content>No se encontró información</Content>
  ) : (
    <Content style={{ overflow: 'auto' }}>
      <ParamCard>
        <ParamCardHeader>
          <ParamCardTitle>Parámetros :</ParamCardTitle>

          {false ? (
            <></>
          ) : (
            <ContentRow>
              {finalArray.length === 0 ? (
                <></>
              ) : (
                <>
                  <ButtonForm
                    isAction={true}
                    isOnlyIcon={false}
                    noBackground={true}
                    name={'Filtros'}
                    action={openModalFiltros}
                    type={'tertiary'}
                  />

                  <ButtonForm
                    isAction={true}
                    isOnlyIcon={false}
                    noBackground={true}
                    name={'Reglas'}
                    action={openModalRestricciones}
                    type={'tertiary'}
                  />

                  <ButtonForm
                    isAction={true}
                    isOnlyIcon={false}
                    noBackground={true}
                    name={'Soluciones'}
                    action={openModalSoluciones}
                    type={'tertiary'}
                  />
                </>
              )}
            </ContentRow>
          )}
          <ContentButton>
            <ButtonForm
              icon={<FaAngleDown />}
              isAction={false}
              isOnlyIcon={true}
              noBackground={true}
              rotateIcon={expandedParametroEntrada}
              size={'18px'}
              style={{
                marginRight: '10px',
                overflow: 'hidden',
              }}
              action={(e) => showParametros(e)}
            />
          </ContentButton>
        </ParamCardHeader>
        <ParamCardBody
          className={expandedParametroEntrada ? 'show' : ''}
          setHeight={accodionHeightParametroEntrada}
          ref={ref2}
        >
          <Content>
            <ContentForm width={'100%'}>
              <RowForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm width={'80px'} title={'Versión :'}></LabelForm>
                    <Input
                      placeholder="Ingrese..."
                      value={flujoCajaState.sVersion}
                      onChange={(e) => handleChangeVersion(e)}
                      disabled={flujoCajaState.disableParametrosFC}
                    ></Input>
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Estructura : '}></LabelForm>
                    <SelectForm
                      bValid={true}
                      value={
                        flujoCajaState.iIdEstructura === null
                          ? []
                          : [
                              {
                                value: flujoCajaState.iIdEstructura,
                                label: flujoCajaState.sEstructura,
                              },
                            ]
                      }
                      handleChange={(evnt, v) => handleChangeEstructuraBaseCrearFlujoCaja(v)}
                      options={aListaEstructurasBaseCrearFlujoCaja}
                      placeholder="Seleccione Estructura"
                      isDisabled={flujoCajaState.disableParametrosFC}
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Etiqueta : '}></LabelForm>
                    <SelectForm
                      isMultiple={true}
                      bValid={true}
                      value={flujoCajaState.selectEtiquetaFlujoCaja}
                      handleChange={(evnt, v) => handleChangeMultiKey(v)}
                      options={flujoCajaState.listarEtiquetasFlujoCaja}
                      placeholder="Seleccione etiqueta"
                      isDisabled={flujoCajaState.disableParametrosFC}
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Fecha : '}></LabelForm>
                    <DatePickerForm
                      dateFormat="dd/MM/yyyy"
                      selectsRange={true}
                      startDate={flujoCajaState.dDateRange[0]}
                      endDate={flujoCajaState.dDateRange[1]}
                      onChange={(evnt) => handleChangeFechaCrearFlujoCaja(evnt)}
                      isClearable={!flujoCajaState.disableParametrosFC}
                      placeholderText="Ingrese"
                      locale="es"
                      showMonthDropdown
                      showYearDropdown
                      portalId="root-portal"
                      validate={flujoCajaState.bValidFecha}
                      disabled={flujoCajaState.disableParametrosFC}
                      alignText={'center'}
                    />
                  </RowForm>
                </ColForm>

                <ColForm xs={24} sm={24} md={12} lg={8} xl={8}>
                  <RowForm>
                    <LabelForm title={'Horizonte :'}></LabelForm>
                    <RowDoubleInput>
                      <Input
                        width={'37%'}
                        onKeyPress={(evnt) => onlyNumbers(evnt)}
                        placeholder="Ingrese..."
                        onChange={(e) => handleChangeProyeccion(e)}
                        value={flujoCajaState.iProyeccion}
                        disabled={flujoCajaState.disableParametrosFC}
                      ></Input>
                      <SelectForm
                        width={'60%'}
                        bValid={true}
                        value={
                          flujoCajaState.iIdtiempoProyeccion === null
                            ? []
                            : [
                                {
                                  value: flujoCajaState.iIdtiempoProyeccion,
                                  label: flujoCajaState.sTiempoProyeccion,
                                },
                              ]
                        }
                        handleChange={(evnt, v) => handleChangeTiempoProyeccion(v)}
                        isDisabled={flujoCajaState.disableParametrosFC}
                        options={listaProyeccion}
                        placeholder="Seleccione tiempo"
                      />
                    </RowDoubleInput>
                  </RowForm>
                </ColForm>
              </RowForm>
            </ContentForm>
          </Content>
        </ParamCardBody>
      </ParamCard>
      <>
        <TabBarCreacion
          marginTopContentBar="10px"
          onClickTab1={(e) => {
            setShowGrafico(false);

            dispatch(onSetTabProceso({ tabProceso: 0 }));
          }}
          tabProceso={flujoCajaState.tabProceso}
          labelTab1={'Flujo Inicial'}
          onClickTab2={(e) => onClickTabSolucione(e)}
          labelTab2={'Flujo Solucionado'}
        />
        {flujoCajaState.tabProceso === 0 ? (
          <>
            <ContentForm width={'100%'} style={{ marginBottom: '5px', padding: '0' }}>
              <RowForm style={{ width: '100%' }}>
                <ColForm xs={20} sm={11} md={11} lg={8} xl={5}>
                  <RowForm>
                    <LabelForm title={'Periodicidad : '}></LabelForm>
                    <SelectForm
                      bValid={true}
                      disableClearable={true}
                      value={flujoCajaState.selectPeridiocidadTableFC}
                      handleChange={(evnt, v) => handleChangePeridiocidadTableFC(v)}
                      options={aTiemposPeriodoCrearFlujoCaja}
                      placeholder="Seleccione Periodo"
                    />
                  </RowForm>
                </ColForm>
                <ColForm xs={20} sm={11} md={11} lg={8} xl={5}>
                  <RowForm>
                    <LabelForm title={'Moneda : '}></LabelForm>
                    <SelectForm
                      bValid={true}
                      disableClearable={true}
                      value={flujoCajaState.selectMonedaTableFC}
                      handleChange={(evnt, v) => handleChangeMonedaTableFC(evnt, v)}
                      options={aMonedasCrearFlujoCaja}
                      placeholder="Seleccione Moneda"
                    />
                  </RowForm>
                </ColForm>
                <ColForm
                  xs={10}
                  sm={10}
                  md={10}
                  lg={10}
                  xl={10}
                  style={{
                    marginLeft: 'auto',
                  }}
                >
                  <RowForm style={{ justifyContent: 'flex-end' }}>
                    <Tooltip title="Descargar Excel">
                      <>
                        <ButtonForm
                          action={(e) =>
                            onExcel(finalArray, finalArrayTotal, finalArrayTotalParciales)
                          }
                          icon={<FaFileExcel />}
                          isAction={true}
                          isOnlyIcon={true}
                          typeColor="secondary"
                          style={{ marginRight: '2px' }}
                        />
                      </>
                    </Tooltip>
                    <Tooltip title="Generar Gráfico">
                      <>
                        <ButtonForm
                          action={(e) => generarGrafico()}
                          icon={<FaChartLine />}
                          isAction={true}
                          isOnlyIcon={true}
                          typeColor="secondary"
                          style={{ marginRight: '2px' }}
                        />
                      </>
                    </Tooltip>
                    <Tooltip title="Descargar Gráfico">
                      <>
                        <ButtonForm
                          action={(e) => downloadImage()}
                          icon={<FaDownload />}
                          isAction={true}
                          isOnlyIcon={true}
                          typeColor="secondary"
                          style={{ marginRight: '2px' }}
                        />
                      </>
                    </Tooltip>
                  </RowForm>
                </ColForm>
              </RowForm>
            </ContentForm>
            <TableDetalleFlujoCaja
              finalArray={finalArray}
              setFinalArray={setFinalArray}
              //GNM
              finalArraySinFiltro={finalArraySinFiltro}
              setFinalArraySinFiltro={setFinalArraySinFiltro}
              //GNM
              finalArrayTotal={finalArrayTotal}
              setFinalArrayTotal={setFinalArrayTotal}
              finalArrayTotalParciales={finalArrayTotalParciales}
              setFinalArrayTotalParciales={setFinalArrayTotalParciales}
              finalArrayCaracAgrupada={finalArrayCaracAgrupada}
              setFinalArrayCaracAgrupada={setFinalArrayCaracAgrupada}
              editTable={editTable}
              setEditTable={setEditTable}
              finalArrayEliminar={finalArrayEliminar}
              setFinalArrayEliminar={setFinalArrayEliminar}
              objRightClick={objRightClick}
              setObjRightClick={setObjRightClick}
              objRightPosicionClick={objRightPosicionClick}
              setObjRightPosicionClick={setObjRightPosicionClick}
              objRightRegistroClick={objRightRegistroClick}
              setObjRightRegistroClick={setObjRightRegistroClick}
              formAgregarRegM={formAgregarRegM}
              setFormAgregarRegM={setFormAgregarRegM}
              limpiarFormAgregarRegM={limpiarFormAgregarRegM}
              selectedDesdobles={selectedDesdobles}
              setSelectedDesdobles={setSelectedDesdobles}
            />
          </>
        ) : (
          <Solucion
            finalArray={finalArray}
            setFinalArray={setFinalArray}
            //GNM
            finalArraySinFiltro={finalArraySinFiltro}
            setFinalArraySinFiltro={setFinalArraySinFiltro}
            //GNM
            finalArrayTotal={finalArrayTotal}
            setFinalArrayTotal={setFinalArrayTotal}
            finalArrayTotalParciales={finalArrayTotalParciales}
            setFinalArrayTotalParciales={setFinalArrayTotalParciales}
            finalArrayCaracAgrupada={finalArrayCaracAgrupada}
            setFinalArrayCaracAgrupada={setFinalArrayCaracAgrupada}
            editTable={editTable}
            setEditTable={setEditTable}
            finalArrayEliminar={finalArrayEliminar}
            setFinalArrayEliminar={setFinalArrayEliminar}
            objRightClick={objRightClick}
            setObjRightClick={setObjRightClick}
            objRightPosicionClick={objRightPosicionClick}
            setObjRightPosicionClick={setObjRightPosicionClick}
            objRightRegistroClick={objRightRegistroClick}
            setObjRightRegistroClick={setObjRightRegistroClick}
            formAgregarRegM={formAgregarRegM}
            setFormAgregarRegM={setFormAgregarRegM}
            limpiarFormAgregarRegM={limpiarFormAgregarRegM}
            selectedDesdobles={selectedDesdobles}
            setSelectedDesdobles={setSelectedDesdobles}
          />
        )}
      </>
      <ModalRestricciones />
      <ModalSoluciones />
      <ModalFiltros
        selectionFiltrosModel={selectionFiltrosModel}
        setSelectionFiltrosModel={setSelectionFiltrosModel}
        // aFiltrosDetalleFlujoCaja = {flujoCajaState.aFiltrosDetalleFlujoCaja}
        // finalArray={finalArray}
        // setFinalArray={setFinalArray}
        // //GNM
        // finalArraySinFiltro={finalArraySinFiltro}
        // setFinalArraySinFiltro={setFinalArraySinFiltro}
        // selectedDesdobles={selectedDesdobles}
        // setSelectedDesdobles={setSelectedDesdobles}
        // onRealizarDesdoblesAutomatico={onRealizarDesdoblesAutomatico}
        // setFinalArrayTotalParciales={setFinalArrayTotalParciales}
        // setFinalArrayTotal={setFinalArrayTotal}
        // realizarRecalculoSubTotal_Total={realizarRecalculoSubTotal_Total}
        // realizarRecalculo={realizarRecalculo}
      />
      {loading === true ? <LoadingSpinner /> : <></>}
    </Content>
  );
};
